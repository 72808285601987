import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

export const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    pb: 5
  },

  resContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: color.primaryBackground
  },

  headerContainer: {
    position: 'sticky',
    top: 0,
    paddingTop: '1%',
    backgroundColor: color.primaryBackground,
    zIndex: 2
  },

  cardContainer: {
    display: 'flex',
    width: '511px',
    height: '100px',
    boxShadow: 2,
    borderRadius: 2,
    ml: 5,
    mt: 2,
    p: 2,
    justifyContent: 'space-between',
    backgroundColor: color.palette.lightBlue
  },

  resCardContainer: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '79px',
    width: '95%',
    borderRadius: 2,
    boxShadow: 2,
    m: 1,
    boxSizing: 'border-box',
    p: defaultValues.isResponsive ? 1 : 2,
    justifyContent: 'space-between',
    backgroundColor: color.palette.lightBlue
  },

  subContainer: { width: '70%' },

  nameText: { fontSize: 24, fontWeight: 500 },

  textWithIconContainer: {
    wordWrap: 'break-word',
    overflowWrap: 'anywhere',
    flexWrap: 'wrap',
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },

  subText: { fontSize: 15, fontWeight: 500 },
  reSubText: { fontSize: 12, fontWeight: 500 },

  buttonContainer: { display: 'flex' },

  buttonActive: {
    width: '75px',
    height: '28px',
    backgroundColor: color.palette.green,
    fontSize: 12
  },
  reButtonActive: {
    height: '14px',
    width: '45px',
    backgroundColor: color.palette.green,
    fontSize: 7
  },
  buttonInactive: {
    width: '75px',
    height: '28px',
    backgroundColor: color.palette.red,
    fontSize: 12
  },
  reButtonInctive: {
    width: '45px',
    height: '14px',
    backgroundColor: color.palette.red,
    fontSize: 7
  },
  defaultButton: {
    width: '75px',
    height: '28px',
    backgroundColor: color.primaryBackground,
    fontSize: 12
  },
  reDefaultButton: {
    width: '45px',
    height: '14px',
    backgroundColor: color.primaryBackground,
    fontSize: 7
  },

  icon: { height: '16px', objectFit: 'contain', marginRight: '1%' },

  notificationStyle: { color: color.palette.red, margin: '3%' },
  resBtnStyle: { fontSize: '10px' }
};
