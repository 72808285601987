import React from 'react';
import './style.css';

export const Text3DFlip = ({ text, height, animation, contentStyle }) => {
  const half = height / 2 || 50;
  height = height || 100;
  animation = animation || 30;

  const defaultStyle = {
    transformStyle: 'preserve-3d',
    position: 'relative',
    animation: `spin ${animation}s linear infinite`,
    minWidth: '100%',
    height: `${height}px`
  };

  const phaseStyles = [
    {
      transform: `rotateX(90deg) translateY(-${half}px)`,
      transformOrigin: '50% 0',
      animationDelay: '0s, 10s'
    },
    {
      transform: `rotateX(-90deg) translateY(${half}px)`,
      transformOrigin: '50% 100%',
      animationDelay: '0s, 10s'
    },

    {
      transform: `translateZ(${half}px)`,
      animationDelay: '0s, 10s'
    },
    {
      transform: `translateZ(-${half}px) rotateX(180deg)`,
      animationDelay: '0s, 10s'
    }
  ];
  const divStyle = {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    backfaceVisibility: 'hidden'
  };

  return (
    <div style={defaultStyle}>
      {text.map((item, index) => {
        if (index < 4)
          return (
            <div key={index} style={{ ...contentStyle, ...divStyle, ...phaseStyles[index] }}>
              {item}
            </div>
          );
      })}
    </div>
  );
};
