import React, { useEffect, useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import "./style.css";
import { styles } from "./style";
import noImage from "../../../assets/images/noImage.jpg";

export const ImageSlider = ({ images, width, height }) => {
  const [formattedImages, setFormattedImages] = useState([]);
  const [key, setKey] = useState(0);
  const [showBullets, setShowBullets] = useState(false);

  useEffect(() => {
    const updatedImages = [...images];
    if (updatedImages.length === 0) {
      updatedImages.push(noImage);
    }
    setFormattedImages(updatedImages);
    setShowBullets(updatedImages.length > 1);
    setKey((prevKey) => prevKey + 1);
  }, [images]);

  return (
    <div className="imageSliderContainer">
      <SimpleImageSlider
        key={key}
        style={styles.sliderImg}
        width={width}
        height={height}
        images={formattedImages}
        showBullets={showBullets}
        showNavs={showBullets}
        navSize={30}
        navMargin={5}
      />
    </div>
  );
};
