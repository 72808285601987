import { createSelector } from 'reselect';

export const getSidebarIndex = createSelector(
  [(state) => state.admin.sidebarIndex],
  (sidebarIndex) => sidebarIndex
);

export const getNavbarHeading = createSelector(
  [(state) => state.admin.navbarHeading],
  (navbarHeading) => navbarHeading
);
