import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';

import {
  CauseCard,
  EventCard,
  DonersCard,
  PageHeader,
  Screen,
  Text,
  Loader
} from '../../../../components';
import { Route_Paths, defaultValues } from '../../../../constants';
import { viewEventStyle } from './style';

import LeftIcon from '../../../../assets/images/Left.png';
import { useDispatch, useSelector } from 'react-redux';
import { allProjects, currentEvent } from '../../../../redux/selectors';
import { getEventDetails } from '../../../../services/api';
import { setCurrentEventData } from '../../../../redux/slices';

export const AdminEvents = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const projects = useSelector(allProjects);
  const savedEventData = useSelector(currentEvent);

  const [eventData, setEventData] = useState(null);
  const [donors, setDonors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [causesInEvent, setCausesInEvent] = useState([]);

  useEffect(() => {
    const getData = async () => {
      await getEventDetails(savedEventData._id)
        .then((res) => {
          setEventData(res.data.event);
          dispatch(setCurrentEventData(res.data.event));
          setDonors(res.data.donors);
          if (res.data.event.causes.length && projects) {
            let finalData = [];
            for (let i = 0; i < res.data.event.causes.length; i++) {
              for (let j = 0; j < projects.length; j++) {
                if (projects[j]._id === res.data.event.causes[i]) {
                  finalData.push(projects[j]);
                }
              }
            }
            setCausesInEvent(finalData);
          }
          setIsLoading(false);
        })
        // eslint-disable-next-line no-unused-vars
        .catch((_error) => {
          setIsLoading(false);
        });
    };
    getData();
  }, []);

  return (
    <Screen showFooter={true}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={viewEventStyle.mainContainer}>
          <Box sx={viewEventStyle.container}>
            <img
              src={LeftIcon}
              style={viewEventStyle.img}
              onClick={() => navigation(Route_Paths.MANAGE_EVENTS)}
            />
            <PageHeader title={'View Events'} />
          </Box>
          <Box
            sx={
              defaultValues.isResponsive
                ? viewEventStyle.resCardContainer
                : viewEventStyle.cardContainer
            }>
            <EventCard {...eventData} />
            <Box>
              <Text
                text="Cause"
                variant="header"
                style={
                  defaultValues.isResponsive ? viewEventStyle.resHeader : viewEventStyle.header
                }
              />
            </Box>
            <Grid container >
              {causesInEvent &&
                causesInEvent.map((item, index) => (
                  <Grid item xs={12} md={7} key={index}>
                    <CauseCard data={item} />
                  </Grid>
                ))}
            </Grid>
            <Box>
              <Text
                text="Donor details"
                variant="header"
                style={
                  defaultValues.isResponsive ? viewEventStyle.resHeader : viewEventStyle.header
                }
              />
            </Box>
            <Grid container>
              {donors.map((item, index) => (
                <Grid item xs={12} key={index} md={4}>
                  <DonersCard data={item} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Screen>
  );
};
