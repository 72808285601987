import { color } from '../../theme/color';

export const otpFormStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputStyles: {
    border: '1px solid',
    borderRadius: '8px',
    width: '54px',
    margin: '15px',
    height: '54px',
    fontSize: '12px',
    color: '#000',
    fontWeight: '400',
    caretColor: 'blue'
  },
  containerStyle: {
    display: 'block'
  },
  spanStyle: { width: '8px' },
  responsiveContainer: {
    display: 'block',
    marginTop: '2%'
  },
  responsiveInput: {
    border: '1px solid',
    borderRadius: '8px',
    width: '35px',
    margin: '5px',
    height: '35px',
    fontSize: '12px',
    color: '#000',
    fontWeight: '400',
    caretColor: 'blue'
  },
  responsiveSpan: { width: '8px' },

  otpResendButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%'
  },
  otpResendButton: {
    ml: '2%',
    cursor: 'pointer',
    color: color.palette.primaryBlue
  },

  clickedOtpResendButton: {
    ml: '2%',
    color: color.palette.gray
  },

  resendMessage: { ml: '2%' },

  notificationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    mt: 2,
    width: '100%'
  },

  notification: {
    marginLeft: '2%'
  },

  responsiveNotification: {
    fontSize: 10
  },

  responsiveNotificationContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    mt: '2%'
  },

  emailColor: { color: color.palette.primaryBlue },

  changeOption: {
    cursor: 'pointer',
    color: color.palette.primaryBlue,
    marginRight: '2%'
  },

  changeOptionResponsive: {
    cursor: 'pointer',
    color: color.palette.primaryBlue,
    mr: '2%',
    fontSize: 10
  },
  buttonMargin: { pr: '50px', pl: '50px' }
};
