/*
 *Commonly used regular expressions
 *
 * Add or remove variables according to your requirement
 */
export const regExp = {
  strLowerCase: /^(?=.*[a-z])/,
  strUpperCase: /^(?=.*[A-Z])/,
  containsNum: /^(?=.*[0-9])/,
  containsSpecialChar: /^(?=.*[!@#$%^&*])/,
  onlyNumbers: /^\d+/,
  email: /\S+@\S+\.\S+/,
  phoneRegExp: /^\d{10}$/,
  panRegExp: /[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}/,
  alphabetRegExp: /^[A-Za-z]+$/,
  pinCode: /^\d{6}$/
};
