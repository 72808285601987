import { defaultValues } from "../../constants";
import { color } from "../../theme/color";
import { palette } from "../../theme/palette";

const isResponsive = defaultValues.isResponsive;

export const styles = {
  container: {
    width: "100%",
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 10,
    width: "100%",
    left: "50%",
  },

  resButtonContainer: {
    display: "flex",
    justifyContent: "center",
    top: "90vh",
    width: "100%",
  },

  button: {
    paddingLeft: "4%",
    paddingRight: "4%",
    marginBottom: "30px",
  },

  editContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },

  subContainer: { p: 4 },

  iconContainer: {
    display: "flex",
    alignItems: "center",
  },

  icon: {
    cursor: "pointer",
    objectFit: "contain",
    width: defaultValues.isResponsive && "40px",
    height: defaultValues.isResponsive && "40px",
    marginTop: defaultValues.isResponsive ? "1%" : "2%",
    marginLeft: "2%",
  },

  height: {
    mb: 10,
    pl: defaultValues.isResponsive ? 1 : 3,
    pb: 1,
    pr: defaultValues.isResponsive ? 1 : 3,
  },

  editParentContainer: {
    width: isResponsive ? "90%" : "65%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  headerContainer: {
    position: "sticky",
    top: 0,
    backgroundColor: color.primaryBackground,
    paddingTop: "1%",
    marginBottom: 3,
    pl: 2,
    zIndex: 1,
  },
  imgContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "20px",
  },
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "10px",
    marginBottom: "5px",
  },
  imageStyle: {
    width: "150px",
    height: "150px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  deleteIconStyle: {
    position: "absolute",
    top: "5px",
    right: "5px",
    cursor: "pointer",
    background: palette.black,
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    lineHeight: "14px",
    fontWeight: "bold",
    color: palette.white,
  },
  deleteContainer: {
    position: "relative",
  },
  uploadButtonStyle: {
    backgroundColor: "transparent",
    color: "black",
    border: "1px solid black",
    padding: "5px 10px",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
  },
  "@media (max-width: 600px)": {
    imageContainer: {
      flexDirection: "column",
    },
  },

  inputFieldContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },

  inputFieldStyle: { width: isResponsive ? "100%" : "90%", my: 1 },

  textStyle: { fontSize: 8 },

  iconStyle: {
    position: "absolute",
    top: "5%",
    right: "5%",
    height: "20%",
    width: "20%",
    cursor: "pointer",
    borderRadius: "50%",
    border: "1px solid",
    borderColor: color.palette.skyBlue,
  },

  notificationStyle: { color: color.palette.red, margin: "3%" },
};
