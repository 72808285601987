import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styles } from './styles';

export const DatePicker = (props) => {
  const { date, onChangeDate, label, name, ref, onOpen } = props;
  return (
    <div style={styles.container}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          disablePast
          error={false}
          ref={ref}
          label={label}
          name={name}
          value={dayjs(date)}
          onChange={onChangeDate}
          onOpen={onOpen}
        />
        {/* {errorMessage && <Text variant="fieldError">{errorMessage}</Text>} */}
      </LocalizationProvider>
    </div>
  );
};
