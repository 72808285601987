import { color } from "../../../theme/color";

export const styles = {
  container: {
    width: 300,
    height: 140,
    borderRadius: 5,
    background: color.palette.lightBlue,
    alignItems: "flex-start",
    boxShadow: 2,
    padding: 2,
    marginRight: 2,
    marginBottom: 2
  },
  subItemContainer: {display: 'flex', alignItems: 'center', marginTop: '2px'},
  itemIcon: {width: 15, height: 15, marginRight: 5}
};
