import { defaultValues } from '../../../../constants';

export const viewEventStyle = {
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  img: {
    cursor: 'pointer',
    objectFit: 'contain',
    width: defaultValues.isResponsive && '40px',
    height: defaultValues.isResponsive && '40px',
    marginTop: defaultValues.isResponsive ? '1%' : '2%',
    marginLeft: '2%'
  },
  mainContainer: {
    width: '100%',
    pb: 5
  },

  cardContainer: { pl: 14, pr: 10 },
  resCardContainer: { pl: 4, pr: 2 },

  header: { fontSize: 32, fontWeight: 500 },
  resHeader: { fontSize: 16, fontWeight: 500 }
};
