import React from 'react';
import { Box, Modal } from '@mui/material';
import { TextInput } from '../../ui-kit/text-field';
import { UIButton } from '../../ui-kit';
import { style } from './style';
import { defaultValues } from '../../../constants';
import { useFormik } from 'formik';
import { queryDetailsSchema } from '../../../utils/schemas';

export const SendQueryModal = ({ open, handleClose, queryData }) => {
  const { values, handleChange, errors, touched, setFieldTouched } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email_id: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: queryDetailsSchema
  });

  const handleSubmit = () => {
    queryData(values);
    handleClose();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style.container(defaultValues.isResponsive)}>
        <TextInput
          label="First name"
          InputProps={{ sx: style.inputField }}
          sx={style.inputStyle}
          name="first_name"
          value={values.first_name}
          onBlur={() => setFieldTouched('first_name')}
          onChange={handleChange}
          error={touched.first_name && errors.first_name}
        />
        <TextInput
          label="last name"
          InputProps={{ sx: style.inputField }}
          sx={style.inputStyle}
          name="last_name"
          value={values.last_name}
          onBlur={() => setFieldTouched('last_name')}
          onChange={handleChange}
          error={touched.last_name && errors.last_name}
        />
        <TextInput
          label="email"
          InputProps={{ sx: style.inputField }}
          sx={style.inputStyle}
          name="email_id"
          value={values.email_id}
          onBlur={() => setFieldTouched('email_id')}
          onChange={handleChange}
          error={touched.email_id && errors.email_id}
        />
        <UIButton title="submit" style={style.button} onClick={handleSubmit} />
      </Box>
    </Modal>
  );
};
