import { color } from '../../../theme/color';

export const style = {
  continer: (isFullScreen) => ({
    width: isFullScreen ? window.innerWidth : '100%',
    height: isFullScreen ? window.innerHeight : '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: color.primary
  })
};
