import { color } from '../../theme/color';

export const ProfileStyles = {
  container: {
    display: 'flex',
    justifyContent: 'center'
  },
  imgContainer: {
    position: 'relative',
    width: '150px',
    height: '150px',
    borderRadius: '50%'
  },
  imgStyle: { width: '100%', height: '100%', borderRadius: 'inherit' },
  iconStyles: {
    position: 'absolute',
    top: '8px',
    right: '8px',
    color: color.palette.gray,
    borderRadius: '50%',
    cursor: 'pointer',
    border: '1px solid',
    borderColor: color.palette.skyBlue
  },
  inputStyle: { display: 'none' }
};
