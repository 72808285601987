import { palette } from "../../theme/palette";

const style = {
  flexContainer: {
    display: "flex",
    gap: "10px",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },

  flexItem: {
    flex: 1,
  },

  flexInput: {
    display: "flex",
    gap: "20px",
    "@media (max-width: 600px)": {
      flexDirection: "column",
      gap: "0px",
    },
  },

  projectDetails: {
    display: "flex",
    marginBottom: "16px",
    "@media (max-width: 600px)": {
      flexDirection: "column",
    },
  },
  projectImage: {
    width: "200px",
    height: "200px",
    marginRight: "5px",
    borderRadius: "10px",
  },
  projectInfo: {
    marginLeft: "20px",
  },
  projectText: {
    marginBottom: "8px",
    fontWeight: "bold",
  },
  formatAmount: {
    color: palette.red,
    fontSize:"14px"
  },
  seeMoreContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  descriptionHeading: {
    fontSize: "16px",
    fontWeight: "bold",
  },
  description: {
    fontSize: "14px",
    margin: "8px 0px",
    maxHeight: "110px",
    paddingRight: "15px",
    overflowY: "hidden",
  },
  purpose: {
    fontWeight: "bold",
  },
  seeMore: {
    fontSize: "14px",
    margin: "8px 0px",
    maxHeight: "130px",
    paddingRight: "15px",
    overflowY: "auto",
  },
  seeMoreButton: {
    fontSize: "14px",
    textAlign: "center",
    color: palette.blue,
    cursor: "pointer",
  },
};

export default style;
