import { put, takeLatest, all } from 'redux-saga/effects';
import { createProject, getActiveProjects, getProjects, updateProject } from '../../services/api';
import {
  allProjectsRequest,
  createProjectRequest,
  projectsFailure,
  projectsRequest,
  projectsSuccess,
  updateProjectRequest
} from '../slices/projectsSlice';
import { setAuthError } from '../slices';

function* allProjectRequest() {
  try {
    const response = yield getActiveProjects();
    yield put(projectsSuccess(response.data));
  } catch (error) {
    yield all([
      put(projectsFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* allProjectAdminRequest() {
  try {
    const response = yield getProjects();
    yield put(projectsSuccess(response.data));
  } catch (error) {
    yield all([
      put(projectsFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* createProjectCall(action) {
  try {
    const response = yield createProject(action.payload);
    yield put(projectsSuccess(response.data));
  } catch (error) {
    yield all([
      put(projectsFailure(error.response)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* updateProjectCall(action) {
  try {
    const response = yield updateProject(action.payload);
    yield put(projectsSuccess(response.data));
  } catch (error) {
    yield all([
      put(projectsFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

export function* projectsSaga() {
  yield takeLatest(projectsRequest.type, allProjectRequest);
  yield takeLatest(allProjectsRequest.type, allProjectAdminRequest);
  yield takeLatest(createProjectRequest.type, createProjectCall);
  yield takeLatest(updateProjectRequest.type, updateProjectCall);
}
