import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  events: [],
  donations: [],
  eventSuccess: null,
  eventFailure: null,
  currentEventData: []
};

const eventSlice = createSlice({
  initialState,
  name: 'events',
  reducers: {
    eventRequest(state) {
      state.isLoading = true;
      state.eventSuccess = null;
      state.eventFailure = null;
    },
    eventRequestSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload.data;
      state.eventSuccess = action.payload.success_code;
    },
    eventRequestFailure(state, action) {
      state.isLoading = false;
      state.eventFailure = action.payload.error_code;
    },
    createEventRequest(state) {
      state.isLoading = true;
    },
    createEventSuccess(state, action) {
      state.isLoading = false;
      state.events = action.payload.events;
      state.eventSuccess = action.payload.success_code;
    },
    createEventFailure(state, action) {
      state.isLoading = false;
      state.eventFailure = action.payload.error_code;
    },
    eventUpdateRequest(state) {
      state.isLoading = true;
      state.eventSuccess = null;
      state.eventFailure = null;
    },
    eventUpdateRequestSuccess(state, action) {
      state.events = action.payload.data;
      state.isLoading = false;
      state.eventSuccess = action.payload.success_code;
    },
    eventUpdateRequestFailure(state, action) {
      state.isLoading = false;
      state.eventFailure = action.payload.error_code;
    },
    eventRequestByUser(state) {
      state.isLoading = true;
      state.eventSuccess = null;
      state.eventFailure = null;
    },
    eventRequestByUserSuccess(state, action) {
      state.events = action.payload.created_events;
      state.donations = action.payload.donated_events;
      state.isLoading = false;
      state.eventSuccess = action.payload.success_code;
    },
    eventRequestByUserFailure(state, action) {
      state.isLoading = false;
      state.eventFailure = action.payload.error_code;
    },
    setCurrentEventData(state, action) {
      state.currentEventData = action.payload;
    }
  }
});

export const {
  eventRequest,
  eventRequestFailure,
  eventRequestSuccess,
  eventUpdateRequest,
  eventUpdateRequestSuccess,
  eventUpdateRequestFailure,
  eventRequestByUser,
  eventRequestByUserFailure,
  eventRequestByUserSuccess,
  createEventFailure,
  createEventRequest,
  createEventSuccess,
  setCurrentEventData
} = eventSlice.actions;

export default eventSlice.reducer;
