/* eslint-disable react/jsx-key */
import { Avatar, Box, IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import logo from '../../../assets/images/logo.png';
import { defaultValues } from '../../../constants/defaultValues';
import { Text } from '../../ui-kit';
import { Stack } from '@mui/system';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import TaskRoundedIcon from '@mui/icons-material/TaskRounded';
import GroupsIcon from '@mui/icons-material/Groups';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import LogoutIcon from '@mui/icons-material/Logout';
import { useDispatch, useSelector } from 'react-redux';
import { setLogOutRequest, setNavbarHeading, setSidebarIndex } from '../../../redux/slices';
import { useNavigate } from 'react-router-dom';
import { sidebarStyle } from './sidebarStyle';
import { getNavbarHeading, getSidebarIndex } from '../../../redux/selectors';

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const selectedMenu = useSelector(getSidebarIndex);
  const navbarHeading = useSelector(getNavbarHeading);

  const [anchorElNav, setAnchorElNav] = useState(null);

  const icons = [
    <NoteAltIcon />,
    <TaskRoundedIcon />,
    <GroupsIcon />,
    <VolunteerActivismIcon />,
    <LogoutIcon />
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleNavigation = (item, index) => {
    let headerText = item;

    dispatch(setSidebarIndex(index));
    if (item === 'Manage Projects') {
      headerText = 'Dashboard';
    }
    dispatch(setNavbarHeading(headerText));

    const navigateTo = defaultValues.navigationRoutes[item];
    if (navigateTo) {
      if (item === defaultValues.navigationRoutes['Logout']) {
        setAnchorElNav(null);
        dispatch(setLogOutRequest(true));
      } else {
        navigation(navigateTo);
      }
    }
  };

  return (
    <Box>
      <Box
        sx={
          defaultValues.isResponsive
            ? sidebarStyle.responsive_container
            : sidebarStyle.main_container
        }>
        {defaultValues.isResponsive ? (
          <>
            <Box sx={sidebarStyle.title_container}>
              <Text
                variant="primarySubTitles"
                text={navbarHeading}
                style={sidebarStyle.title_color}
              />
            </Box>
            <Box sx={sidebarStyle.icon_container}>
              <NotificationsIcon sx={sidebarStyle.icon_color} />
              <IconButton>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>

              <Box>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}>
                  <ArrowDropDownIcon sx={sidebarStyle.icon_color} />
                </IconButton>

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left'
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}>
                  {defaultValues.admin_navigation_responsive.map((item, index) => (
                    <MenuItem key={index} onClick={() => handleNavigation(item)}>
                      <Text textAlign="center">{item}</Text>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box sx={sidebarStyle.logo_container}>
              <img src={logo} alt="logo" style={sidebarStyle.image_style} />
            </Box>

            <Box>
              {defaultValues.admin_navigation.map((item, index) => (
                <Stack
                  sx={sidebarStyle.menu_style}
                  key={index}
                  onClick={() => handleNavigation(item, index)}>
                  {selectedMenu === index ? (
                    <Box sx={sidebarStyle.clicked}></Box>
                  ) : (
                    <Box sx={sidebarStyle.beforeClicked}></Box>
                  )}

                  <Box sx={sidebarStyle.icon_style}>{icons[index]}</Box>
                  <Text text={item} variant="primaryMenuText" />
                </Stack>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Sidebar;
