import { createSelector } from 'reselect';

export const getAuthStatus = createSelector(
  [(state) => state.login.isAuthenticated],
  (isAuthenticated) => isAuthenticated
);

export const getToken = createSelector([(state) => state.login.token], (token) => token);

export const loginLoadingStatus = createSelector(
  [(state) => state.login.isLoading],
  (isLoading) => isLoading
);

export const loginSuccessMsg = createSelector(
  [(state) => state.login.loginSuccessMessage],
  (loginSuccessMessage) => loginSuccessMessage
);

export const userInfo = createSelector([(state) => state.login.userInfo], (userInfo) => userInfo);

export const loginVerificationError = createSelector(
  [(state) => state.login.errorLogin],
  (errorLogin) => errorLogin
);

export const loginOtp = createSelector(
  [(state) => state.login.signingOtp],
  (signingOtp) => signingOtp
);

export const findUserEmail = createSelector(
  [(state) => state.login.user_email],
  (user_email) => user_email
);

export const isEmailVerified = createSelector(
  [(state) => state.login.isEmailVerified],
  (isEmailVerified) => isEmailVerified
);

export const isAdminProfile = createSelector(
  [(state) => state.login.is_admin],
  (isAdmin) => isAdmin
);

export const updateSuccessMsg = createSelector(
  [(state) => state.login.updateSuccess],
  (updateSuccess) => updateSuccess
);
export const updateErrorsMsg = createSelector(
  [(state) => state.login.updateUserFailure],
  (updateErrors) => updateErrors
);

export const logOutStatus = createSelector([(state) => state.login.logout], (logout) => logout);
