export const pageHeaderStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mt: '2%'
  },

  resContainer: {
    m: '3%',
    width: '100%'
  },
  title: { fontSize: 32, marginLeft: '3%' },
  resTitle: { fontSize: 18, marginLeft: '2%' },
  textField: { width: '60%', marginRight: 10 },
  resTextField: { width: '89%', marginRight: 3, marginLeft: 2 }
};
