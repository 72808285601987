import React from "react";
import { TextField } from "@mui/material";
import { Text } from "../text";

export const NumberInput = (props) => {
  const {
    id,
    ref,
    required = false,
    name,
    variant = "outlined",
    label,
    value,
    onChange,
    error,
    style,
    onBlur,
    isNum = false, // New prop to control numeric input
    ...rest
  } = props;

  const styles = {
    container: { marginBottom: "1px" },
    errorText: { paddingLeft: "px" },
  };

  // Handler to restrict input to numbers if isNum is true
  const handleChange = (e) => {
    const { value } = e.target;
    if (isNum) {
      // Allow only numeric values
      if (/^\d*$/.test(value)) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  return (
    <div style={styles.container}>
      <TextField
        id={id}
        ref={ref}
        required={required}
        name={name}
        variant={variant}
        label={label}
        value={value}
        error={error ? true : false}
        onChange={handleChange} // Custom handler to enforce numeric input
        sx={style}
        onBlur={onBlur}
        inputProps={isNum ? { inputMode: "numeric", pattern: "[0-9]*" } : {}} // Restrict to numeric input for mobile
        {...rest}
      />
      {error && (
        <Text variant="fieldError" text={error} style={styles.errorText} />
      )}
    </div>
  );
};
