import React from 'react';
import Header from '../../components/common/header';

const ShippingPolicy = () => {
  return (
    <div>
      <Header hideUserDetails={true} />
      <div style={{ padding: '16px' }}>
        <h1>Shipping & Delivery Policy</h1>
        <p>Last updated May 26, 2024</p>
        <p>
          Please carefully review our Shipping & Delivery Policy when purchasing our products. This
          policy will apply to any order you place with us.
        </p>

        <h2>What are my shipping & delivery options?</h2>

        <h3>Free Shipping</h3>
        <p>We do not ship any items and all contributions are towards social causes.</p>

        <h2>Do you deliver internationally?</h2>
        <p>We do not offer international shipping.</p>

        <h2>How can you contact us about this policy?</h2>
        <p>If you have any further questions or comments, you may contact us by:</p>
        <ul>
          <li>
            Email: <a href="mailto:secretary.rbitc@gmail.com">secretary.rbitc@gmail.com</a>.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ShippingPolicy;
