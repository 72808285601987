/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastProvider } from "react-toast-notifications";
import { store, persistor } from "./redux/store";
import { RootNavigation } from "./navigation/rootNavigation";
import { BrowserRouter as Router } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { API } from "./constants";

const App = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  window.onresize = () => {
    if (windowWidth !== window.innerWidth) {
      window.location.reload();
      setWindowWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    const app = initializeApp(API.firebaseConfig);
    getAnalytics(app);
  }, []);

  return (
    <ToastProvider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router basename={process.env.PUBLIC_URL}>
            <RootNavigation />
          </Router>
        </PersistGate>
      </Provider>
    </ToastProvider>
  );
};

export default App;
