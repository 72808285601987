import React, { useState } from 'react';
import { Screen } from '../../components';
import { Box, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { faqs } from './faqs';
import styles from './style';

const Faq = () => {
  const [expandedTitle, setExpandedTitle] = useState(null);
  const [expandedQuestion, setExpandedQuestion] = useState({});

  const handleExpandTitle = (panel) => (event, isExpanded) => {
    setExpandedTitle(isExpanded ? panel : false);
  };

  const handleExpandQuestion = (category, index) => (event, isExpanded) => {
    setExpandedQuestion({
      ...expandedQuestion,
      [category + '-' + index]: isExpanded
    });
  };

  return (
    <Screen showFooter={true}>
      <Box sx={styles.container}>
        <Typography variant="h3" sx={styles.heading}>FAQs</Typography>
        {Object.keys(faqs).map((category, index) => (
          <Accordion
            key={index}
            expanded={expandedTitle === category}
            onChange={handleExpandTitle(category)}
            sx={styles.accordion}
          >
            <AccordionSummary
              expandIcon={<ArrowForwardIcon />}
              aria-controls={`${category}-content`}
              id={`${category}-header`}
              sx={styles.summary}
            >
              <Typography variant="h5" component="span" sx={styles.title}>
                {faqs[category].title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={styles.faqList}>
                {faqs[category].qNa.map((item, innerIndex) => (
                  <Accordion
                    key={innerIndex}
                    expanded={expandedQuestion[category + '-' + innerIndex]}
                    onChange={handleExpandQuestion(category, innerIndex)}
                    sx={styles.accordion}
                  >
                    <AccordionSummary
                      expandIcon={<ArrowForwardIcon />}
                      aria-controls={`${category}-content-${innerIndex}`}
                      id={`${category}-header-${innerIndex}`}
                      sx={styles.summary}
                    >
                      <Typography variant="subtitle1" component="span" sx={styles.question}>
                        {item.q}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography variant="body2">{item.a}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Screen>
  );
};

export default Faq;
