/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, Box } from '@mui/material';
import { Text, UIButton } from '../ui-kit/index.js';
import { useDispatch } from 'react-redux';
import { loginRequest, socialLoginrequest } from '../../redux/slices/loginSlice.js';
import { loginSchema } from '../../utils/schemas.js';
import { ssoFormStyles } from './ssoFormStyles.js';
import { defaultValues } from '../../constants/defaultValues.js';
import Google from '../../assets/images/google.png';
import Apple from '../../assets/images/Apple.jpg';

import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useToasts } from 'react-toast-notifications';
import { ERROR_KEY } from '../../constants/keys.js';

export const SSOForm = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const provider = new GoogleAuthProvider();

  const [userData, setUserData] = useState({
    email_id: ''
  });

  const {
    register,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'all'
  });

  const onSubmit = () => {
    dispatch(loginRequest(userData));
  };

  const onGoogleLoginPressed = () => {
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        //not using token as of now, using our own tokens
        // const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        const loginObj = {
          email_id: user.email,
          first_name: user.displayName,
          phone_number: user.phoneNumber,
          image_url: user.photoURL
        };
        dispatch(socialLoginrequest(loginObj));
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        const errorMessage = error.message;
        addToast(errorMessage, { appearance: ERROR_KEY, autoDismiss: true });
      });
  };

  return (
    <Box sx={ssoFormStyles.container}>
      <Box
        sx={
          defaultValues.isResponsive
            ? ssoFormStyles.textfield_container_responsive
            : ssoFormStyles.textfield_container
        }>
        <TextField
          required
          name="emailId"
          variant="outlined"
          label="Email ID"
          value={userData.emailId}
          {...register('email')}
          error={errors.email}
          onChange={(e) => setUserData({ ...userData, email_id: e.target.value })}
        />
        <Text variant="fieldError" text={errors.email ? <>{errors.email.message}</> : null} />
      </Box>
      <UIButton
        item
        onClick={onSubmit}
        title="Login"
        disabled={!userData.email_id}
        sx={ssoFormStyles.button}
      />
      <Box sx={ssoFormStyles.horzonalContainer}>
        <Box sx={ssoFormStyles.horizonalLine}></Box>
        <Text text="Or" style={ssoFormStyles.textStyle(defaultValues.isResponsive)} />
        <Box sx={ssoFormStyles.horizonalLine}></Box>
      </Box>
      <Text text="Sign up with" style={ssoFormStyles.textStyle(defaultValues.isResponsive)} />

      {defaultValues.isResponsive ? (
        <img
          src={Google}
          style={ssoFormStyles.iconStyle(defaultValues.isResponsive)}
          onClick={onGoogleLoginPressed}></img>
      ) : (
        <Box
          sx={ssoFormStyles.signInContainer(defaultValues.isResponsive)}
          onClick={onGoogleLoginPressed}>
          <img src={Google} style={ssoFormStyles.iconStyle(defaultValues.isResponsive)} />
          <Text
            text="Continue with google"
            style={ssoFormStyles.textStyle(defaultValues.isResponsive)}
          />
        </Box>
      )}
      {/* <Box sx={ssoFormStyles.signInContainer(defaultValues.isResponsive)}>
        <img src={Apple} style={ssoFormStyles.iconStyle} />
        <Text
          text="Continue with apple"
          style={ssoFormStyles.textStyle(defaultValues.isResponsive)}
        />
      </Box> */}
    </Box>
  );
};
