import { Box } from '@mui/material';
import React from 'react';
import { Text } from '../../ui-kit';
import { styles } from './styles';

import emailIcon from '../../../assets/images/Email.png';
import phoneIcon from '../../../assets/images/Phone.png';
import { commaMaskedAmount } from '../../../utils/strings';
import { commonStyles } from '../../../theme/commonStyles';

export const DonorCard = ({ data }) => {
  return (
    <Box sx={styles.container}>
      <Text variant="primaryMenuText">
        {data.first_name} {data.last_name}
      </Text>
      <div style={commonStyles.divider} />
      <Box sx={styles.subItemContainer}>
        <img src={emailIcon} style={styles.itemIcon} />
        <Text variant="success">{data.email_id}</Text>
      </Box>
      <Box sx={styles.subItemContainer}>
        <img src={phoneIcon} style={styles.itemIcon} />
        <Text variant="success">{data.phone_number}</Text>
      </Box>
      <Text variant="success">Pan no: {data.pan_number}</Text>
      <Text variant="success">Donations: {commaMaskedAmount(data.amount)}</Text>
    </Box>
  );
};
