import { Box, TextField, InputAdornment } from '@mui/material';
import React from 'react';
import { Text } from '../../ui-kit';
import SearchIcon from '@mui/icons-material/Search';
import { pageHeaderStyles } from './pageHeaderStyles';
import { defaultValues } from '../../../constants';

export const PageHeader = ({ title, searchContent, onSearch, searchText }) => {
  return (
    <Box
      sx={defaultValues.isResponsive ? pageHeaderStyles.resContainer : pageHeaderStyles.container}>
      <Text
        variant="header"
        style={defaultValues.isResponsive ? pageHeaderStyles.resTitle : pageHeaderStyles.title}
        text={title}
      />{' '}
      {searchContent && (
        <TextField
          id="outlined-multiline-flexible"
          label={searchContent}
          value={searchText}
          onChange={(e) => onSearch(e.target.value)}
          size="small"
          sx={
            defaultValues.isResponsive ? pageHeaderStyles.resTextField : pageHeaderStyles.textField
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
      )}
    </Box>
  );
};
