export const styles = {
  container: { width: '615px' },
  responsiveContainer: { width: '100%' },

  title: { fontSize: 30, fontWeight: 600 },
  responsiveTitle: { fontSize: 18, fontWeight: 500 },

  subTitle: {
    fontSize: 24,
    weigh: 600,
    paddingBottom: '1%'
  },
  responsiveSubTitle: {
    fontSize: 15,
    weigh: 500,
    paddingBottom: '1%'
  },

  descText: { fontSize: 18, fontWeight: 500 },
  resDescText: { fontSize: 12, fontWeight: 500 },

  dateText: { fontSize: 18, fontWeight: 600 },
  resDateText: { fontSize: 12, fontWeight: 600 }
};
