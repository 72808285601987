import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { UIButton, Text } from '../ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { findUserEmail } from '../../redux/selectors/loginSelector';
import { changeMailRequest, otpResendRequest, verificationRequest } from '../../redux/slices';
import { Box } from '@mui/material';
import { otpFormStyles } from './otpFormStyles';
import { defaultValues } from '../../constants/defaultValues';

export const OTPForm = () => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');

  const [showText, setShowText] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [count, setCount] = useState(45);

  const savedEmail = useSelector(findUserEmail);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleSubmit = () => {
    let formatedOtp = parseInt(otp);
    let data = {
      email_id: savedEmail,
      login_otp: formatedOtp
    };

    dispatch(verificationRequest(data));
  };

  const handleResendOTP = () => {
    const data = {
      email_id: savedEmail
    };
    if (!buttonDisable) {
      setShowText(true);
      setButtonDisable(true);
      dispatch(otpResendRequest(data));
      let interval = setInterval(() => {
        setCount((prev) => {
          if (prev === 1) {
            clearInterval(interval);
            setShowText(false);
            setButtonDisable(false);
            setCount(45);
          }
          return prev - 1;
        });
      }, 1000);
    }
  };

  const handleChangeMail = () => {
    dispatch(changeMailRequest());
  };

  return (
    <Box style={otpFormStyles.container}>
      <Box>
        <Box
          sx={
            defaultValues.isResponsive
              ? otpFormStyles.responsiveNotificationContainer
              : otpFormStyles.notificationContainer
          }>
          <Text
            style={
              defaultValues.isResponsive
                ? otpFormStyles.responsiveNotification
                : otpFormStyles.notification
            }>
            OTP sent to <span style={otpFormStyles.emailColor}>{savedEmail}</span>
          </Text>
          <Text
            text="Change Mail"
            style={
              defaultValues.isResponsive
                ? otpFormStyles.changeOptionResponsive
                : otpFormStyles.changeOption
            }
            onClick={handleChangeMail}
          />
        </Box>

        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={
            <span
              style={
                defaultValues.isResponsive ? otpFormStyles.responsiveSpan : otpFormStyles.spanStyle
              }></span>
          }
          renderInput={(props) => <input {...props} />}
          isInputNum={true}
          shouldAutoFocus={true}
          inputStyle={
            defaultValues.isResponsive ? otpFormStyles.responsiveInput : otpFormStyles.inputStyles
          }
          containerStyle={
            defaultValues.isResponsive
              ? otpFormStyles.responsiveContainer
              : otpFormStyles.containerStyle
          }
          handleSubmit={(e) => {
            e.preventDefault();
          }}
        />
        <Box sx={otpFormStyles.otpResendButtonContainer}>
          <Text
            text="Resend OTP"
            onClick={handleResendOTP}
            sx={
              buttonDisable ? otpFormStyles.clickedOtpResendButton : otpFormStyles.otpResendButton
            }
            disabled={buttonDisable}
          />
          {showText && (
            <Text text={`OTP will be send in ${count} seconds`} sx={otpFormStyles.resendMessage} />
          )}
        </Box>
      </Box>

      <UIButton item sx={otpFormStyles.buttonMargin} onClick={handleSubmit} title="Submit" />
    </Box>
  );
};
