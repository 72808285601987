import { put, takeLatest, all } from 'redux-saga/effects';

import {
  getAllUserRequest,
  allUserRequestSuccess,
  allUserRequetFailure,
  updateUserSuccess,
  updateUserFailure,
  updateUserByAdminRequest
} from '../slices/userSlice';
import { getAllUsers, updateUserByAdmin } from '../../services/api';
import { setAuthError } from '../slices';

function* allUsersRequest() {
  try {
    const response = yield getAllUsers();
    yield put(allUserRequestSuccess(response.data.data));
  } catch (error) {
    yield all([
      put(allUserRequetFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* userUpdateByAdmin(action) {
  const { id, data } = action.payload;
  try {
    const response = yield updateUserByAdmin(id, data);
    yield put(updateUserSuccess(response.data));
  } catch (error) {
    yield all([
      put(updateUserFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

export function* usersSaga() {
  yield takeLatest(getAllUserRequest.type, allUsersRequest);
  yield takeLatest(updateUserByAdminRequest.type, userUpdateByAdmin);
}
