import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
export const createEventstyles = {
  innerContainer: {
    m: 4,
    borderRight: 'solid',
    borderRightWidth: '1px',
    maxWidth: '600px',
    borderRightColor: color.primaryBackground
  },
  inputFieldStyle: { width: '100%' },
  datePickerContainer: {
    display: 'flex',
    flexDirection: { md: 'row', xs: 'column' },
    justifyContent: 'space-between',
    width: '100%'
  },
  innerTextField: { width: '48%' },

  title: { mb: 1 },
  datePicker: { marginBottom: defaultValues.isResponsive && '16px' },

  imageSelectContainer: {
    marginTop: '20px',
    textAlign: 'center'
  },
  imageSelectLabel: {
    marginBottom: '10px'
  },
  imageOptionsContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  },
  imageOption: {
    margin: '5px',
    cursor: 'pointer'
  },
  imageOptionImage: {
    width: '100px',
    height: '100px',
    objectFit: 'cover'
  },
  imagePreviewContainer: {
    marginTop: '10px'
  },
  imagePreviewLabel: {
    marginBottom: '5px'
  },
  imagePreview: {
    width: '100%',
    maxWidth: '300px',
    height: 'auto'
  },
  modalStyle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: 2
  },
  cardsButton: {
    margin: '20px',
    display: 'flex',
    justifyContent: 'center'
  }
};
