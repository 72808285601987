import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  Menu,
  Toolbar,
  MenuItem,
  Typography,
} from "@mui/material";
import { headerStyles } from "./headerStyles";
import { Text, UIButton } from "../ui-kit";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { userInfo } from "../../redux/selectors/loginSelector";
import logo from "../../assets/images/givfting.png";
import { Route_Paths } from "../../constants";
import donateIcon from "../../assets/images/donate.png";
import { setLogOutRequest } from "../../redux/slices";
import ContactModal from "../contact-modal";
import { routeNavigation } from "../../constants/routeNavigation";

const Header = (props) => {
  const bgColor = props.color;
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [userImage, setUserImage] = useState(donateIcon);

  const user = useSelector(userInfo);

  useEffect(() => {
    if (user && user.image_url && user.image_url.length) {
      setUserImage(user.image_url);
    }
  }, [user]);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [open, setOpen] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleMenuItemClick = (item) => {
    if (item.name === "LOGOUT") {
      dispatch(setLogOutRequest(true));
      setAnchorElNav(null);
    } else if (item.name === "ABOUT US") {
      navigation(`${Route_Paths.HOME}#about`);
    } else if (item.name === "CONTACT US") {
      navigation(item.path);
    } else if (item.name === "HOME" && user) {
      navigation(Route_Paths.EVENTS);
    } else {
      navigation(item.path);
    }
  };

  const handleHome = () => {
    navigation(Route_Paths.HOME);
  };

  const filteredNavigationOptions = user
    ? routeNavigation.navigation_options.filter(
        (item) => item.name !== "ABOUT US",
      )
    : routeNavigation.navigation_options;

  return (
    <>
      <Box position="static" style={headerStyles.container(bgColor)}>
        <ContactModal open={open} handleClose={() => setOpen(false)} />
        <Container maxWidth={"100%"}>
          <Toolbar disableGutters sx={headerStyles.toolbar}>
            <Box sx={headerStyles.logoContainer} onClick={handleHome}>
              <img src={logo} alt="logo" style={headerStyles.logoStyles} />
            </Box>
            <Box sx={headerStyles.menuStyle}>
              {filteredNavigationOptions.map((item, index) => (
                <Typography
                  key={index}
                  component="a"
                  href={item.link}
                  sx={headerStyles.linkStyle}
                  onClick={() => handleMenuItemClick(item)}
                  style={{ textTransform: "capitalize" }}
                >
                  {item.name}
                </Typography>
              ))}
            </Box>
            {!props.hideUserDetails && (
              <Box sx={headerStyles.userIcon}>
                {user ? (
                  <IconButton>
                    <img
                      alt="Remy Sharp"
                      src={userImage}
                      style={headerStyles.userLogo}
                    />
                  </IconButton>
                ) : (
                  <UIButton
                    title="Sign In"
                    style={headerStyles.signInBtn}
                    onClick={() => navigation(Route_Paths.LOGIN)}
                  />
                )}
                {user && (
                  <Box>
                    <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                    >
                      <ArrowDropDownIcon sx={headerStyles.downIcon} />
                    </IconButton>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={headerStyles.toggleMenuStyle}
                    >
                      {routeNavigation.navigation_settings_option.map(
                        (page, index) => (
                          <MenuItem
                            key={index.toString()}
                            onClick={() => handleMenuItemClick(page)}
                          >
                            <Text textAlign="center">{page.name}</Text>
                          </MenuItem>
                        ),
                      )}
                    </Menu>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={headerStyles.toggleMenuHide}
                    >
                      {routeNavigation.settings_options.map((item, index) => (
                        <MenuItem
                          key={index.toString()}
                          onClick={() => handleMenuItemClick(item)}
                        >
                          <Text textAlign="center">{item.name}</Text>
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                )}
              </Box>
            )}
          </Toolbar>
        </Container>
      </Box>
    </>
  );
};

export default Header;
