export const Route_Paths = {
  LOGIN: '/login',
  MANAGE_PROJECT: '/manage-projects',
  ADD_PROJECT: '/add_project',
  EDIT_PROJECT: '/edit_project',
  HANDLE_PROJECTS: '/handle-project',
  MANAGE_EVENTS: '/manage-events',
  MANAGE_USERS: '/manage-users',
  DONATIONS: '/donations',
  VIEW_EVENTS: '/view-event',
  EDIT_EVENTS: '/edit-events',
  VIEW_DONATIONS: '/view-donations',
  VIEW_PROFILE: '/view-profile',
  ADMIN_VIEW_EVENTS: '/admin-view-event',
  CREATE_EVENTS: '/create-events',
  EVENTS: '/events',
  GUEST: '/event_details',
  CHECKOUT: '/checkout',
  HOME: '/',
  CONTACTUS: '/contact',
  FALLBACK: '/fallback',
  TERMS: '/terms-conditions',
  PRIVACY_POLICY: '/privacy-policy',
  REFUND: '/refund-policy',
  SHIPPING: '/shipping-policy',
  FAQ: '/faq',
  CARD:'/card',
  ABOUT: '/#about',
};
