/* eslint-disable no-undef */
export const API = {
  currentEnv: process.env.REACT_APP_SERVER, //api server environment dev/test/uat/prod
  baseURL: {
    dev: process.env.REACT_APP_API_URL_DEV,
    test: process.env.REACT_APP_API_URL_TEST,
    uat: process.env.REACT_APP_API_URL_UAT,
    prod: process.env.REACT_APP_API_URL_PROD
  },
  baseRoute: {
    dev: process.env.REACT_APP_BASE_ROUTE_DEV,
    test: process.env.REACT_APP_BASE_ROUTE_TEST,
    uat: process.env.REACT_APP_BASE_ROUTE_UAT,
    prod: process.env.REACT_APP_BASE_ROUTE_PROD
  },
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJET_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  },
  noAuthUrls: {
    login: '/v1/login',
    social_login: '/v1/social_login',
    verify_login: '/v1/verify_login_otp',
    resend_login_otp: '/v1/resend_otp',
    initiateTransaction: '/v1/donation/initiate-transaction',
    updateTransaction: '/v1/donation/update_transaction_status',
    submitQuery: '/v1/submit-query',
    contact_us: '/v1/contact-us',
    donor_email: '/v1/donor-email',
    create_card: '/v1/donation/create-card',
    get_card: '/v1/donation/get-card'
  },
  auth_urls: {
    projects: '/v1/projects',
    active_projects: '/v1/projects/active',
    users: '/v1/users',
    events: '/v1/events',
    create_events: '/v1/events/create_events',
    user_events: '/v1/user_events',
    update_user_by_admin: '/v1/users/update_by_admin',
    donors: '/v1/donors', //might need to remove
    eventDetails: '/v1/event_details',
    deleteProjectImage: '/v1/projects',
    getAllDonation: '/v1/donation/get-all-donations',
    getAllThankyouCards: '/v1/get_thank_you_cards'
  }
};
