export const Styles = {
  container: {
    flexDirection: 'row',
    display: 'flex',
    height: window.innerHeight
  },
  responsiveContainer: {
    flexDirection: 'row',
    display: 'column',
    height: window.innerHeight
  },
  subContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto'
  },
  footerContainer: { zIndex: 1 }
};
