import React, { useState } from 'react';
import { Screen, UIButton } from '../../components/ui-kit';
import UserDetails from '../../components/settings/details';
import { Box, Stack } from '@mui/material';
import Profile from '../../components/settings/profile';
import { useNavigate } from 'react-router-dom';
import { profileStyles } from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { userInfo } from '../../redux/selectors';
import { updateUserRequest } from '../../redux/slices';
import { Route_Paths } from '../../constants';

const SettingsPage = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(false);

  const userData = useSelector(userInfo);

  const values = {};
  const dataOnChange = (data, isValid) => {
    setIsValid(isValid);
    values.first_name = data.firstName;
    values.last_name = data.lastName;
    values.phone_number = data.phoneNo;
    values.pin_code = data.pinCode;
    values.city = data.city;
    values.state = data.state;
    values.address_line_one = data.addressLineOne;
    values.address_line_two = data.addressLineTwo;
  };
  const profileImage = (img) => {
    values.image = img;
  };

  const handleSubmit = () => {
    dispatch(updateUserRequest(values));
    navigation(Route_Paths.EVENTS);
  };

  return (
    <Screen showHeader={true} showFooter={true}>
      <Box sx={profileStyles.container}>
        <Box sx={profileStyles.subContainer}>
          <Box sx={profileStyles.innerContainer}>
            <UserDetails data={userData} onDataChange={dataOnChange} />
          </Box>
          <Box sx={profileStyles.innerContainer}>
            <Profile imageUrl={userData.image_url} photo={profileImage} />
          </Box>
        </Box>
        <Stack style={profileStyles.buttonContainer}>
          <UIButton title="Save" onClick={handleSubmit} disabled={!isValid} />
        </Stack>
      </Box>
    </Screen>
  );
};

export default SettingsPage;
