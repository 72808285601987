import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import { styles } from './styles';
import {
  allProjects,
  isAdminProfile,
  loading,
  projectErrorMessage,
  projectSuccessMessage
} from '../../redux/selectors';
import {
  ERROR_KEY,
  Route_Paths,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../constants';
import { Loader, PageHeader, ProjectCard, Screen, Text, UIButton } from '../../components';

import { useNavigate } from 'react-router-dom';
import { allProjectsRequest } from '../../redux/slices/projectsSlice';

export const ManageProjects = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const isAdmin = useSelector(isAdminProfile);
  const projects = useSelector(allProjects);
  const isLoading = useSelector(loading);
  const projectSuccess = useSelector(projectSuccessMessage);
  const projectError = useSelector(projectErrorMessage);

  const [projectSearch, setProjectSearch] = useState([]);

  useEffect(() => {
    dispatch(allProjectsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (projectSuccess) {
      addToast(getSuccessMessage(projectSuccess), {
        appearance: SUCCESS_KEY,
        autoDismiss: true
      });
    }
  }, [projectSuccess]);

  useEffect(() => {
    if (projectError) {
      addToast(getErrorMessage(projectError), {
        appearance: ERROR_KEY,
        autoDismiss: true
      });
    }
  }, [projectError]);

  useEffect(() => {
    let temp = [...projects];
    setProjectSearch(temp);
  }, [projects]);

  const handleNavigation = (type, data) => {
    let path = Route_Paths.ADD_PROJECT;
    if (type === 'edit') {
      let id = data._id;
      path = `${Route_Paths.EDIT_PROJECT}?${id}`;
    }
    navigation(path, { state: { type, data } });
  };

  const handleSearch = useCallback(
    (text) => {
      const tempData = [...projects];
      if (text.length >= 2) {
        const filteredProjects = tempData.filter(
          (item) =>
            item.project_name && item.project_name.toLowerCase().includes(text.toLowerCase())
        );
        setProjectSearch(filteredProjects);
      } else {
        setProjectSearch(tempData);
      }
    },
    [projects]
  );

  return (
    <Screen showSideBar={isAdmin} showFooter={true}>
      <div style={styles.container}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!defaultValues.isResponsive && (
              <Box sx={styles.headerContainer}>
                <PageHeader
                  title={'Dashboard'}
                  searchContent={'Search Projects'}
                  onSearch={handleSearch}
                />
              </Box>
            )}
            <Grid container sx={styles.height}>
              {projectSearch.length > 0 ? (
                projectSearch.map((item, index) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    key={index}
                    sx={{ pl: !defaultValues.isResponsive && 3, pb: 1 }}>
                    <ProjectCard data={item} onEditClick={() => handleNavigation('edit', item)} />
                  </Grid>
                ))
              ) : (
                <Text
                  text="No projects available"
                  variant="secondarySubTitle"
                  style={styles.notificationStyle}
                />
              )}
            </Grid>
            <div
              style={
                defaultValues.isResponsive ? styles.resButtonContainer : styles.buttonContainer
              }>
              <UIButton
                title={'Add new project'}
                style={styles.button}
                onClick={() => handleNavigation('add', null)}
              />
            </div>
          </>
        )}
      </div>
    </Screen>
  );
};
