import { color } from "../../theme/color";
import { fonts } from "../../theme/fonts";

export const headerStyles = {
  container: (bgColor) => ({
    background: bgColor ? color.primaryBackground : color.primary,
    width: "100%",
  }),
  headerText: {
    color: color.primaryText,
    fontFamily: fonts.header,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  buttonStyle: { minWidth: "10%", maxWidth: "10%" },
  toggleMenu: {
    flexGrow: 1,
    display: { xs: "flex", md: "none" },
  },
  toggleMenuStyle: {
    display: { xs: "block", md: "none" },
  },
  toggleMenuHide: { display: { xs: "none", md: "block" } },
  logoContainer: {
    height: "100px",
    display: "flex",
    justifyContent: "flex-start",
  },
  logoStyles: {
    height: "100%",
    display: "flex",
    justifyContent: "start",
  },
  menuStyle: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    gap: "20px",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  linkStyle: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "30.34px",
    letterSpacing: "0.02em",
    textAlign: "center",
    textDecoration: "none",
    color: "inherit",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  userIcon: {
    display: "flex",
    alignItems: "center",
  },
  downIcon: { color: color.palette.primaryBlack },

  iconContainer: { display: "flex", justifyContent: "flex-end" },
  iconsStyles: {
    width: "30px",
    height: "30px",
    objectFit: "contain",
    margin: "10px",
    cursor: "pointer",
  },
  userLogo: { width: "50px", height: "50px", borderRadius: "25px" },
  signInBtn: {
    border: `0.5px solid ${color.palette.primaryBlack}`,
    padding: "12px 34px",
    gap: "10px",
    borderRadius: "90px",
    backgroundColor: color.palette.primaryWhite,
    color: color.palette.primaryBlack,
    cursor: "pointer",
    textAlign: "center",
    "&:hover": {
      backgroundColor: color.palette.primaryBlack,
      color: color.palette.primaryWhite,
    },
  },
};
