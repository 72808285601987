/* eslint-disable prettier/prettier */
import React, { useCallback, useState } from "react";
import { Text, UIButton } from "../../ui-kit";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Switch } from "@mui/material";
import { manageEventsStyle } from "./styles";
import { API, Route_Paths, defaultValues } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isAdminProfile } from "../../../redux/selectors";
import { setCurrentEventData } from "../../../redux/slices";
import shareIcon from "../../../assets/images/Share.png";
import editIcon from "../../../assets/images/Edit.png";
import { RWebShare } from "react-web-share";
import { commaMaskedAmount } from "../../../utils/strings";

export const ManageEventCard = ({ data, onStatusChange, isDonation }) => {
  const navigation = useNavigate();
  const dispatch = useDispatch();

  const isAdmin = useSelector(isAdminProfile);

  const [isActive, setIsActive] = useState(data.is_active);

  const urlParts = data?.custom_url?.split("/");
  const lastUrlPart = urlParts?.[urlParts.length - 1] || "";

  const handleNavigation = useCallback(() => {
    dispatch(setCurrentEventData(data));
    if (isAdmin) {
      navigation(`${Route_Paths.ADMIN_VIEW_EVENTS}?${lastUrlPart}`, {
        state: { data: data },
      });
    } else {
      navigation(`${Route_Paths.VIEW_EVENTS}?${lastUrlPart}`);
    }
  }, []);

  const handleSwitchChange = (id, type, custom_url) => {
    setIsActive(type);
    onStatusChange(id, type, custom_url);
  };

  // function for editing event
  const handleEdit = () => {
    dispatch(setCurrentEventData(data));
    navigation(`${Route_Paths.EDIT_EVENTS}?${lastUrlPart}`);
  };

  return (
    <Box sx={manageEventsStyle.container}>
      <div style={manageEventsStyle.firstInnerContainer}>
        <div style={manageEventsStyle.textContainer}>
          <Text
            text={data.event_name}
            style={
              defaultValues.isResponsive
                ? manageEventsStyle.resTextName
                : manageEventsStyle.textName
            }
          />
          <Text
            text={`Hosted by ${data.host_name}`}
            style={
              defaultValues.isResponsive
                ? manageEventsStyle.resText
                : manageEventsStyle.textHost
            }
          />
          <Text
            text={
              data.event_description && data.event_description.length > 40
                ? data.event_description.slice(0, 40) + "..."
                : data.event_description
            }
            style={
              defaultValues.isResponsive
                ? manageEventsStyle.resText
                : manageEventsStyle.descText
            }
          />
        </div>
        <div style={manageEventsStyle.qrCodeImage}>
          {" "}
          {data.qr_code_url && (
            <Box sx={manageEventsStyle.qrCodeContainer}>
              <img src={data.qr_code_url} alt="Event QR Code" />
            </Box>
          )}
        </div>
        {!isDonation && (
          <div style={manageEventsStyle.leftFirstContainer}>
            {!isAdmin && !isDonation && (
              <Box>
                <img
                  src={editIcon}
                  alt="editIcon"
                  style={manageEventsStyle.iconContainer}
                  onClick={handleEdit}
                />

                <RWebShare
                  data={{
                    text: defaultValues.inviteToDonateMsg(data.event_name),
                    url: `${API.baseRoute[API.currentEnv]}/${lastUrlPart}`,
                    title: "OTOO",
                    sites: ["WhatsApp"],
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <img
                    src={shareIcon}
                    alt="shareIcon"
                    style={manageEventsStyle.iconContainer}
                  />
                </RWebShare>
              </Box>
            )}
            <Box sx={manageEventsStyle.arrowButton}>
              <ArrowForwardIosIcon onClick={handleNavigation} />
            </Box>

            <Text
              text="View event"
              style={
                defaultValues.isResponsive
                  ? manageEventsStyle.res1Text
                  : manageEventsStyle.viewText
              }
            />
          </div>
        )}
      </div>
      <div style={manageEventsStyle.secondInnerContainer}>
        <Text
          text={
            data.donation
              ? `${commaMaskedAmount(data.donation)} has been raised`
              : "No donation for this event yet"
          }
          style={
            defaultValues.isResponsive
              ? manageEventsStyle.resText
              : manageEventsStyle.amntText
          }
        />

        {isDonation && (
          <UIButton
            title="Donate more"
            style={
              defaultValues.isResponsive
                ? manageEventsStyle.resDonateButton
                : manageEventsStyle.donateButton
            }
            onClick={() => {
              const url = `${API.baseRoute[API.currentEnv]}/${lastUrlPart}`;
              window.open(url, "_blank");
            }}
          />
        )}

        {isAdmin && (
          <>
            {defaultValues.isResponsive ? (
              <Switch
                defaultChecked={isActive}
                onChange={() =>
                  handleSwitchChange(data._id, !isActive, data.custom_url)
                }
                sx={manageEventsStyle.resButton}
              />
            ) : (
              <div style={manageEventsStyle.buttonContainer}>
                <UIButton
                  title="Active"
                  style={
                    data.is_active
                      ? manageEventsStyle.buttonActive
                      : manageEventsStyle.button
                  }
                  onClick={() =>
                    onStatusChange(data._id, true, data.custom_url)
                  }
                />
                <UIButton
                  title="Inactive"
                  style={
                    data.is_active
                      ? manageEventsStyle.button
                      : manageEventsStyle.inactivebutton
                  }
                  onClick={() =>
                    onStatusChange(data._id, false, data.custom_url)
                  }
                />
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  );
};
