"use client";
import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { styles } from "./styles";
import { Text } from "../text";

export const DatePicker = (props) => {
  const { date, onChangeDate, label, name, ref, onOpen, errorMessage } = props;
  return (
    <div style={styles.container}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          disablePast
          ref={ref}
          label={label}
          name={name}
          value={date ? new Date(date) : null}
          onChange={onChangeDate}
          onOpen={onOpen}
        />
        {errorMessage && <Text variant="fieldError">{errorMessage}</Text>}
      </LocalizationProvider>
    </div>
  );
};
