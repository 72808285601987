import { color } from '../../../theme/color';

export const Styles = {
  container: {
    width: '100%',
    height: '80vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  subContainer: (isResponsive) => ({
    width: isResponsive ? '80%' : '30%',
    height: '30%',
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: 2,
    borderRadius: 2,
    position: 'relative'
  }),
  buttonContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    p: 2
  },
  buttonStyle: (bgColor) => ({
    width: '30%',
    backgroundColor: bgColor
  }),
  iconContainer: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: color.primary,
    position: 'absolute',
    top: '-5%',
    right: '-5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },

  textStyle: (isResponsive) => ({ fontSize: isResponsive ? 14 : 28 })
};
