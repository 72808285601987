import React, { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import {
  AddProject,
  CheckoutPage,
  CreateEventPage,
  EventDetails,
  FallbackPage,
  GuestLanding,
  HomePage,
  HostEvents,
  ManageEvent,
  ManageProjects,
  ManageUsers,
  PrivacyPolicy,
  SignInPage,
} from "../pages";
import { AdminEvents } from "../pages/events/admin/viewEvent";
import SettingsPage from "../pages/profile";
import { Route_PPaths, Route_Paths, getErrorMessage } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { getAuthStatus, isAdminProfile, isAuthError } from "../redux/selectors";
import TermsAndConditions from "../pages/terms and condition";
import ShippingPolicy from "../pages/shipping policy";
import RefundPolicy from "../pages/refund policy";
import Faq from "../pages/faq";
import ContactUs from "../pages/contact-us";
import Card from "../components/common/card";
import { Donations } from "../pages/all-donations";
import PrivateRoute from "./PrivateRoute";
import AdminRoute from "./AdminRoute";

export const RootNavigation = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const isAuthenticated = useSelector(getAuthStatus);
  const isAdmin = useSelector(isAdminProfile);

  const authError = useSelector(isAuthError);

  useEffect(() => {
    if (authError) {
      addToast(getErrorMessage(authError), {
        appearance: "error",
        autoDismiss: true,
      });
      dispatch({ type: "USER_LOGOUT" });
      sessionStorage.clear();
      navigation(Route_Paths.LOGIN);
    }
  }, [authError]);

  useEffect(() => {
    if (isAuthenticated) {
      if (isAdmin && location.pathname === Route_Paths.HOME) {
        navigation(Route_Paths.MANAGE_PROJECT);
      } else if (!isAdmin && location.pathname === Route_Paths.HOME) {
        navigation(Route_Paths.EVENTS);
      }
    }
  }, [isAuthenticated, isAdmin, location.pathname]);

  return (
    <Routes>
      {/* Public Routes - Accessible by Guests */}
      <Route path={Route_Paths.HOME} element={<HomePage />} />
      <Route path={Route_Paths.LOGIN} element={<SignInPage />} />
      <Route path={Route_Paths.TERMS} element={<TermsAndConditions />} />
      <Route path={Route_Paths.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={Route_Paths.SHIPPING} element={<ShippingPolicy />} />
      <Route path={Route_Paths.REFUND} element={<RefundPolicy />} />
      <Route path={Route_Paths.FAQ} element={<Faq />} />
      <Route path={Route_Paths.CONTACTUS} element={<ContactUs />} />
      <Route path={Route_Paths.FALLBACK} element={<FallbackPage />} />
      <Route path={"/:id"} element={<GuestLanding />} />
      <Route path={Route_Paths.CHECKOUT} element={<CheckoutPage />} />

      {/* Routes for Authenticated Users (Private Routes) */}

      <Route
        path={Route_PPaths.DONATIONS}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Donations />
          </PrivateRoute>
        }
      />

      <Route
        path={Route_PPaths.VIEW_EVENTS}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <EventDetails />
          </PrivateRoute>
        }
      />
      <Route
        path={Route_PPaths.ADD_PROJECT}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <AddProject />
          </PrivateRoute>
        }
      />
      <Route
        path={Route_PPaths.EDIT_PROJECT}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <AddProject />
          </PrivateRoute>
        }
      />

      <Route
        path={Route_PPaths.CREATE_EVENTS}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <CreateEventPage />
          </PrivateRoute>
        }
      />
      <Route
        path={Route_PPaths.EDIT_EVENTS}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <CreateEventPage />
          </PrivateRoute>
        }
      />

      <Route
        path={`${Route_PPaths.CARD}/:id`}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Card />
          </PrivateRoute>
        }
      />

      <Route
        path={Route_PPaths.EVENTS}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <HostEvents />
          </PrivateRoute>
        }
      />
      <Route
        path={Route_PPaths.VIEW_DONATIONS}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <HostEvents />
          </PrivateRoute>
        }
      />
      <Route
        path={Route_PPaths.VIEW_PROFILE}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <SettingsPage />
          </PrivateRoute>
        }
      />

      {/* Admin-Only Routes */}
      <Route
        path={Route_PPaths.MANAGE_PROJECT}
        element={
          <AdminRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
            <ManageProjects />
          </AdminRoute>
        }
      />
      <Route
        path={Route_PPaths.MANAGE_USERS}
        element={
          <AdminRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
            <ManageUsers />
          </AdminRoute>
        }
      />

      <Route
        path={Route_PPaths.EVENTS}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
            <AddProject />
          </PrivateRoute>
        }
      />
      <Route
        path={Route_PPaths.ADMIN_VIEW_EVENTS}
        element={
          <AdminRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
            <AdminEvents />
          </AdminRoute>
        }
      />
      <Route
        path={Route_PPaths.MANAGE_EVENTS}
        element={
          <AdminRoute isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
            <ManageEvent />
          </AdminRoute>
        }
      />
    </Routes>
  );
};
