import { Box } from '@mui/material';
import React from 'react';
import { Text } from '../../ui-kit';
import { defaultValues } from '../../../constants';
import { styles } from './eventCardStyle';
import moment from 'moment';
export const EventCard = (props) => {
  const startDate = moment(props.start_date_time).format('MM/DD/YYYY, h:mm A');

  return (
    <Box sx={defaultValues.isResponsive ? styles.responsiveContainer : styles.container}>
      <Text
        text={props.event_name}
        variant="sectionTitle"
        style={defaultValues.isResponsive ? styles.responsiveTitle : styles.title}
      />
      <Text
        text={`Hosted by ${props.host_name}`}
        style={defaultValues.isResponsive ? styles.responsiveSubTitle : styles.subTitle}
      />
      <Text
        text={props.event_description}
        style={defaultValues.isResponsive ? styles.resDescText : styles.descText}
      />
      <Text
        text={`On ${startDate} Onwards`}
        style={defaultValues.isResponsive ? styles.resDateText : styles.dateText}
      />
    </Box>
  );
};
