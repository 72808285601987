import { Route_Paths } from "../navigation/routes";

export const routeNavigation = {
    navigation_options: [
        { name: 'HOME', path: Route_Paths.HOME },
        { name: 'ABOUT US' },
        { name: 'CONTACT US', path:Route_Paths.CONTACTUS }
      ],
      settings_options: [{ name: 'PROFILE', path: Route_Paths.VIEW_PROFILE }, { name: 'LOGOUT' }],
      navigation_settings_option: [
        { name: 'HOME', path: Route_Paths.HOME },
        { name: 'PROFILE', path: Route_Paths.VIEW_PROFILE },
        { name: 'ABOUT US' },
        { name: 'CONTACT US' },
        { name: 'LOGOUT' }
      ],
}