import { Box } from '@mui/material';
import React from 'react';
import { Text } from '../../ui-kit';

import msgIcon from '../../../assets/images/Email.png';
import phoneIcon from '../../../assets/images/Phone.png';
import { styles } from './DonersCardStyles';
import { defaultValues } from '../../../constants';
import { commaMaskedAmount } from '../../../utils/strings';

export const DonersCard = ({ data }) => {
  return (
    <Box sx={styles.container}>
      <Box sx={styles.subContainer}>
        <Text style={defaultValues.isResponsive ? styles.resTitle : styles.title}>
          {data.first_name}
          {data.last_name}
        </Text>
        <Box sx={styles.textContainer}>
          <img src={msgIcon} style={styles.icon} />
          <Text
            text={data.email_id}
            style={defaultValues.isResponsive ? styles.resText : styles.text}
          />
        </Box>
        <Box sx={styles.textContainer}>
          <img src={phoneIcon} style={styles.icon} />
          <Text
            text={data.phone_number}
            style={defaultValues.isResponsive ? styles.resText : styles.text}
          />
        </Box>
        <Box sx={styles.textContainer}>
          <Text text="Pan-no:" style={defaultValues.isResponsive ? styles.resText : styles.text} />
          <Text
            text={data.pan_number}
            style={defaultValues.isResponsive ? styles.resText : styles.text}
          />
        </Box>
        <Box sx={styles.textContainer}>
          <Text
            text="Donations:"
            style={defaultValues.isResponsive ? styles.resAmnt : styles.amnt}
          />
          <Text
            text={commaMaskedAmount(data.amount)}
            style={defaultValues.isResponsive ? styles.resAmnt : styles.amnt}
          />
        </Box>
      </Box>
    </Box>
  );
};
