import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { style } from './stlye';

export const Loader = ({ isFullScreen = false }) => {
  return (
    <Box sx={style.continer(isFullScreen)}>
      <CircularProgress color={'inherit'} />
    </Box>
  );
};
