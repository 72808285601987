import { Box, Stack, Typography, Button } from '@mui/material';
import React from 'react';
import { Text } from '../../ui-kit';
import EditIcon from '@mui/icons-material/Edit';
import { styles } from './cardStyles';
import { defaultValues } from '../../../constants';
import { commaMaskedAmount } from '../../../utils/strings';
import { useDispatch } from 'react-redux';
import { updateProjectRequest } from '../../../redux/slices';
import { ImageSlider } from '../../ui-kit/slider';

export const ProjectCard = ({ data, onEditClick }) => {
  const truncatedProjectName = truncateText(data.project_name, 40);
  const dispatch = useDispatch();
  const handleStatusChange = (newStatus) => {
    const id = data._id;
    const formData = { ...data, status: newStatus, images: [] };
    dispatch(updateProjectRequest({ id, formData }));
  };
  return (
    <Box sx={defaultValues.isResponsive ? styles.resContainer : styles.detailsContainer}>
      <Box sx={styles.mainDetails}>
        <ImageSlider images={data.images} width={160} height={130} />
        <Box sx={styles.titlesContainer}>
          <Box sx={styles.primaryTitle}>
            <Box sx={styles.heading}>
              <Text
                variant="header"
                text={truncatedProjectName}
                style={defaultValues.isResponsive ? styles.responsiveText : styles.textSize}
              />
            </Box>
            <EditIcon sx={styles.iconStyle} onClick={onEditClick} />
          </Box>
          <Box sx={styles.purposeContainer}>
            <Text
              text={data.purpose}
              style={defaultValues.isResponsive && styles.responsiveDescription}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={styles.infoContainer}>
        <Box sx={styles.infoStyle}>
          <Text
            text="Fund raised"
            style={defaultValues.isResponsive ? styles.responsiveInfoTextSize : styles.infoTextSize}
          />{' '}
          <Stack sx={styles.amountContainer}>
            <Text
              text={
                data.fund_raised > 0 ? commaMaskedAmount(data.fund_raised) : commaMaskedAmount(0)
              }
              style={
                defaultValues.isResponsive ? styles.responsiveInfoTextSize : styles.infoTextSize
              }
            />
          </Stack>
        </Box>
        <Box sx={styles.infoStyle}>
          <Text
            text="Funds to be raised"
            style={defaultValues.isResponsive ? styles.responsiveInfoTextSize : styles.infoTextSize}
          />{' '}
          <Stack sx={styles.amountContainer}>
            <Text
              text={commaMaskedAmount(data.fund_to_be_raised)}
              style={
                defaultValues.isResponsive ? styles.responsiveInfoTextSize : styles.infoTextSize
              }
            />
          </Stack>
        </Box>
      </Box>
      <Box sx={styles.additionalInfoContainer}>
        <Box sx={styles.infoBox}>
          {data.additional_info && (
            <Box sx={styles.additionalInfo}>
              <Typography variant="body2" sx={styles.additionalInfo}>
              {`${data.additional_info.substring(0, 50)}`}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={styles.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            sx={data.status === 'active' ? styles.activeButton : styles.defaultButton}
            onClick={() => handleStatusChange('active')}>
            Active
          </Button>
          <Button
            variant="contained"
            color="error"
            sx={data.status === 'inactive' ? styles.inactiveButton : styles.defaultButton}
            onClick={() => handleStatusChange('inactive')}>
            Inactive
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

function truncateText(text, maxLength) {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  }
  return text;
}
