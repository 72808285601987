import { color } from '../../../theme/color';

export const styles = {
  container: (isResponsive) => ({
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    background: color.palette.white,
    height: isResponsive ? window.innerHeight * 0.4 : window.innerHeight * 0.5,
    width: isResponsive ? window.innerWidth * 0.7 : window.innerWidth * 0.3,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 1,
    left: isResponsive ? window.innerWidth * 0.15 : window.innerWidth * 0.35,
    right: isResponsive ? window.innerWidth * 0.15 : window.innerWidth * 0.3,
    top: isResponsive ? window.innerHeight * 0.3 : window.innerHeight * 0.25,
    bottom: isResponsive ? window.innerHeight * 0.3 : window.innerHeight * 0.25,
    borderRadius: 10
  }),
  imageStyle: (isResponsive) => ({
    width: isResponsive ? 40 : 75,
    height: isResponsive ? 40 : 75,
    marginBottom: isResponsive ? 10 : 20,
    marginTop: isResponsive ? 5 : 10
  }),
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 7,
    alignItems: 'center',
    justifyContent: 'space-around',
    width: '100%'
  },
  buttonSTyle: { flex: 0.3 },
  messageText: { marginRight: 10, marginLeft: 10, textAlign: 'center' }
};
