/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
  Box,
  Grid,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import style from "./style";
import CreateIcon from "@mui/icons-material/Create";
import { handleTransaction } from "../../utils/handleTransaction";
import { Loader } from "../common/loader/loader";
import { donorDetailsSchema } from "../../utils/schemas";
import { useFormik } from "formik";
import { TextInput } from "../ui-kit/text-field";
import { ConvertIndian } from "../../utils/currencyConvert";
import { commaMaskedAmount } from "../../utils/strings";
import { ImageSlider } from "../ui-kit/slider";
import { createCard, sendEmail, updateOrder } from "../../services/api";
import { Route_Paths, SUCCESS_KEY, getSuccessMessage } from "../../constants";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { getAllThankyouCards } from "../../services/api";

const DonationPopup = ({ open, handleClose, project }) => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showCard, setShowCard] = useState(false);
  const [amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [numberInWords, setNumberInWords] = useState("");
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const [thankyouCards, setThankYouCards] = useState([]);
  const [isCardPopupOpen, setIsCardPopupOpen] = useState(false);
  const [selectedThankYouCard, setSelectedThankYouCard] = useState(null);
  const [buttonText, setButtonText] = useState("Select Thank You Card");
  const [previewText, setPreviewText] = useState("");

  const handleAmount = (e) => {
    const value = e.target.value;
    const num = parseInt(value, 10);
    const totalAmount = num + project.fund_raised;

    if (!isNaN(num)) {
      setNumberInWords(ConvertIndian(num));
    } else {
      setNumberInWords("");
    }

    if (!isNaN(value) || value === "") {
      setAmount(value);
      setError("");
    } else {
      setError("Please enter a valid number");
    }

    if (totalAmount > project.fund_to_be_raised) {
      setError("You cannot donate more than the required amount.");
    }
  };

  const {
    values,
    handleChange,
    errors,
    touched,
    setFieldTouched,
    setFieldValue,
    isValid,
  } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email_id: "",
      pan_number: "",
      occasion: "",
      on_behalf: "",
      host_name: "",
      guest: "",
      wishesFrom: "",
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: donorDetailsSchema,
  });

  const handlePay = async () => {
    setIsLoading(true);
    try {
      await handleTransaction(
        null,
        amount,
        values,
        project,
        setIsLoading,
        setShowCard,
      );

      const emailData = {
        name: values.first_name + " " + values.last_name,
        email: values.email_id,
        donationDetails: [
          {
            amount: amount,
            project_name: project.project_name,
            purpose: project.purpose,
          },
        ],
        thankyouCardUrl: selectedThankYouCard,
      };

      const response = await sendEmail(emailData);

      if (response.data) {
        const uniqueId = uuidv4();
        const causesText = project.project_name;
        const stateData = {
          guest: "Guest",
          uuid: uniqueId,
          host_name: values.on_behalf,
          event_name: values.occasion,
          total: parseFloat(amount),
          guest_name: values.wishesFrom,
          causes: causesText,
          background_url: selectedThankYouCard,
          add_info: project.additional_info,
        };

        const cardResponse = await createCard(stateData);

        if (cardResponse) {
          navigate(`${Route_Paths.CARD}/${uniqueId}`);
        }

        addToast(getSuccessMessage(response.data.success_code), {
          appearance: SUCCESS_KEY,
          autoDismiss: true,
        });
      }
    } catch (error) {
      setIsLoading(false);
      handleClose();
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    getAllThankyouCards()
      .then((res) => {
        if (Array.isArray(res.data.data)) {
          setThankYouCards(res.data.data);
          if (res.data.data.length > 0) {
            setSelectedThankYouCard(res.data.data[0].cards_url);
            setButtonText("Background image");
          }
        } else {
          console.error("Unexpected response format:", res.data);
          setThankYouCards([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching thank you cards:", error);
        setThankYouCards([]);
      });
  }, []);

  const toggleDescription = () => {
    setExpanded(!expanded);
  };

  const openCardPopup = () => {
    setIsCardPopupOpen(true);
  };

  const closeCardPopup = () => {
    setIsCardPopupOpen(false);
  };

  const handleCardSelection = (cardUrl) => {
    setSelectedThankYouCard(cardUrl);
    setButtonText("Background image");
    closeCardPopup();
  };

  const handleEditableChange = (event, fieldName) => {
    setFieldValue(fieldName, event.target.innerText);
  };

  if (isLoading) {
    return <Loader isFullScreen={false} />;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
      <DialogTitle>Donation Form</DialogTitle>
      <DialogContent>
        <Box sx={style.flexContainer}>
          <Box sx={style.flexItem}>
            <Box sx={style.flexInput}>
              <TextInput
                margin="dense"
                id="first_name"
                label="First Name"
                fullWidth
                value={values.first_name}
                error={touched.first_name && errors.first_name}
                onChange={handleChange}
                onBlur={() => setFieldTouched("first_name")}
              />
              <TextInput
                margin="dense"
                id="last_name"
                label="Last Name"
                fullWidth
                error={touched.last_name && errors.last_name}
                value={values.last_name}
                onChange={handleChange}
                onBlur={() => setFieldTouched("last_name")}
              />
            </Box>
            <Box sx={style.flexInput}>
              <TextInput
                margin="dense"
                id="email_id"
                label="Email"
                fullWidth
                value={values.email_id}
                onChange={handleChange}
                onBlur={() => setFieldTouched("email_id")}
                error={touched.email_id && errors.email_id}
              />
              <TextInput
                margin="dense"
                id="phone_number"
                label="Phone"
                type="tel"
                fullWidth
                value={values.phone_number}
                onChange={handleChange}
                onBlur={() => setFieldTouched("phone_number")}
                error={touched.phone_number && errors.phone_number}
              />
            </Box>
            <Box sx={style.flexInput}>
              <TextInput
                margin="dense"
                id="pan_number"
                label="Pan Number"
                fullWidth
                value={values.pan_number}
                onBlur={() => setFieldTouched("pan_number")}
                onChange={handleChange}
                error={touched.pan_number && errors.pan_number}
              />
              <Box>
                <TextField
                  margin="dense"
                  id="amount"
                  label="Enter Donation Amount"
                  value={amount}
                  onChange={handleAmount}
                  error={!!error}
                  helperText={error}
                />
                {numberInWords !== "" && (
                  <Typography sx={style.formatAmount}>
                    {numberInWords}
                  </Typography>
                )}
              </Box>
            </Box>
            <Box sx={{ marginTop: 1 }}>
              <Typography variant="h6">Card Preview</Typography>
              <Box
                sx={{
                  position: "relative",
                  width: 380,
                  // height: 430,
                  backgroundImage: `url(${selectedThankYouCard})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  margin: "0",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "black",
                  padding: 1,
                  "@media (max-width: 600px)": {
                    width: "100%",
                    height: 450,
                    // overflow: "hidden",
                    fontSize: "15px",
                    marginRight: "0px",
                  },
                }}
              >
                <IconButton
                  onClick={openCardPopup}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    zIndex: 10,
                    "@media (max-width: 600px)": {
                      width: 8,
                      height: 8,
                    },
                  }}
                >
                  <CreateIcon />
                </IconButton>
                <Box
                  sx={{
                    position: "relative",
                    textAlign: "center",
                    display: "inline-block",
                  }}
                >
                  <img
                    src={selectedThankYouCard}
                    alt="Background"
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      zIndex: 0,
                      objectFit: "cover",
                    }}
                  />
                  <Box
                    style={{
                      position: "relative",
                      zIndex: 1,
                      padding: "20px",
                      backgroundColor: "rgba(255, 255, 255, 0.5)",
                    }}
                  >
                    <Typography>We are honoured to donate for</Typography>
                    <Typography variant="h6" sx={{ marginTop: 1 }}>
                      {project.project_name}
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: 1 }}>
                      {project.additional_info}
                    </Typography>
                    <Typography>On the occasion of </Typography>
                    <TextInput
                      margin="dense"
                      id="occasion"
                      label="Occasion"
                      fullWidth
                      value={values.occasion}
                      onBlur={() => setFieldTouched("occasion")}
                      onChange={handleChange}
                      error={touched.occasion && errors.occasion}
                    />
                    <Typography>of </Typography>
                    <TextInput
                      margin="dense"
                      id="on_behalf"
                      label="On Behalf"
                      fullWidth
                      value={values.on_behalf}
                      onBlur={() => setFieldTouched("on_behalf")}
                      onChange={handleChange}
                      error={touched.on_behalf && errors.on_behalf}
                    />
                    <Typography>With best wishes from</Typography>
                    <TextInput
                      margin="dense"
                      id="wishesFrom"
                      label="Wishes From"
                      fullWidth
                      value={values.wishesFrom}
                      onBlur={() => setFieldTouched("wishesFrom")}
                      onChange={handleChange}
                      error={touched.wishesFrom && errors.wishesFrom}
                      sx={{ marginTop: 1, textAlign: "center" }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={style.flexItem}>
            <DialogContentText>Project Details:</DialogContentText>
            <Box sx={style.projectDetails}>
              <div>
                <ImageSlider images={project.images} width={200} height={150} />
              </div>
              <Box sx={style.projectInfo}>
                <Typography sx={style.projectText}>
                  {project.project_name}
                </Typography>
                <Typography sx={style.projectText}>
                  Fund Raised: {commaMaskedAmount(project.fund_raised)}
                </Typography>
                <Typography sx={style.projectText}>
                  Fund to be Raised:{" "}
                  {commaMaskedAmount(project.fund_to_be_raised)}
                </Typography>
                {project.additional_info && (
                  <Typography
                    sx={style.purpose}
                  >{`${project.additional_info.substring(0, 40)}`}</Typography>
                )}
              </Box>
            </Box>
            <Typography sx={style.descriptionHeading}>Description</Typography>
            <Box sx={style.seeMoreContainer}>
              <Typography sx={expanded ? style.seeMore : style.description}>
                {project.purpose}
              </Typography>
              <Typography sx={style.seeMoreButton} onClick={toggleDescription}>
                {expanded ? "See Less" : "See More"}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handlePay}
          variant="contained"
          color="primary"
          disabled={!isValid || error !== ""}
        >
          Proceed to Pay
        </Button>
      </DialogActions>
      <Dialog
        open={isCardPopupOpen}
        onClose={closeCardPopup}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle variant="h6" component="h2" sx={{ padding: "20px" }}>
          Select a Thank You Card
        </DialogTitle>
        <DialogActions>
          <IconButton
            onClick={closeCardPopup}
            sx={{
              position: "absolute",
              top: 15,
              right: 10,
              "@media (max-width: 600px)": {
                right: 0,
              },
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
        {/* <Box sx={{ margin: 0, top: 0, right: 0 }}>
          <DialogTitle>Select a Thank You Card</DialogTitle>

          <Button onClick={closeCardPopup}>Close</Button>
        </Box> */}
        <DialogContent>
          <Grid container spacing={2}>
            {thankyouCards.map((card, index) => (
              <Grid item xs={4} key={index}>
                <img
                  src={card.cards_url}
                  alt={`Thank You Card ${index + 1}`}
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() => handleCardSelection(card.cards_url)}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
      </Dialog>
    </Dialog>
  );
};

export default DonationPopup;
