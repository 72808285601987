import React from 'react';
import { Text, UIButton } from '../../ui-kit';
import { useDispatch } from 'react-redux';
import { logoutRequest, setLogOutRequest } from '../../../redux/slices';
import { Route_Paths, defaultValues } from '../../../constants';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Styles } from './style';
import { color } from '../../../theme/color';

export const LogoutModal = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const handleLogout = () => {
    dispatch(logoutRequest());
    dispatch({ type: 'USER_LOGOUT' });
    sessionStorage.clear();
    navigation(Route_Paths.HOME);
  };

  const cancel = () => {
    dispatch(setLogOutRequest(false));
  };

  return (
    <div style={Styles.container}>
      <Box sx={Styles.subContainer(defaultValues.isResponsive)}>
        <Box sx={Styles.iconContainer} onClick={cancel}>
          X
        </Box>
        <Text
          text="Are you sure you want to logout ?"
          style={Styles.textStyle(defaultValues.isResponsive)}
        />
        <Box sx={Styles.buttonContainer}>
          <UIButton
            title="cancel"
            onClick={cancel}
            style={Styles.buttonStyle(color.primaryBackground)}
          />
          <UIButton title="yes" onClick={handleLogout} style={Styles.buttonStyle(color.primary)} />
        </Box>
      </Box>
    </div>
  );
};
