import React from "react";
import { TextField } from "@mui/material";
import { Text } from "../text";

export const TextInput = (props) => {
  const {
    id,
    ref,
    required = false,
    name,
    variant = "outlined",
    label,
    value,
    onChange,
    error,
    style,
    onBlur,
    ...rest
  } = props;

  const styles = { container: { marginBottom: "1px" } };
  return (
    <div style={styles.container}>
      <TextField
        id={id}
        ref={ref}
        required={required}
        name={name}
        variant={variant}
        label={label}
        value={value}
        error={error}
        onChange={onChange}
        sx={style}
        onBlur={onBlur}
        {...rest}
      />
      {error && <Text variant="fieldError" text={error} />}
    </div>
  );
};
