/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SSOForm, SSOImage } from '../../components';
import { OTPForm } from '../../components';
import {
  getAuthStatus,
  isAdminProfile,
  isEmailVerified,
  loginSuccessMsg,
  loginVerificationError
} from '../../redux/selectors';
import { styles } from './styles';
import { Route_Paths } from '../../navigation/routes';
import { defaultValues } from '../../constants/defaultValues';
import { Screen, Text } from '../../components/ui-kit';
import { useToasts } from 'react-toast-notifications';
import { ERROR_KEY, SUCCESS_KEY, getErrorMessage, getSuccessMessage } from '../../constants';

export const SignInPage = () => {
  const navigation = useNavigate();
  const { addToast } = useToasts();

  const emailVerified = useSelector(isEmailVerified);
  const isAuthenticated = useSelector(getAuthStatus);
  const isAdmin = useSelector(isAdminProfile);
  const loginSuccess = useSelector(loginSuccessMsg);
  const verificationErr = useSelector(loginVerificationError);

  useEffect(() => {
    if (isAuthenticated && isAdmin) {
      navigation(Route_Paths.MANAGE_PROJECT);
    } else if (isAuthenticated) {
      navigation(Route_Paths.EVENTS);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (loginSuccess) {
      addToast(getSuccessMessage(loginSuccess), {
        appearance: SUCCESS_KEY,
        autoDismiss: true
      });
    }
  }, [loginSuccess]);

  useEffect(() => {
    if (verificationErr) {
      addToast(getErrorMessage(verificationErr), {
        autoDismiss: true,
        appearance: ERROR_KEY
      });
    }
  }, [verificationErr]);

  return (
    <Screen showFooter={true} hideHeader={true}>
      <Box>
        <Grid container>
          <Grid item xs={12} md={4}>
            <SSOImage />
          </Grid>
          <Grid item xs={12} md={8} style={styles.formContainer}>
            {!defaultValues.isResponsive && (
              <Box sx={styles.textContainer}>
                <Text variant="header" text="Login to OTOO" />
                <Text variant="primarySubTitles" text="Give. Receive. Spread the love." />
              </Box>
            )}
            <Box sx={styles.componentContainer}>{emailVerified ? <OTPForm /> : <SSOForm />}</Box>
          </Grid>
        </Grid>
      </Box>
    </Screen>
  );
};
