import { color } from '../../../theme/color';

export const style = {
  container: (isResponsive) => ({
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    background: color.palette.white,
    height: isResponsive ? window.innerHeight * 0.4 : window.innerHeight * 0.5,
    width: isResponsive ? window.innerWidth * 0.7 : window.innerWidth * 0.3,
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    zIndex: 1,
    left: isResponsive ? window.innerWidth * 0.15 : window.innerWidth * 0.35,
    right: isResponsive ? window.innerWidth * 0.15 : window.innerWidth * 0.3,
    top: isResponsive ? window.innerHeight * 0.3 : window.innerHeight * 0.25,
    bottom: isResponsive ? window.innerHeight * 0.3 : window.innerHeight * 0.25,
    borderRadius: 10
  }),
  inputStyle: { m: 1, width: '100%' },

  inputField: { borderRadius: '30px' },
  button: { paddingLeft: '5%', paddingRight: '5%' }
};
