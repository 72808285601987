import React from 'react';
import Header from '../../components/common/header';

const RefundPolicy = () => {
  return (
    <div>
      <Header hideUserDetails={true} />
      <div style={{ padding: '16px' }}>
        <h1>Refund Policy</h1>
        <p>Thanks for supporting the social cause.</p>
        <p>
          We do not offer refunds for the contributions made but if you have made any contributions
          by mistake please write to{' '}
          <a href="mailto:secretary.rbitc@gmail.com">secretary.rbitc@gmail.com</a>.
        </p>
        <p>If anything is unclear or you have more questions feel free to contact us.</p>
      </div>
    </div>
  );
};

export default RefundPolicy;
