import { color } from "../../../theme/color";
import { palette } from "../../../theme/palette";

export const styles = {
  detailsContainer: {
    p: "2%",
    backgroundColor: color.palette.lightBlue,
    boxShadow: 1,
    borderRadius: 1,
    height: "19rem",
  },

  resContainer: {
    width: "100%",
    mt: 1,
    boxSizing: "border-box",
    p: "3%",
    backgroundColor: color.palette.lightBlue,
    boxShadow: 1,
    borderRadius: 1,
    height: "280px",
    "@media (max-width: 600px)": {
      height: "auto",
      maxHeight: "290px",
    },
  },

  imageContainer: {
    width: "160px",
    height: "128px",
    borderRadius: "5px",
    objectFit: "cover",
  },

  resImageContainer: {
    width: "74px",
    height: "59px",
    borderRadius: "2px",
    objectFit: "cover",
  },

  mainDetails: {
    width: "100%",
    display: "flex",
    overflow: "hidden",
    position: "relative",
  },
  titlesContainer: { width: "100%", ml: "2%" },
  primaryTitle: {
    display: "flex",
    justifyContent: "space-between",
  },

  heading: {
    wordWrap: "break-word",
    overflowWrap: "break-word",
    flexWrap: "wrap",
    width: "90%",
  },

  purposeContainer: {
    position: "relative",
    overflowY: "auto",
    overflowX: "hidden",
    flexGrow: 1,
    maxHeight: "80px",
    maxWidth: "100%",
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
  additionalInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "5px",
  },
  additionalInfo: {
    fontWeight: "bold",
  },
  infoBox: {
    marginTop: "10px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "10px 5px 5px 5px",
    gap: "8px",
  },
  activeButton: {
    backgroundColor: palette.green,
    color: palette.primaryBlack,
    padding: "2px 8px",
  },
  inactiveButton: {
    backgroundColor: palette.red,
    color: palette.primaryBlack,
    padding: "2px 8px",
  },
  defaultButton: {
    backgroundColor: palette.white,
    color: palette.primaryBlack,
    padding: "2px 8px",
  },
  textSize: { fontSize: 22 },
  responsiveText: { fontSize: 15, fontWeight: 400 },
  responsiveDescription: { fontSize: 10, fontWeight: 500 },
  infoContainer: { width: "100%", pt: "20px" },
  infoStyle: { display: "flex", justifyContent: "space-between" },
  infoTextSize: { fontSize: 17, fontWeight: 600 },
  responsiveInfoTextSize: { fontSize: 14, fontWeight: 500 },
  iconStyle: { cursor: "pointer" },
  amountContainer: { display: "flex", flexDirection: "row" },
};
