/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Screen, Text } from '../../components/ui-kit';
import { useLocation } from 'react-router-dom';
import { getErrorMessage } from '../../constants';
import { styles } from './styles';

export const FallbackPage = () => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState(getErrorMessage('E-10003'));

  useEffect(() => {
    if (location.state) {
      let { message } = location.state;
      setErrorMessage(message);
    }
  }, []);

  return (
    <Screen showFooter={true}>
      <div style={styles.container}>
        <Text variant="noData">{errorMessage}</Text>
      </div>
    </Screen>
  );
};
