import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projects: [],
  isLoading: false,
  projectSuccess: '',
  projectFetchError: null,
  projectCreateError: null
};

const projectsSlice = createSlice({
  initialState,
  name: 'projects',
  reducers: {
    projectsRequest(state) {
      state.projectFetchError = null;
      state.isLoading = true;
      state.projectSuccess = null;
    },
    allProjectsRequest(state) {
      state.projectFetchError = null;
      state.isLoading = true;
      state.projectSuccess = null;
    },
    projectsSuccess(state, action) {
      state.projects = action.payload.data;
      state.isLoading = false;
      state.projectSuccess = action.payload.success_code;
    },
    projectsFailure(state, action) {
      state.projectFetchError = action.payload;
      state.isLoading = false;
    },
    createProjectRequest(state) {
      state.isLoading = true;
      state.projectSuccess = null;
    },
    updateProjectRequest(state) {
      state.isLoading = true;
      state.projectSuccess = null;
    }
  }
});

export const {
  projectsFailure,
  projectsRequest,
  projectsSuccess,
  createProjectRequest,
  updateProjectRequest,
  allProjectsRequest
} = projectsSlice.actions;

export default projectsSlice.reducer;
