import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
import { fonts } from '../../theme/fonts';
export const footerStyles = {
  container: {
    flexGrow: 1,
    color: color.palette.white,
    width: '100%',
    backgroundColor: color.palette.primaryBlack,
    pb: 3
  },

  titleText: { color: color.secondaryText },
  subTitleText: { color: color.palette.gray, mt: !defaultValues.isResponsive && -3 },
  descText: { mt: 2, color: color.palette.gray },
  buttonStyle: {
    height: '100%',
    width: 'fit-content',
    ml: 1,
    paddingLeft: defaultValues.isResponsive ? '15%' : '5%',
    paddingRight: defaultValues.isResponsive ? '15%' : '5%'
  },
  inputFieldStyle: (isResponsive) => ({
    input: {
      background: 'white',
      borderRadius: 1,
      width: isResponsive ? '100%' : '365px'
    }
  }),
  linkTexts: {
    mt: 1,
    mb: 1,
    color: color.palette.gray,
    cursor: 'pointer'
  },
  contactTexts: {
    mb: 1,
    color: color.palette.gray,
    fontFamily: fonts.primary
  },

  subHeading: { color: color.tertiaryText, mb: 2 },
  icon: { cursor: 'pointer' },
  mainContainer: (isResponsive) => ({ width: isResponsive ? '100%' : '70%' }),
  subContainer: (isResponsive) => ({ display: isResponsive ? 'column' : 'flex', p: 3 }),
  titleContainer: (isResponsive) => ({ width: isResponsive ? '100%' : '30%' }),
  innerContainer: (isResponsive) => ({ width: isResponsive ? '50%' : '55%' }),
  rightInnerContainer: (isResponsive) => ({ width: isResponsive ? '50%' : '45%' }),
  desContainer: { width: '85%' },
  rightContainer: { display: 'flex', width: '100%', p: 1 },
  searchContainer: { p: 1, display: 'flex' },
  labelContainer: { display: 'flex', pl: 3, justifyContent: 'space-between' },
  laberLeft: (isResponsive) => ({ width: isResponsive ? '50%' : '50%', display: 'flex' }),
  laberRight: (isResponsive) => ({ width: isResponsive ? '50%' : '50%' }),
  labelText: (isResponsive) => ({
    fontSize: isResponsive ? 8 : 15,
    fontWeight: 400,
    margin: 1,
    cursor: 'pointer',
    color: color.palette.gray
  }),
  emailWraper: {
    width: '80%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    flexWrap: 'wrap'
  }
};
