import { Box } from '@mui/material';
import React from 'react';
import { Text } from '../ui-kit';
import { style } from './styles';
import { commaMaskedAmount } from '../../utils/strings';
import { defaultValues } from '../../constants';

export const DonationItem = (props) => {
  return (
    <Box sx={style.rightContainer}>
      <Text text="Donation Summary" style={style.titleText(defaultValues.isResponsive)} />
      <Box sx={style.infoContainer}>
        {props.data.map((item, index) => (
          <Box sx={style.detailsContainer} key={index.toString()}>
            <Text text={item.project_name} style={style.causeTxt(defaultValues.isResponsive)} />
            <Text
              text={`${commaMaskedAmount(item.amount)}`}
              style={style.amntStyle(defaultValues.isResponsive)}
            />
          </Box>
        ))}

        <Box sx={style.totalContainer}>
          <Text
            text={`Total: ${commaMaskedAmount(props.total)}`}
            style={style.totalTxt(defaultValues.isResponsive)}
          />
          <Text text="is being donated" style={style.descText(defaultValues.isResponsive)} />
        </Box>
      </Box>
    </Box>
  );
};
