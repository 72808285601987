export const faqs = {
    individualsNfamily: {
      title: 'For Individuals and Families',
      qNa: [
        {
          q: 'How do I create an event on OTOOGifts.org ?',
          a: 'Creating an event is simple. Visit our website, click on "Create Event," select the occasion, choose a cause from our curated list, and share the event details with your invitees.'
        },
        {
          q: 'Can I choose any cause I want to support ?',
          a: 'Yes, you can choose from a variety of causes that have been carefully vetted and approved by our partner, Rotary. We offer a diverse range of causes to match your interests and values.'
        },
        {
          q: 'What do I receive after my guests make a contribution ?',
          a: 'You will receive a beautiful digital certificate that highlights the impact of the contributions, such as the number of uniforms donated or meals provided. This certificate can be a cherished reminder of the difference made on your special occasion.'
        },
        {
          q: 'Are the contributions tax-deductible ?',
          a: 'Yes, all contributions made through OTOOGifts.org are eligible for an 80G tax exemption. Contributors will receive a certificate for tax purposes.'
        },
        {
          q: 'How do I know the donations are being used appropriately ?',
          a: 'We partner with Rotary, a globally respected organization, to ensure all projects and causes are legitimate and impactful. You can trust that your contributions are making a real difference.'
        }
      ]
    },
    for_Employers: {
      title: 'For Employers',
      qNa: [
        {
          q: 'How can I use OTOOGifts.org for corporate gifting ?',
          a: 'Employers can create events for various occasions like Diwali, Christmas, or employee milestones. Choose a cause and share the event details with your employees, encouraging them to contribute.'
        },
        {
          q: 'What are the benefits of using OTOOGifts for corporate events ?',
          a: `Using OTOOGifts for corporate events enhances your company's social responsibility profile, boosts employee morale, and provides a meaningful alternative to traditional corporate gifts.`
        },
        {
          q: 'Can employees receive tax benefits for their contributions ?',
          a: 'Yes, employees who contribute will receive an 80G tax exemption certificate for their donations.'
        },
        {
          q: 'How do I communicate this initiative to my employees ?',
          a: 'We provide customizable templates and communication materials to help you inform your employees about the initiative and how they can participate.'
        },
        {
          q: 'What kind of feedback will I receive about the contributions ?',
          a: 'As an employer, you will receive a summary report detailing the impact of the contributions, such as the total number of sweaters provided to school children or meals served, which you can share with your employees.'
        }
      ]
    },
    for_Ngo: {
      title: 'For NGOs and Partner Organizations',
      qNa: [
        {
          q: 'How can my organization become a partner with OTOOGifts.org ?',
          a: 'If your organization is interested in partnering with us, please contact us through our website. We have a vetting process in place to ensure all partners align with our values and standards.'
        },
        {
          q: 'What are the criteria for a cause to be featured on OTOOGifts.org ?',
          a: `Causes must be approved by Rotary and meet our standards for integrity, impact, and transparency. We focus on projects that make a tangible difference in communities.`
        },
        {
          q: 'How will my organization benefit from partnering with OTOOGifts ?',
          a: 'Partnering with OTOOGifts provides your organization with a platform to reach a wider audience, increase donations, and raise awareness for your cause.'
        },
        {
          q: 'How do we ensure the transparency of the contributions ?',
          a: 'We provide detailed reports to donors and event creators about how the funds are used. This transparency helps build trust and ensures continued support.'
        },
        {
          q: 'Can we propose new projects or causes for inclusion ?',
          a: 'Yes, partner organizations can propose new projects or causes. These proposals will be reviewed and, if they meet our criteria, added to our platform.'
        }
      ]
    },
    for_eventOrg: {
      title: 'For Event Organizers',
      qNa: [
        {
          q: 'How do I integrate OTOOGifts into my event planning services ?',
          a: 'You can offer OTOOGifts as an alternative gift option to your clients. We provide all the necessary tools and materials to seamlessly incorporate our service into your event planning process.'
        },
        {
          q: 'What support does OTOOGifts provide to event organizers ?',
          a: `We offer marketing materials, customizable templates, and dedicated support to help you explain and promote the concept to your clients. `
        },
        {
          q: 'Can I track the contributions made for an event I organize ?',
          a: 'Yes, as an event organizer, you will have access to a dashboard where you can track contributions and see the impact of the donations.'
        },
        {
          q: 'How does using OTOOGifts enhance the experience for event attendees ?',
          a: 'Attendees can feel good knowing their contributions are making a real difference. They receive tax benefits and a personalized thank you note, enhancing their overall experience.'
        },
        {
          q: 'What types of events are best suited for using OTOOGifts ?',
          a: 'OTOOGifts is perfect for all types of events, including birthdays, anniversaries, weddings, corporate events, and festivals. Any occasion where gifts are typically exchanged can be transformed into an opportunity to give back.'
        }
      ]
    },
    for_donors: {
      title: 'For Donors',
      qNa: [
        {
          q: 'How do I make a contribution to an event ?',
          a: 'When you receive an invitation with an OTOOGifts link, simply click on the link, choose the amount you wish to donate, and complete the payment process.'
        },
        {
          q: ' Will I receive a receipt for my donation ?',
          a: `Yes, you will receive an 80G tax exemption certificate and a personalized thank you note for your contribution.`
        },
        {
          q: 'Can I choose the specific cause I want to support within an event ?',
          a: 'The event organizer selects the cause, but you can always browse our website for other causes to support directly if you wish.'
        },
        {
          q: 'How can I be sure my donation is making a difference?',
          a: 'OTOOGifts partners with Rotary and other trusted organizations to ensure all donations are used effectively. You will receive detailed information about how your donation has been utilized.'
        },
        {
          q: 'Can I make anonymous donations?',
          a: ' Yes, you have the option to make your donation anonymously if you prefer.'
        }
      ]
    },
  };
  