import { palette } from '../../../theme/palette';

export const style = {
  mainContainer: { m: 2 },
  s4card: (isResponsive) => ({
    m: isResponsive && 1,
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '250px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0,0,0,0.2)'
    }
  }),
  imageGrid: {
    margin: '0px',
    padding: '0px'
  },
  s4image: {
    width: '300px',
    height: '250px',
    marginBottom: '10px',
    borderRadius: '15px'
  },
  projectName: {
    fontSize: '18px',
    fontWeight: '700',
    textAlign: 'center',
    margin: '10px 0px',
    height: '65px',
    overflow: 'hidden'
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  additionalInfoExpand: {
    cursor: 'pointer',
    color: palette.blue,
    fontSize: '14px'
  },
  purpose: {
    fontWeight: 'bold',
    fontSize: '12px'
  },
  s4detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '80px',
    margin: '20px 0px'
  },
  bodyText: {
    fontSize: '12px',
    fontWeight: 'bold'
  }
};
