import { color } from "../../theme/color";

const styles = {
  container: {
    width: '100%',
    padding: '40px 0px',
    background: color.cBackground,
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '40px',
    margin: '0px 10%',
    '@media (max-width: 768px)': {
      margin: '0px',
      flexDirection: 'column',
    },
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: '8px',
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '90%',
  },
  heading: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  form: {
    marginTop: '16px',
    borderRadius: '8px',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
    margin: '10px 0px',
  },
  input: {
    width: '100%',
  },
  label: {
    marginTop: '8px',
    marginBottom: '4px',
  },
  checkboxGroup: {
    marginBottom: '8px',
    flexDirection: 'column',
  },
  textarea: {
    marginBottom: '8px',
  },
  button: {
    marginTop: '8px',
    backgroundColor: color.primaryText,
    borderRadius: '10px',
    padding: '6px 10px',
    width: '100%',
  },
};

export default styles;
