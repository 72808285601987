import { color } from "../../../theme/color";
import { palette } from "../../../theme/palette";

import { defaultValues } from "../../../constants";

const isResponsive = defaultValues.isResponsive;

export const styles = {
  container: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "110vh",
  },
  cardContainer: (isResponsive) => {
    return {
      width: isResponsive ? "80%" : "30%",
      boxShadow: "0 4px 8px rgba(0,0,0,0.8)",
      borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      position: "relative",
      overflow: "hidden",
      padding: "20px",
      minHeight: "400px",
      height: "auto",
      maxHeight: "500px",
    };
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: "10px",
  },
  closeButton: {
    position: "absolute",
    top: "-5px",
    right: "10px",
    cursor: "pointer",
    zIndex: 10,
  },
  closeText: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    color: palette.white,
  },
  iconContainer: {
    position: "absolute",
    bottom: "10px",
    right: "10px",
    zIndex: 10,
    backgroundColor: palette.white,
    borderRadius: "50%",
    padding: "5px 8px 2px 5px",
  },
  shareImage: {
    width: "40px",
    height: "40px",
    backgroundColor: palette.white,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: 10,
  },
  iconStyle: {
    width: "30px",
    cursor: "pointer",
  },
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#ffffff",
    zIndex: 10,
    margin: "auto",
  },
  guestName: {
    width: "100%",
    marginTop: "40px",
    fontWeight: "bold",
    color: color.palette.primaryText,
    textAlign: "right",
  },
  titleText: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: color.palette.primaryText,
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
  },
  messageText: {
    fontSize: isResponsive ? "1rem" : "2rem",
    fontWeight: 500,
    marginTop: "10px",
    // backgroundColor: "red",
    color: color.palette.primaryText,
    textShadow: "1px 1px 2px rgba(0,0,0,0.8)",
  },
  guestText: {
    fontSize: "1.5rem",
    fontWeight: 500,
    marginTop: "10px",
    color: color.palette.primaryText,
    textShadow: "1px 1px 2px rgba(0,0,0,0.8)",
    textTransform: "capitalize",
    "@media (max-width: 600px)": {
      fontSize: "15px",
    },
  },
};
