import React from 'react';
import { Box, Modal } from '@mui/material';

import successIcon from '../../../assets/images/checkmark.png';
import errorIcon from '../../../assets/images/error.png';
import { Text, UIButton } from '../../ui-kit';
import { SUCCESS_KEY, defaultValues } from '../../../constants';
import { styles } from './styles';

export const CommonMOdal = ({
  type,
  open,
  handleClose,
  message,
  okButtonTitle = 'Ok',
  onOkPressed,
  cancelButtonTitle = 'Cancel'
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styles.container(defaultValues.isResponsive)}>
        <img
          src={type === SUCCESS_KEY ? successIcon : errorIcon}
          style={styles.imageStyle(defaultValues.isResponsive)}
        />
        <Text variant="success" style={styles.messageText}>
          {message}
        </Text>
        <Box sx={styles.buttonContainer}>
          <UIButton
            title={cancelButtonTitle}
            style={styles.buttonSTyle}
            onClick={handleClose}
            textVariant="buttonTitle"
          />
          <UIButton
            title={okButtonTitle}
            style={styles.buttonSTyle}
            onClick={onOkPressed}
            textVariant="buttonTitle"
          />
        </Box>
      </Box>
    </Modal>
  );
};
