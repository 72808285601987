/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef, useState } from 'react';
import { Screen, Text } from '../../components/ui-kit';
import { Box, Button, Grid, Typography } from '@mui/material';

import logo from '../../assets/images/Rectangle.png';
import Bg from '../../assets/images/otoo-bg.png';
import qouteRightIcon from '../../assets/images/quoteRight.png';
import qouteLeftIcon from '../../assets/images/quoteLeft.png';
import { style } from './style';
import { Route_Paths, defaultValues, getErrorMessage } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import img1 from '../../assets/images/hero-1.png';
import img2 from '../../assets/images/hero-2.jpg';
import img3 from '../../assets/images/hero-3.png';
import simg1 from '../../assets/images/step-1.jfif';
import simg2 from '../../assets/images/step-2.jfif';
import simg3 from '../../assets/images/step-3.png';
import aimg1 from '../../assets/images/about-1.jfif';
import aimg2 from '../../assets/images/about-2.jfif';
import aimg3 from '../../assets/images/about-3.png';
import dimg1 from '../../assets/images/donate-1.jfif';
import about from '../../assets/images/about.jfif';
import DonationPopup from '../../components/donation';
import { getActiveProjects } from '../../services/api';
import { useToasts } from 'react-toast-notifications';
import { ImageSlider } from '../../components/ui-kit/slider';
import { commaMaskedAmount } from '../../utils/strings';

export const HomePage = () => {
  const navigation = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const { addToast } = useToasts();
  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const curatedProjectsRef = useRef(null);
  const aboutRef = useRef(null);
  const location = useLocation();

  const [visibleProjects, setVisibleProjects] = useState(4);
  const [expanded, setExpanded] = useState({});

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index]
    }));
  };

  const handleOpenPopup = (project) => {
    setCurrentProject(project);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setCurrentProject(null);
  };
  const images = [aimg1, aimg2, aimg3];
  const steps_images = [simg1, simg2, simg3];

  useEffect(() => {
    const getProjectData = async () => {
      await getActiveProjects()
        .then((response) => {
          setProjects(response.data.data);
        })
        .catch((error) => {
          const errorCode = error.response.data.error_code;
          if (errorCode === 'E-10040' || errorCode === 'E-10043' || errorCode === 'E-10042') {
            let message = errorCode;
            navigation(Route_Paths.FALLBACK, { state: { message } });
          } else {
            addToast(getErrorMessage(error.response.data.error_code), {
              appearance: error,
              autoDismiss: true
            });
          }
        });
    };
    getProjectData();
  }, []);

  const handleScrollToProjects = () => {
    curatedProjectsRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const handleScrollToAbout = () => {
    aboutRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.hash === '#project' && curatedProjectsRef.current) {
      const offset = 150;
      const elementPosition =
        curatedProjectsRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
    if (location.hash === '#about' && aboutRef.current) {
      const offset = 200;
      const elementPosition = aboutRef.current.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition + offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  }, [location]);

  const handleLoadMore = () => {
    setVisibleProjects((prev) => prev + 4);
  };

  return (
    <Screen
      showColor={true}
      showFooter={true}
      scrollProject={handleScrollToProjects}
      scrollAbout={handleScrollToAbout}>
      <Box sx={style.sectionFirstContainer}>
        <Box sx={style.contentContainer(Bg)}>
          <Box sx={style.leftContainer}>
            <Box sx={style.landingPageData}>
              <Typography sx={style.landingPageHeading}>
                {defaultValues.landingPage.heading}
              </Typography>
              <Typography sx={style.landingPageCTitle}>
                {defaultValues.landingPage.changingTitle}
              </Typography>
            </Box>
            <Box sx={style.buttonContainer}>
              <Button
                variant="contained"
                onClick={handleScrollToProjects}
                sx={style.browseButtonStyle}>
                Browse Project
              </Button>
              <Button
                variant="contained"
                onClick={() => navigation(Route_Paths.LOGIN)}
                sx={style.createButtonStyle}>
                Create Event
              </Button>
            </Box>
          </Box>
          <Box sx={style.rightContainer}>
            <Box sx={style.imageContainer}>
              <img src={img3} alt="Image 1" style={style.imageStyle} />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '30px',
                  position: 'relative',
                  marginLeft: 'auto'
                }}>
                <img src={img2} alt="Image 2" style={style.imageStyleRight} />
                <img src={img1} alt="Image 3" style={style.imageStyleRight} />
              </div>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={style.sectionSecondContainer}>
        <Typography variant="h2" sx={style.q1heading}>
          {defaultValues.landingPage.q1}
        </Typography>
        <Typography variant="body1" sx={style.q1content}>
          {defaultValues.landingPage.q1Content}
        </Typography>
        <Typography variant="body1" sx={style.q1SubParagraph}>
          {defaultValues.landingPage.q1SubParagraph}
        </Typography>
        <Box sx={style.cardContainer(defaultValues.isResponsive)}>
          {images.map((item, index) => (
            <Box
              sx={
                index % 2 === 1
                  ? { ...style.cardStyle(defaultValues.isResponsive), ...style.q1BorderChange }
                  : style.cardStyle(defaultValues.isResponsive)
              }
              key={index}>
              <Typography
                style={{ fontSize: '18px', fontWeight: 'bold', textTransform: 'capitalize' }}>
                {defaultValues.landingPage.headingCardTitles[index]}
              </Typography>
              <img src={item} alt={`Card ${index + 1}`} style={style.q1imageStyle} />
              <Typography variant="body2">{defaultValues.landingPage.card1Content}</Typography>
            </Box>
          ))}
        </Box>
      </Box>

      <Box sx={style.sectionFirstContainer}>
        <Box sx={style.s3ContentContainer}>
          <Box sx={style.leftContainer}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              <h1>{defaultValues.landingPage.s3Heading}</h1>
              <p>{defaultValues.landingPage.s3Content}</p>
            </div>
            <Box sx={style.buttonContainer}>
              <Button
                variant="contained"
                onClick={handleScrollToProjects}
                sx={{ ...style.browseButtonStyle, ...style.browseButton }}>
                Browse Project
              </Button>
            </Box>
          </Box>
          <Box sx={style.s3RightContainer}>
            <Box sx={style.s3imageContainer}>
              <img src={dimg1} alt="Image 3" style={style.s3imageStyleRight} />
            </Box>
          </Box>
        </Box>
      </Box>

      <Box ref={curatedProjectsRef} sx={style.s4Container}>
        <Typography variant="h4" sx={style.curatedProject}>
          Curated Projects
        </Typography>
        <Box sx={style.s4cardContainer}>
          <Grid container sx={style.gridContainer}>
            {projects.slice(0, visibleProjects).map((project, index) => (
              <Box key={index} sx={style.imageGrid}>
                <Box sx={style.s4card(defaultValues.isResponsive)}>
                  <ImageSlider images={project.images} width={300} height={250} />
                  <Typography variant="subtitle1" sx={style.projectName}>
                    {project.project_name}
                  </Typography>
                  {project.purpose && (
                    <Box sx={style.additionalInfo}>
                      <Typography variant="body2" sx={style.additionalInfo}>
                        {expanded[index]
                          ? project.purpose
                          : `${project.purpose.substring(0, 35)}...`}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={style.additionalInfoExpand}
                        onClick={() => handleExpandClick(index)}>
                        {expanded[index] ? 'Show Less' : 'Show More'}
                      </Typography>
                    </Box>
                  )}
                  <Box sx={style.s4detailsContainer}>
                    <Box>
                      <Typography variant="body1" sx={style.bodyText}>
                        Fund Raised
                      </Typography>
                      <Typography variant="body1" sx={style.bodyText}>
                        Fund to be Raised
                      </Typography>
                    </Box>
                    <Box sx={style.rightDetails}>
                      <Typography variant="body1" sx={style.bodyText}>
                        {`${commaMaskedAmount(project.fund_raised)}`}
                      </Typography>
                      <Typography variant="body1" sx={style.bodyText}>
                        {`${commaMaskedAmount(project.fund_to_be_raised)}`}
                      </Typography>
                    </Box>
                  </Box>
                  {project.additional_info && (
                    <Typography
                      sx={
                        style.purpose
                      }>{`${project.additional_info.substring(0, 35)}`}</Typography>
                  )}
                  <Button
                    variant="contained"
                    sx={style.donateButton}
                    onClick={() => handleOpenPopup(project)}>
                    Donate Now
                  </Button>
                </Box>
              </Box>
            ))}
          </Grid>
          {visibleProjects < projects.length && (
            <Box sx={style.loadMoreButtonContainer}>
              <Button onClick={handleLoadMore} sx={style.loadMorebtn}>
                Load More
              </Button>
            </Box>
          )}
          {currentProject && (
            <DonationPopup
              open={openPopup}
              handleClose={handleClosePopup}
              project={currentProject}
            />
          )}
        </Box>
      </Box>

      <Box sx={style.sectionSecondContainer}>
        <Typography variant="h3" sx={style.q1heading}>
          OTOOgifts - as easy as 1-2-3
        </Typography>
        <Box sx={style.cardContainer(defaultValues.isResponsive)}>
          {steps_images.map((item, index) => (
            <Box
              sx={
                index % 2 === 1
                  ? { ...style.cardStyle(defaultValues.isResponsive), ...style.q1BorderChange }
                  : style.cardStyle(defaultValues.isResponsive)
              }
              key={index}>
              <img src={item} alt={`Card ${index + 1}`} style={style.q1imageStyle} />
              <Typography
                style={{ fontSize: '18px', fontWeight: 'bold', textTransform: 'capitalize' }}>
                {index === 0
                  ? 'step-1 Choose an occasion'
                  : index === 1
                    ? 'step-2 select cause'
                    : 'step-3 make donation'}
              </Typography>
              <Typography variant="body2">{defaultValues.landingPage.card1Content}</Typography>
            </Box>
          ))}
        </Box>
        <Box sx={style.buttonContainer} style={{ justifyContent: 'center', marginBottom: '20px' }}>
          <Button
            variant="contained"
            onClick={() => navigation(Route_Paths.LOGIN)}
            sx={style.createButtonStyle}>
            Create Event
          </Button>
        </Box>
      </Box>

      <Box ref={aboutRef} sx={style.s6Container}>
        <Box sx={style.s3ContentContainer} bgcolor="#E7F5FD">
          <Box sx={style.s3RightContainer}>
            <Box sx={style.s3imageContainer}>
              <img src={about} alt="Image 3" style={style.s3imageStyleRight} />
            </Box>
          </Box>
          <Box sx={style.leftContainer}>
            <div sx={style.s6DivContent}>
              <h1>{defaultValues.landingPage.s6Heading}</h1>
              <p>{defaultValues.landingPage.s6Content}</p>
              <p>{defaultValues.landingPage.s6SubContent}</p>
            </div>
          </Box>
        </Box>
        <Box sx={style.s6footer}>
          <Typography variant="h4" sx={style.s6FooterContent}>
            {defaultValues.landingPage.s6FooterHeading}
          </Typography>
          <Typography variant="body1" sx={style.s6FooterContent}>
            {defaultValues.landingPage.s6FooterContent}
          </Typography>
        </Box>
      </Box>

      <Box sx={style.outerTBox}>
        <Box sx={{ ...style.triangleBox, ...style.tringle }}>
          <Button sx={style.btnContactUs} onClick={() => navigation(Route_Paths.CONTACTUS)}>
            Contact Us
          </Button>
        </Box>
      </Box>

      <Box sx={{ ...style.sectionSecondContainer, ...style.tContainer }}>
        <Box sx={style.tSubContainer}>
          <Typography variant="h3" sx={style.tHeading}>
            Hear From Our Guests
          </Typography>
          <Typography variant="body1" sx={style.tSubheading}>
            Real Stories, Real Impact
          </Typography>
        </Box>
        <Box sx={style.testimonialCardContainer(defaultValues.isResponsive)}>
          <Box sx={style.testimonialCardStyle(defaultValues.isResponsive)}>
            <img src={qouteLeftIcon} style={style.leftQuoteIcon} />
            <Box sx={style.testimonialTextContainer}>
              <Text
                text={defaultValues.landingPage.testimonial1}
                sx={defaultValues.isResponsive ? style.resCommon : style.testimonialText}
              />
            </Box>

            <img src={qouteRightIcon} style={style.rightQuoteIcon} />
            <Box sx={style.profileIcon}>
              <img src={logo} />
            </Box>
          </Box>
          <Box sx={style.centerCard(defaultValues.isResponsive)}>
            <img src={qouteLeftIcon} style={style.leftQuoteIcon} />
            <Box sx={style.testimonialTextContainer}>
              <Text
                text={defaultValues.landingPage.testimonial2}
                sx={defaultValues.isResponsive ? style.resCommon : style.testimonialText}
              />
            </Box>

            <img src={qouteRightIcon} style={style.rightQuoteIcon} />
            <Box sx={style.profileIcon}>
              <img src={logo} />
            </Box>
          </Box>

          <Box sx={style.testimonialCardStyle(defaultValues.isResponsive)}>
            <img src={qouteLeftIcon} style={style.leftQuoteIcon} />
            <Box sx={style.testimonialTextContainer}>
              <Text
                text={defaultValues.landingPage.testimonial3}
                sx={defaultValues.isResponsive ? style.resCommon : style.testimonialText}
              />
            </Box>

            <img src={qouteRightIcon} style={style.rightQuoteIcon} />
            <Box sx={style.profileIcon}>
              <img src={logo} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Screen>
  );
};
