import { createSelector } from 'reselect';

export const allEvents = createSelector([(state) => state.events.events], (events) => events);

export const getDonations = createSelector(
  [(state) => state.events.donations],
  (donations) => donations
);

export const eventsSuccessMessage = createSelector(
  [(state) => state.events.eventSuccess],
  (eventsSuccessMessage) => eventsSuccessMessage
);

export const eventFailureMessage = createSelector(
  [(state) => state.events.eventFailure],
  (eventFailureMessage) => eventFailureMessage
);

export const isEventLoading = createSelector(
  [(state) => state.events.isLoading],
  (isLoading) => isLoading
);

export const currentEvent = createSelector(
  [(state) => state.events.currentEventData],
  (currentEventData) => currentEventData
);
