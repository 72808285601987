import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarIndex: 0,
  navbarHeading: 'Dashboard',
  projects: [],
  projectFetchError: null,
  projectCreateError: null
};

const adminSlice = createSlice({
  initialState,
  name: 'admin',
  reducers: {
    setSidebarIndex(state, action) {
      state.sidebarIndex = action.payload;
    },
    setNavbarHeading(state, action) {
      state.navbarHeading = action.payload;
    }
  }
});

export const { setSidebarIndex, setNavbarHeading } = adminSlice.actions;

export default adminSlice.reducer;
