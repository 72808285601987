import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { useFormik } from "formik";

import { CustomUrlInput, Text } from "../ui-kit";
import { createEventstyles } from "./createEventStyles";
import { eventSchema } from "../../utils/schemas";
import { TextInput } from "../ui-kit/text-field";
import { DatePicker } from "../ui-kit/datepicker";
import { getAllThankyouCards } from "../../services/api";

export const CreateEvent = ({ onDataChange, data }) => {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const urlParts = data ? data.custom_url.split("/") : "";
  const lastUrlPart = urlParts?.[urlParts.length - 1] || "";
  const [thankyouCards, setThankYouCards] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedCardUrl, setSelectedCardUrl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const { values, errors, touched, setFieldTouched, setFieldValue } = useFormik(
    {
      initialValues: {
        host: data ? data.host_name : "",
        event: data ? data.event_name : "",
        customUrl: data ? lastUrlPart : "",
        description: data ? data.event_description : "",
        startDate: data ? data.start_date_time : null,
        endDate: data ? data.end_date_time : null,
        thankYouCardUrl: data ? data.thank_you_card_url : null,
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: eventSchema,
    },
  );

  const handleDataChange = (field, val) => {
    setFieldValue(field, val);
    onDataChange({ ...values, [field]: val });
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const handleCardSelect = (url) => {
    setSelectedCardUrl(url);
    handleDataChange("thankYouCardUrl", url);
    handleCloseModal();
  };

  useEffect(() => {
    getAllThankyouCards()
      .then((res) => {
        if (Array.isArray(res.data.data)) {
          setThankYouCards(res.data.data);
        } else {
          console.error("Unexpected response format:", res.data);
          setThankYouCards([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching thank you cards:", error);
        setThankYouCards([]);
      });
  }, []);

  return (
    <Box sx={createEventstyles.innerContainer}>
      <Text variant="primarySubTitles" sx={createEventstyles.title}>
        Create your event
      </Text>

      <TextInput
        label="Enter host name"
        name="host"
        value={values.host}
        sx={createEventstyles.inputFieldStyle}
        onChange={(e) => handleDataChange("host", e.target.value)}
        onBlur={() => setFieldTouched("host")}
        error={touched.host && errors.host}
      />
      <TextInput
        label="Enter event name"
        name="event"
        sx={createEventstyles.inputFieldStyle}
        onChange={(e) => handleDataChange("event", e.target.value)}
        value={values.event}
        onBlur={() => setFieldTouched("event")}
        error={touched.event && errors.event}
      />
      <CustomUrlInput
        label="Enter custom URL name"
        name="customUrl"
        sx={createEventstyles.inputFieldStyle}
        onChange={(e) => handleDataChange("customUrl", e.target.value)}
        value={values.customUrl}
        onBlur={() => setFieldTouched("customUrl")}
        error={touched.customUrl && errors.customUrl}
      />
      <TextInput
        label="Event description"
        multiline
        rows={6}
        name="description"
        onChange={(e) => handleDataChange("description", e.target.value)}
        value={values.description}
        onBlur={() => setFieldTouched("description")}
        error={touched.description && errors.description}
        sx={createEventstyles.inputFieldStyle}
      />
      <Stack sx={createEventstyles.datePickerContainer}>
        <div style={createEventstyles.datePicker}>
          <DatePicker
            date={values.startDate}
            label="Select start date"
            name="startDate"
            onChangeDate={(newDate) => handleDataChange("startDate", newDate)}
            ref={startDateRef}
            onOpen={() => setFieldTouched("startDate")}
            errorMessage={touched.startDate && errors.startDate}
          />
        </div>
        <DatePicker
          date={values.endDate}
          label="Donation accepted till"
          name="enddate"
          onChangeDate={(newDate) => handleDataChange("endDate", newDate)}
          ref={endDateRef}
          onOpen={() => setFieldTouched("endDate")}
          errorMessage={touched.endDate && errors.endDate}
        />
      </Stack>
      <div style={createEventstyles.cardsButton}>
        <Button variant="contained" color="primary" onClick={handleOpenModal}>
          {selectedCardUrl
            ? "Thank You Selected"
            : "Please select a Thank You Card"}
        </Button>
        <Modal open={openModal} onClose={handleCloseModal}>
          <Box
            sx={{
              ...createEventstyles.modalStyle,
              width: isMobile ? "90%" : isTablet ? "75%" : 600, // Adjusting width for mobile
              maxHeight: isMobile ? "90%" : isTablet ? "80%" : "auto",
              // Allow vertical scrolling on mobile
              overflowY: isMobile ? "auto" : isTablet ? "auto" : "unset", // Enable scrolling only on mobile
            }}
          >
            <Typography variant="h6" component="h2">
              Thank You Cards
            </Typography>
            <IconButton
              onClick={handleCloseModal}
              sx={{ position: "absolute", top: 15, right: 10 }}
            >
              <CloseIcon />
            </IconButton>
            {thankyouCards.length > 0 ? (
              <Grid
                container
                spacing={2}
                direction={isMobile ? "column" : "row"} // Column on mobile, row on desktop
              >
                {thankyouCards.map((card, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Box
                      sx={{
                        margin: "10px 0",
                        border:
                          selectedCardUrl === card.cards_url
                            ? "2px solid blue"
                            : "none",
                        cursor: "pointer",
                        transition: "transform 0.2s, box-shadow 0.2s",
                        "&:hover": {
                          transform: "scale(1.05)",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                          border: "2px solid blue",
                        },
                      }}
                      onClick={() => handleCardSelect(card.cards_url)}
                    >
                      <img
                        src={card.cards_url}
                        alt={`Thank You Card ${index + 1}`}
                        width="100%"
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Typography variant="body1">
                No thank you cards available.
              </Typography>
            )}
          </Box>
        </Modal>
      </div>
    </Box>
  );
};
