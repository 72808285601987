import React from 'react';
import Header from '../../common/header';
import Footer from '../../common/footer';
import Sidebar from '../../admin/common/sidebar';
import { useSelector } from 'react-redux';
import { getAuthStatus, isAdminProfile, logOutStatus } from '../../../redux/selectors';
import { defaultValues } from '../../../constants';
import { Styles } from './style';
import { LogoutModal } from '../../common/logout';

function ScreenWithHeader(props) {
  const isLogOut = useSelector(logOutStatus);
  return (
    <div>
      <Header color={props.showColor} {...props} />
      {isLogOut ? <LogoutModal /> : props.children}
      {props.showFooter && <Footer {...props}/>}
    </div>
  );
}

function ScreenWithSideBar(props) {
  const isLogOut = useSelector(logOutStatus);

  return (
    <div style={defaultValues.isResponsive ? Styles.responsiveContainer : Styles.container}>
      <Sidebar />
      {isLogOut ? (
        <LogoutModal />
      ) : (
        <div style={Styles.subContainer}>
          {props.children}
          <div style={Styles.footerContainer}>{props.showFooter && <Footer />}</div>
        </div>
      )}
    </div>
  );
}

function ScreenWithoutHeader(props) {
  return (
    <div>
      {props.children}
      <Footer />
    </div>
  );
}

export const Screen = (props) => {
  const isAdmin = useSelector(isAdminProfile);
  const isAuthenticated = useSelector(getAuthStatus);
  if (isAuthenticated && !isAdmin) {
    return <ScreenWithHeader {...props} />;
  } else if (isAuthenticated && isAdmin) {
    return <ScreenWithSideBar {...props} />;
  } else if (props.hideHeader) {
    return <ScreenWithoutHeader {...props} />;
  } else {
    return <ScreenWithHeader {...props} />;
  }
};
