export const commaMaskedAmount = (amount) => {
  let rupee = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR'
  });
  let newAmt = rupee.format(amount);
  return newAmt;
};

export const unsecuredCopyToClipboard = (text) => {
  // Create a textarea element to hold the text
  const textArea = document.createElement('textarea');
  textArea.value = text;
  
  // Append the textarea to the document body
  document.body.appendChild(textArea);
  
  // Select the text in the textarea
  textArea.select();
  
  try {
    // Attempt to copy the text to the clipboard
    const successful = document.execCommand('copy');
    if (!successful) {
      throw new Error('Failed to copy text');
    }
  } catch (err) {
    // Handle errors
    console.error('Unable to copy to clipboard', err);
  } finally {
    // Clean up by removing the textarea
    document.body.removeChild(textArea);
  }
};
