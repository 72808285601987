import { put, takeLatest, all } from 'redux-saga/effects';
import {
  loginFailure,
  loginRequest,
  loginSuccess,
  verificationFailure,
  verificationRequest,
  verificationSuccess,
  otpResendRequest,
  otpResendSuccess,
  otpResendFailure,
  updateUserRequest,
  setAuthError,
  socialLoginrequest,
  updateUserSuccessMessage,
  updateUserFailureMessage
} from '../slices';
import { resendOtp, signIn, socialLogin, updateUserData, verifyUser } from '../../services/api';

function* login(action) {
  try {
    const loginResponse = yield signIn(action.payload);
    if (loginResponse.data) {
      yield put(loginSuccess(loginResponse.data));
    }
  } catch (error) {
    yield all([
      put(loginFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* socialAccountLogin(action) {
  try {
    const loginResponse = yield socialLogin(action.payload);
    if (loginResponse.data) {
      yield put(verificationSuccess(loginResponse.data));
    }
  } catch (error) {
    yield all([
      put(verificationFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* verifyLogin(action) {
  try {
    const verificationResponse = yield verifyUser(action.payload);
    if (verificationResponse.data) {
      yield put(verificationSuccess(verificationResponse.data));
    }
  } catch (error) {
    yield all([
      put(verificationFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* resendOTP(action) {
  try {
    const otpResendResponse = yield resendOtp(action.payload);
    if (otpResendResponse) {
      yield put(otpResendSuccess(otpResendResponse.data));
    }
  } catch (error) {
    yield all([
      put(otpResendFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* updateUser(action) {
  try {
    const response = yield updateUserData(action.payload);
    yield put(updateUserSuccessMessage(response.data));
  } catch (error) {
    yield all([
      put(updateUserFailureMessage(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

export function* loginSaga() {
  yield takeLatest(loginRequest.type, login);
  yield takeLatest(verificationRequest.type, verifyLogin);
  yield takeLatest(otpResendRequest.type, resendOTP);
  yield takeLatest(updateUserRequest.type, updateUser);
  yield takeLatest(socialLoginrequest.type, socialAccountLogin);
}
