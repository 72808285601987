import { color } from '../../../../theme/color';

export const eventPageStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },

  headerContainer: {
    position: 'sticky',
    top: 0,
    paddingTop: '1%',
    backgroundColor: color.primaryBackground,
    zIndex: 2
  },

  eventContainer: { pl: 3 },

  resContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    backgroundColor: color.primaryBackground
  },

  notificationStyle: {
    color: color.palette.red,
    marginLeft: '1%',
    marginTop: '2%'
  }
};
