export const donationStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    overflowY:'hidden',
    height: '100vh',
    padding: '16px',
  },
  filterSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '16px',
    '@media (max-width: 600px)': {
      flexDirection: 'column'
    }
  },
  filterInput: {
    flex: 0.5,
    marginRight: '16px',
    '@media (max-width: 600px)': {
      marginRight: '0',
      marginBottom: '16px',
      width: '100%'
    }
  },
 tableContainer: {
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto', 
  },
  table: {
    minWidth: '700px',
    '& .MuiTableCell-head': {
      backgroundColor: '#1e88e5',
      color: '#ffffff',
      fontWeight: 'bold'
    },
    '& .MuiTableCell-body': {
      fontSize: '14px',
      color: '#424242'
    }
  },
  exportButton: {
    marginLeft: 'auto',
    backgroundColor: '#1e88e5',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#1565c0'
    },
    '@media (max-width: 600px)': {
      marginLeft: '0',
      marginTop: '16px'
    }
  },
  projectsCell: {
    maxWidth: '350px',
    maxHeight: '22px',
    overflowY: 'auto',
    overflowX: 'hidden'
  }
};
