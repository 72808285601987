// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes spin {
  0%,
  15% {
    transform: rotateX(0deg);
  }
  16%,
  40% {
    transform: rotateX(90deg);
  }
  41%,
  65% {
    transform: rotateX(180deg);
  }
  66%,
  90% {
    transform: rotateX(270deg);
  }
  91%,
  100% {
    transform: rotateX(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/flipingText/style.css"],"names":[],"mappings":"AAAA;EACE;;IAEE,wBAAwB;EAC1B;EACA;;IAEE,yBAAyB;EAC3B;EACA;;IAEE,0BAA0B;EAC5B;EACA;;IAEE,0BAA0B;EAC5B;EACA;;IAEE,0BAA0B;EAC5B;AACF","sourcesContent":["@keyframes spin {\n  0%,\n  15% {\n    transform: rotateX(0deg);\n  }\n  16%,\n  40% {\n    transform: rotateX(90deg);\n  }\n  41%,\n  65% {\n    transform: rotateX(180deg);\n  }\n  66%,\n  90% {\n    transform: rotateX(270deg);\n  }\n  91%,\n  100% {\n    transform: rotateX(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
