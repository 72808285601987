import React, { useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
  AddProject,
  CheckoutPage,
  CreateEventPage,
  EventDetails,
  FallbackPage,
  GuestLanding,
  HomePage,
  HostEvents,
  ManageEvent,
  ManageProjects,
  ManageUsers,
  PrivacyPolicy,
  SignInPage
} from '../pages';
import { AdminEvents } from '../pages/events/admin/viewEvent';
import SettingsPage from '../pages/profile';
import { Route_Paths, getErrorMessage } from '../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthStatus, isAdminProfile, isAuthError } from '../redux/selectors';
import TermsAndConditions from '../pages/terms and condition';
import ShippingPolicy from '../pages/shipping policy';
import RefundPolicy from '../pages/refund policy';
import Faq from '../pages/faq';
import ContactUs from '../pages/contact-us';
import Card from '../components/common/card';
import { Donations } from '../pages/all-donations';

export const RootNavigation = () => {
  const navigation = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const isAuthenticated = useSelector(getAuthStatus);
  const isAdmin = useSelector(isAdminProfile);

  const authError = useSelector(isAuthError);

  useEffect(() => {
    if (authError) {
      addToast(getErrorMessage(authError), {
        appearance: 'error',
        autoDismiss: true
      });
      dispatch({ type: 'USER_LOGOUT' });
      sessionStorage.clear();
      navigation(Route_Paths.LOGIN);
    }
  }, [authError]);

  useEffect(() => {
    if (isAuthenticated) {
      if (isAdmin && location.pathname === Route_Paths.HOME) {
        navigation(Route_Paths.MANAGE_PROJECT);
      } else if (!isAdmin && location.pathname === Route_Paths.HOME) {
        navigation(Route_Paths.EVENTS);
      }
    }
  }, [isAuthenticated, isAdmin, location.pathname]);

  return (
    <Routes>
      <Route path={Route_Paths.LOGIN} element={<SignInPage />} />
      <Route path={Route_Paths.MANAGE_PROJECT} element={<ManageProjects />} />
      <Route path={Route_Paths.ADD_PROJECT} element={<AddProject />} />
      <Route path={Route_Paths.EDIT_PROJECT} element={<AddProject />} />
      <Route path={Route_Paths.MANAGE_EVENTS} element={<ManageEvent />} />
      <Route path={Route_Paths.MANAGE_USERS} element={<ManageUsers />} />
      <Route path={Route_Paths.DONATIONS} element={<Donations />} />
      <Route path={Route_Paths.EVENTS} element={<HostEvents />} />
      <Route path={Route_Paths.VIEW_DONATIONS} element={<HostEvents />} />
      <Route path={Route_Paths.VIEW_PROFILE} element={<SettingsPage />} />
      <Route path={Route_Paths.VIEW_PROFILE} element={<SettingsPage />} />
      <Route path={Route_Paths.ADMIN_VIEW_EVENTS} element={<AdminEvents />} />
      <Route path={Route_Paths.CREATE_EVENTS} element={<CreateEventPage />} />
      <Route path={Route_Paths.EDIT_EVENTS} element={<CreateEventPage />} />
      <Route path={Route_Paths.VIEW_EVENTS} element={<EventDetails />} />
      <Route path={'/:id'} element={<GuestLanding />} />
      <Route path={Route_Paths.CHECKOUT} element={<CheckoutPage />} />
      <Route path={Route_Paths.HOME} element={<HomePage />} />
      <Route path={Route_Paths.FALLBACK} element={<FallbackPage />} />
      <Route path={Route_Paths.TERMS} element={<TermsAndConditions />} />
      <Route path={Route_Paths.REFUND} element={<RefundPolicy />} />
      <Route path={Route_Paths.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={Route_Paths.SHIPPING} element={<ShippingPolicy />} />
      <Route path={Route_Paths.FAQ} element={<Faq />} />
      <Route path={Route_Paths.CONTACTUS} element={<ContactUs />} />
      <Route path={`${Route_Paths.CARD}/:id`} element={<Card />} />
    </Routes>
  );
};
