import { color } from '../../../theme/color';

export const sidebarStyle = {
  main_container: {
    width: 300,
    height: '100%',
    backgroundColor: color.primary,
    display: 'flex',
    flexDirection: 'column',
    borderTopRightRadius: '20px',
    // borderBottomRightRadius: "20px",
    zIndex: 1
  },
  logo_container: { width: '150px', height: '150px' },
  image_style: {
    width: '150px',
    height: '150px',
    objectFit: 'contain'
  },
  menu_style: {
    m: 3,
    display: 'flex',
    with: '100%',
    flexDirection: 'row',
    cursor: 'pointer'
  },
  icon_style: { mr: 3, ml: 3 },
  responsive_style: {
    display: { md: 'none', sx: 'flex', width: '100%' },
    flexDirection: 'row'
  },
  responsive_container: {
    backgroundColor: color.primary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%'
  },
  title_container: { p: 2 },
  title_color: { color: color.primaryText, fontSize: 16 },
  icon_container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon_color: { color: color.palette.primaryBlack },
  clicked: {
    width: '8px',
    height: '25px',
    ml: -3,
    backgroundColor: color.palette.primaryBlack
  },
  beforeClicked: {
    width: '8px',
    height: '25px',
    ml: -3,
    backgroundColor: color.primary
  }
};
