import { color } from '../../theme/color';

const styles = {
  container: {
    margin: '0 auto',
    maxWidth: '80%',
    padding: '20px 10%',
    background: color.secondary
  },
  heading: {
    textAlign: 'center',
    marginBottom: '16px',
    fontSize: '34px',
    fontWeight: 'bold'
  },
  accordion: {
    width: '100%',
    boxShadow: 'none',
    background: color.secondary,
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  faqList: {
    width: '100%'
  },
  summary: {
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px'
  },
  arrowIcon: {
    marginLeft: 'auto'
  },
  qna: {
    paddingLeft: '12px'
  },
  question: {
    display: 'flex',
    alignItems: 'center'
  }
};

export default styles;
