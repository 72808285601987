import { createSelector } from 'reselect';

export const allProjects = createSelector(
  [(state) => state.projects.projects],
  (projects) => projects
);

export const projectSuccessMessage = createSelector(
  [(state) => state.projects.projectSuccess],
  (projectSuccess) => projectSuccess
);

export const projectErrorMessage = createSelector(
  [(state) => state.projects.projectFetchError],
  (projectFetchError) => projectFetchError
);

export const loading = createSelector([(state) => state.projects.isLoading], (loading) => loading);
