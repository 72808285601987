/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState } from 'react';
import { Screen } from '../../components';
import {
  TextField,
  Button,
  Typography,
  Box,
  Grid,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Container,
  Alert,
  Snackbar,
  CircularProgress
} from '@mui/material';
import styles from './styles';
import contactImg from '../../assets/images/donate-1.jfif';
import { contactUsForm } from '../../services/api';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    need: [],
    message: ''
  });

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevData) => {
      const need = checked
        ? [...prevData.need, name]
        : prevData.need.filter((item) => item !== name);
      return { ...prevData, need };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); 
    try {
      await contactUsForm(formData);
      setMessage('Thanks for Contacting Us! We will get back to you ASAP!');
      setTimeout(() => {
        setMessage('');
        setFormData({
          name: '',
          email: '',
          phone: '',
          need: [],
          message: ''
        });
      }, 3000);
    } catch (error) {
      setMessage(error.response.data.error_code);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Screen showFooter={true}>
      <Container maxWidth={false} sx={styles.container}>
        {loading && (
          <Box
            sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: adds a transparent white background
              zIndex: 9999, // Ensures the loader is above other content
            }}
          >
            <CircularProgress />
          </Box>
        )}
        {message !== '' && (
          <Snackbar
            open={!!message}
            autoHideDuration={3000}
            onClose={() => setMessage('')}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
            <Alert
              onClose={() => setMessage('')}
              severity="success"
              sx={styles.successMessage}>
              {message}
            </Alert>
          </Snackbar>
        )}
        <Grid spacing={4} sx={styles.contentContainer}>
          <Grid item xs={12} md={6} sx={styles.imageContainer}>
            <img src={contactImg} alt="Contact" style={styles.image} />
          </Grid>
          <Grid item xs={12} md={6} sx={styles.formContainer}>
            <Typography variant="h4" gutterBottom sx={styles.heading}>
              Contact Our Team
            </Typography>
            <Typography variant="body1" gutterBottom>
              Reach out to us using the contact form. We are here to help and answer any questions
              you may have.
            </Typography>
            <form onSubmit={handleSubmit} sx={styles.form}>
              <Box sx={styles.inputGroup}>
                <TextField
                  name="name"
                  label="Name"
                  variant="outlined"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  sx={styles.input}
                />
                <TextField
                  name="email"
                  label="Email"
                  variant="outlined"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  size="small"
                  sx={styles.input}
                />
              </Box>
              <Box sx={styles.inputGroup}>
                <TextField
                  name="phone"
                  label="Phone"
                  variant="outlined"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                  size="small"
                  sx={styles.input}
                />
              </Box>
              <Typography variant="body1" gutterBottom sx={styles.label}>
                What best describes your need today?
              </Typography>
              <FormGroup sx={styles.checkboxGroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Organising an Occasion"
                      checked={(formData.need && formData.need.includes('Organising an Occasion')) || false}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Organising an Occasion"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Planning an Event"
                      checked={(formData.need && formData.need.includes('Planning an Event')) || false}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Planning an Event"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="Other"
                      checked={(formData.need && formData.need.includes('Other')) || false}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Other"
                />
              </FormGroup>
              <TextField
                name="message"
                label="Message"
                variant="outlined"
                multiline
                rows={4}
                value={formData.message}
                onChange={handleChange}
                fullWidth
                size="small"
                sx={styles.textarea}
              />
              <Button type="submit" variant="contained" color="primary" sx={styles.button}>
                Submit
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </Screen>
  );
};

export default ContactUs;
