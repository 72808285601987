export const ConvertIndian = (num) => {
    const indianNumbers = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine'];
    const indianTens = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
    const indianTeens = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'];
  
    if (num === 0) return 'Zero';
  
    const units = num % 10;
    const tens = Math.floor(num / 10) % 10;
    const hundreds = Math.floor(num / 100) % 10;
    const thousands = Math.floor(num / 1000) % 100;
    const lakhs = Math.floor(num / 100000) % 100;
    const crores = Math.floor(num / 10000000) % 100;
  
    let words = '';
  
    if (crores > 0) {
      words += indianNumbers[crores] + ' Crore ';
    }
  
    if (lakhs > 0) {
      words += ConvertIndian(lakhs) + ' Lakh ';
    }
  
    if (thousands > 0) {
      words += ConvertIndian(thousands) + ' Thousand ';
    }
  
    if (hundreds > 0) {
      words += indianNumbers[hundreds] + ' Hundred ';
    }
  
    if (tens > 1) {
      words += indianTens[tens] + ' ';
      if (units > 0) words += indianNumbers[units];
    } else if (tens === 1) {
      words += indianTeens[units];
    } else if (units > 0) {
      words += indianNumbers[units];
    }
  
    return words.trim();
  };
  