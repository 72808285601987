export const style = {
  container: (isResponsive) => ({
    display: 'flex',
    flexDirection: isResponsive ? 'column' : 'row'
    // width: '100%'
  }),
  leftContainer: (isResponsive) => ({
    width: isResponsive ? '100%' : '48%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // p: 2,
    my: 3
  }),
  horizonal: {
    display: { xs: 'none', md: 'flex' },
    width: '1px',
    height: '400px',
    boxShadow: 1,
    mt: 3
  },
  inputField: (isResponsive) => ({
    width: isResponsive ? '250px' : '538px',
    height: '70px',
    m: 1
  }),

  rightContainer: (isResponsive) => ({
    width: isResponsive ? '100%' : '48%'
  }),

  infoContainer: { width: '75%', mt: 2 },

  detailsContainer: { display: 'flex', justifyContent: 'space-between' },

  totalContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    mt: 2
  },

  buttonContainer: { display: 'flex', justifyContent: 'center', pb: 2 },
  buttonStyle: { paddingLeft: '5%', paddingRight: '5%' },

  titleText: { fontSize: 32, fontWeight: 600 },
  causeTxt: { fontSize: 24, fontWeight: 500 },
  amntStyle: { fontSize: 25, fontWeight: 600 },
  descText: { fontSize: 20, fontWeight: 400 },
  totalTxt: { fontSize: 32, fontWeight: 500 }
};
