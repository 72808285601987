import React from 'react';
import landingScreenImage from '../../assets/images/landing_screen_image.png';
import logo from '../../assets/images/logo.png';
import { Text } from '../ui-kit';
import { SSOImageStyle } from './ssoImageStyle';
import { defaultValues } from '../../constants/defaultValues';

export const SSOImage = () => {
  return (
    <div
      style={
        defaultValues.isResponsive ? SSOImageStyle.responsiveContainer : SSOImageStyle.container
      }>
      {defaultValues.isResponsive ? (
        <div style={SSOImageStyle.responsiveImageContainer}>
          <img src={logo} alt={'logo'} style={SSOImageStyle.responsiveImage} />
        </div>
      ) : (
        <>
          <img src={landingScreenImage} style={SSOImageStyle.imageStyles} alt={'logo'} />

          <Text variant="header" style={SSOImageStyle.textStyle} text="Welcome to OTOO!" />
          <Text
            style={SSOImageStyle.textStyle}
            variant="secondarySubTitle"
            text="On The Occasion Of"
          />
        </>
      )}
    </div>
  );
};
