import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Screen, Text } from '../../ui-kit';
import { styles } from './style';
import { commaMaskedAmount } from '../../../utils/strings';
import shareIcon from '../../../assets/images/Share.png';
import { API, Route_Paths, defaultValues } from '../../../constants';
import { RWebShare } from 'react-web-share';
import { useNavigate, useParams } from 'react-router-dom';
import Confetti from 'react-confetti';
import { getCard } from '../../../services/api';

const Card = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [encodedUrl, setEncodedUrl] = useState('');
  const { id } = useParams();
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const fetchCard = async () => {
      try {
        const response = await getCard({ uuid: id });
        const cardData = response.data.data;
        setData(cardData);

        // Encode URL after fetching the data
        const encoded = encodeURI(cardData.background_url);
        setEncodedUrl(encoded);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };
    fetchCard();
  }, [id]);

  useEffect(() => {
    const timer = setTimeout(() => setShowConfetti(false), 10000);
    return () => clearTimeout(timer);
  }, []);

  const { guest, host_name, event_name, total, guest_name, causes, add_info } = data;

  const renderCardText = () => {
    if (guest === 'Guest') {
      return (
        <>
        <Text text="We are honoured to donate for" style={styles.messageText} />
        <Text text={causes} style={styles.titleText} />
        <Text text={add_info} style={styles.titleText} />
        <Text text={`on the occasion of the ${event_name} of`} style={styles.messageText} />
        <Text text={host_name} style={styles.titleText} />
        <Text text="with best wishes." style={styles.messageText} />
        <Text text={guest_name} style={styles.titleText} />
      </>
      );
    } else {
      return (
        <>
          <Text text={`Hey, ${host_name}`} style={styles.titleText} />
          <Text
            text={`On the occasion of ${event_name}, I am delighted to contribute ${commaMaskedAmount(total)} on these projects ${causes}.`}
            style={styles.messageText}
          />
          <Box sx={styles.guestName}>
              <Text text={guest_name} style={styles.guestText} />
            </Box>
        </>
      );
    }
  };

  return (
    <Screen showFooter={true}>
      <Box sx={styles.container}>
        {showConfetti && <Confetti width={window.innerWidth} height={window.innerHeight} />}
        <Box sx={styles.cardContainer(defaultValues.isResponsive, encodedUrl)}>
          <img
            src={encodedUrl}
            alt="Background"
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 0,
              objectFit: 'cover',
            }}
          />
          <Box sx={styles.overlay}></Box>
          <Box sx={styles.closeButton} onClick={() => navigate(Route_Paths.HOME)}>
            <Text text="x" style={styles.closeText} />
          </Box>
          <Box sx={styles.iconContainer}>
            <RWebShare
              data={{
                text: defaultValues.donateSuccessMsg(host_name, event_name, total, guest_name),
                url: `${API.baseRoute[API.currentEnv]}${Route_Paths.CARD}/${data.uuid}`,
                title: 'OTOO'
              }}
            >
              <img src={shareIcon} style={styles.iconStyle} alt="Share" />
            </RWebShare>
          </Box>
          <Box sx={styles.detailsContainer}>
            {renderCardText()}
            
          </Box>
        </Box>
      </Box>
    </Screen>
  );
};

export default Card;
