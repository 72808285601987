import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  users: [],
  usersFetchSuccess: null,
  usersFetchFailiure: null,
  userUpdateSuccess: null,
  updateError: null
};

const userSlice = createSlice({
  initialState,
  name: 'users',
  reducers: {
    getAllUserRequest(state) {
      state.isLoading = true;
    },
    allUserRequestSuccess(state, action) {
      state.users = action.payload;
      state.isLoading = false;
      state.usersFetchSuccess = action.payload.success_code;
    },
    allUserRequetFailure(state, action) {
      state.usersFetchFailiure = action.payload.error_code;
      state.isLoading = false;
    },
    updateUserByAdminRequest(state) {
      state.isLoading = true;
      state.userUpdateSuccess = null;
      state.userUpdateFailure = null;
    },

    updateUserSuccess(state, action) {
      state.users = action.payload.data;
      state.isLoading = false;
      state.userUpdateSuccess = action.payload.success_code;
    },
    updateUserFailure(state, action) {
      state.isLoading = false;
      state.updateError = action.payload.error_code;
    },
    resetMessages(state) {
      state.userUpdateSuccess = null;
      state.userUpdateFailure = null;
    }
  }
});

export const {
  getAllUserRequest,
  allUserRequestSuccess,
  allUserRequetFailure,
  updateUserFailure,

  updateUserByAdminRequest,
  updateUserSuccess,
  resetMessages
} = userSlice.actions;

export default userSlice.reducer;
