import React, { useCallback, useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

import {
  ERROR_KEY,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage,
} from "../../../../constants";
import { eventPageStyles } from "./styles";
import {
  Loader,
  ManageEventCard,
  PageHeader,
  Screen,
  Text,
} from "../../../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  eventRequest,
  eventUpdateRequest,
} from "../../../../redux/slices/eventSlice";

import {
  allEvents,
  eventFailureMessage,
  eventsSuccessMessage,
  isEventLoading,
} from "../../../../redux/selectors/eventSelector";

import { useToasts } from "react-toast-notifications";

export const ManageEvent = () => {
  const dispatch = useDispatch();

  const events = useSelector(allEvents);
  const updateSuccess = useSelector(eventsSuccessMessage);
  const updateFailure = useSelector(eventFailureMessage);
  const eventLoading = useSelector(isEventLoading);

  const { addToast } = useToasts();

  const [searchedEvents, setSearchedEvents] = useState([]);

  useEffect(() => {
    dispatch(eventRequest());
  }, [dispatch]);

  useEffect(() => {
    if (events.length) {
      let temp = [...events];
      setSearchedEvents(temp);
    }
  }, [events]);

  useEffect(() => {
    if (updateSuccess) {
      addToast(getSuccessMessage(updateSuccess), {
        appearance: SUCCESS_KEY,
        autoDismiss: true,
      });
    }

    if (updateFailure) {
      addToast(getErrorMessage(updateFailure), {
        appearance: ERROR_KEY,
        autoDismiss: true,
      });
    }
  }, [updateFailure, updateSuccess]);

  const handleSearch = useCallback(
    (value) => {
      const temp = [...events];
      if (value.length > 1) {
        const filterdEvents = temp.filter(
          (item) =>
            (item.event_name &&
              item.event_name.toLowerCase().includes(value.toLowerCase())) ||
            (item.host_name &&
              item.host_name.toLowerCase().includes(value.toLowerCase())),
        );
        setSearchedEvents(filterdEvents);
      } else {
        setSearchedEvents(temp);
      }
    },
    [events],
  );

  const handleStatusChange = (id, type, custom_url) => {
    custom_url = custom_url.split("/").pop() || "";
    dispatch(eventUpdateRequest({ id, data: { is_active: type, custom_url } }));
  };

  return (
    <Screen showFooter={true}>
      <div
        style={
          defaultValues.isResponsive
            ? eventPageStyles.resContainer
            : eventPageStyles.container
        }
      >
        {eventLoading ? (
          <Loader />
        ) : (
          <>
            <Box sx={eventPageStyles.headerContainer}>
              {defaultValues.isResponsive ? (
                <PageHeader searchContent={"Search events"} />
              ) : (
                <PageHeader
                  title={"Manage Events"}
                  searchContent={"Search events"}
                  onSearch={handleSearch}
                />
              )}
            </Box>

            <Grid
              container
              sx={!defaultValues.isResponsive && eventPageStyles.eventContainer}
            >
              {searchedEvents.length > 0 ? (
                searchedEvents.map((item, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <ManageEventCard
                      data={item}
                      onStatusChange={handleStatusChange}
                    />
                  </Grid>
                ))
              ) : (
                <Text
                  text="No events availabe"
                  variant="secondarySubTitle"
                  style={eventPageStyles.notificationStyle}
                />
              )}
            </Grid>
          </>
        )}
      </div>
    </Screen>
  );
};
