import React from "react";
import { Box, TextField, Typography } from "@mui/material";
import { Text } from "../text";

const baseURL = "https://otoogifts.org/";

export const CustomUrlInput = (props) => {
  const {
    id,
    required = false,
    name,
    variant = "outlined",
    label,
    value,
    onChange,
    error,
    style,
    onBlur,
    ...rest
  } = props;

  const handleChange = (e) => {
    const customUrlPart = e.target.value.replace(baseURL, "");
    onChange({ target: { name, value: customUrlPart } });
  };

  return (
    <Box sx={{ marginBottom: "10px", display: "flex", alignItems: "center" }}>
      <Typography sx={{ marginRight: "8px" }}>{baseURL}</Typography>
      <div>
        <TextField
          id={id}
          required={required}
          name={name}
          variant={variant}
          label={label}
          value={value}
          error={error}
          onChange={handleChange}
          sx={style}
          onBlur={onBlur}
          {...rest}
        />
        {error && <Text variant="fieldError" text={error} />}
      </div>
    </Box>
  );
};
