/* eslint-disable no-unused-vars */
import { color } from '../../theme/color';
import { palette } from '../../theme/palette';

export const styles = {
  container: {
    display: 'flex',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    backgroundColor: color.primaryBackground,
    flexDirection:'column'
  },
  titleBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px 20px',
    marginLeft:'10px'
  },
  eventTitle: {
    fontSize: '2rem',
    fontWeight: '600',
  },
  detailsContainer: { pl: 4, width: '80%' },
  imageStyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    top: '30%',
    zIndex: 1
  },
  s4Container:{
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-start'
  },
  s4cardContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 5,
    mb: 5
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'start'
  },
  s4card: (isResponsive) => ({
    m: isResponsive && 1,
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0,0,0,0.2)'
    }
  }),
  imageGrid: {
    margin: '0px',
    padding: '0px'
  },
  s4image: {
    width: '300px',
    height: '250px',
    marginBottom: '10px',
    borderRadius: '15px'
  },
  projectName: {
    fontSize: '18px',
    fontWeight: '700',
    textAlign: 'center',
    margin: '10px 0px',
    height: '65px',
    overflow: 'hidden'
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  purpose:{
    fontWeight:'bold'
  },
  additionalInfoExpand: {
    cursor:'pointer',
    color:palette.blue,
    fontSize:'14px'
  },
  s4detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '80px',
    margin: '20px 0px'
  },
  rightDetails: {
    textAlign: 'right'
  },
  bodyText: {
    fontSize: '14px'
  },
  donateButton: {
    width: '150px',
    height: '54px',
    gap: '10px',
    marginTop: '10px',
    borderRadius: '12px',
    background: '#343434',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1a1a1a'
    }
  },
  inputField: (isResponsive) => ({
    width: '70%',
    height: '9%',
    marginTop: 2
  }),

  errorMsg:{
    color:color.errorText,
    fontSize:'0.8rem',
    marginTop:'25px',
  },
  sliderImg: {
    backgroundColor: palette.white,
    borderRadius: '10px !important',
    objectFit: 'contain'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    mt: '3%',
    alignItems: 'flex-end',
    pb: '5%'
  },
  buttonStyle: { paddingLeft: '5%', paddingRight: '5%' },
  cardContainer: { display: 'flex', m: 1,alignItems:'center',justifyContent:'center' },
};
