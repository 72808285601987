import { combineReducers } from 'redux';
import loginReducer from './slices/loginSlice';
import adminReducer from './slices/adminSlice';
import projectsReducer from './slices/projectsSlice';
import userReducer from './slices/userSlice';
import eventReducer from './slices/eventSlice';
import genericReducer from './slices/genericSlice';

const appReducer = combineReducers({
  login: loginReducer,
  admin: adminReducer,
  projects: projectsReducer,
  users: userReducer,
  events: eventReducer,
  generic: genericReducer
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
