import React, { useEffect, useState } from "react";
import {
  CauseCard,
  EventCard,
  Loader,
  PageHeader,
  Screen,
  Text,
} from "../../../../components";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { allProjects, currentEvent } from "../../../../redux/selectors";
import { styles } from "./style";
import { API, Route_Paths, defaultValues } from "../../../../constants";
import { getEventDetails } from "../../../../services/api";
import { commonStyles } from "../../../../theme/commonStyles";
import { setCurrentEventData } from "../../../../redux/slices";
import { commaMaskedAmount } from "../../../../utils/strings";
import LeftIcon from "../../../../assets/images/Left.png";
import shareIcon from "../../../../assets/images/Share.png";
import editIcon from "../../../../assets/images/Edit.png";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import { viewEventStyle } from "../../admin/viewEvent/style";

export const EventDetails = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const projects = useSelector(allProjects);
  const savedEventData = useSelector(currentEvent);

  const [eventData, setEventData] = useState(null);
  const [donors, setDonors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [causesInEvent, setCausesInEvent] = useState([]);

  const urlParts = savedEventData?.custom_url?.split("/");
  const lastUrlPart = urlParts?.[urlParts.length - 1] || "";

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await getEventDetails(lastUrlPart);
        setEventData(res.data.event);
        dispatch(setCurrentEventData(res.data.event));
        setDonors(res.data.donors);
        if (res.data.event.causes.length && projects) {
          let finalData = [];
          for (let i = 0; i < res.data.event.causes.length; i++) {
            for (let j = 0; j < projects.length; j++) {
              if (projects[j]._id === res.data.event.causes[i]) {
                finalData.push(projects[j]);
              }
            }
          }
          setCausesInEvent(finalData);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching event details:", error);
      }
    };

    getData();
  }, [lastUrlPart, projects, dispatch]);

  const handleEdit = () => {
    navigation(`${Route_Paths.EDIT_EVENTS}?${lastUrlPart}`);
  };

  if (isLoading) {
    return <Loader />;
  }

  const hasEnded =
    eventData?.end_date_time && new Date(eventData.end_date_time) < new Date();

  return (
    <Screen showFooter={true}>
      {!eventData.is_active ? (
        <Box sx={viewEventStyle.textError}>
          <Text text={defaultValues.noActiveText} variant="noData" />
        </Box>
      ) : hasEnded ? (
        <Box sx={viewEventStyle.textError}>
          <Text text={defaultValues.endDateText} variant="noData" />
        </Box>
      ) : causesInEvent.length === 0 ? (
        <Box sx={viewEventStyle.textError}>
          <Text text={defaultValues.noActiveCauseText} variant="noData" />
        </Box>
      ) : (
        <>
          <Box sx={styles.backAndHeaderContainer}>
            <Box sx={styles.headerContainer}>
              <img
                src={LeftIcon}
                style={styles.backIconStyle}
                onClick={() => navigation(-1)}
              />
              <Box sx={styles.titleContainer(defaultValues.isResponsive)}>
                <PageHeader title="Event details" />
              </Box>
              <Box sx={{ display: "flex" }}>
                <img
                  src={editIcon}
                  alt="editIcon"
                  style={styles.iconStyle}
                  onClick={handleEdit}
                />

                <RWebShare
                  data={{
                    text: defaultValues.inviteToDonateMsg(
                      savedEventData.event_name,
                    ),
                    url: `${API.baseRoute[API.currentEnv]}/${lastUrlPart}`,
                    title: "OTOO",
                    sites: ["WhatsApp"],
                  }}
                  onClick={() => console.log("shared successfully!")}
                >
                  <img
                    src={shareIcon}
                    alt="shareIcon"
                    style={styles.iconStyle}
                  />
                </RWebShare>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.cardContainer(defaultValues.isResponsive)}>
            <Box sx={styles.eventCardContainer}>
              <EventCard {...eventData} />
              {eventData?.qr_code_url && (
                <Box sx={styles.qrCodeSection}>
                  <Text
                    text="Event QR Code"
                    style={styles.text(defaultValues.isResponsive)}
                  />
                  <Box sx={styles.qrCodeContainer}>
                    <img
                      src={eventData.qr_code_url}
                      alt="Event QR Code"
                      style={styles.qrCodeImage}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Box sx={styles.causeCardContainer}>
              <Text
                text="Causes"
                style={styles.text(defaultValues.isResponsive)}
              />
              <Grid container sx={styles.container}>
                {causesInEvent &&
                  causesInEvent.map((item, index) => (
                    <Box key={index} sx={styles.item}>
                      <CauseCard type={true} data={item} />
                    </Box>
                  ))}
              </Grid>
            </Box>
          </Box>
          <Box sx={styles.donorSectionContainer(defaultValues.isResponsive)}>
            <Text style={styles.text(defaultValues.isResponsive)}>
              Event Donors
            </Text>
            <div style={commonStyles.divider} />
            {donors.length > 0 ? (
              <Box sx={styles.donorCardContainer(defaultValues.isResponsive)}>
                {donors.map((item, index) => (
                  <Box key={index} sx={styles.donnorDetailsContainer}>
                    <Text style={styles.donorName(defaultValues.isResponsive)}>
                      {item.first_name} {item.last_name}
                    </Text>
                    <Text style={styles.amountText(defaultValues.isResponsive)}>
                      {commaMaskedAmount(item.amount)}
                    </Text>
                  </Box>
                ))}
              </Box>
            ) : isLoading ? (
              <Loader />
            ) : (
              <Text>No donation received for this event!</Text>
            )}
          </Box>
        </>
      )}
    </Screen>
  );
};
