import * as yup from 'yup';
import { getErrorMessage } from '../constants/getMessages';
import { regExp } from '../constants';

export const loginSchema = yup.object({
  email: yup.string().required(getErrorMessage('E-10002')).email(getErrorMessage('E-10001'))
});

export const createEventSchema = yup.object({
  cause: yup.string().required(getErrorMessage('E-10016')),
  fundDetails: yup.string().required(getErrorMessage('E-10017')),
  purpose: yup.string().required(getErrorMessage('E-10018'))
});

export const userDetailsSchema = yup.object({
  firstName: yup.string().required(getErrorMessage('E-10025')),
  lastName: yup.string().required(getErrorMessage('E-10026')),
  phoneNo: yup
    .string()
    .required(getErrorMessage('E-10027'))
    .matches(regExp.phoneRegExp, getErrorMessage('E-10029')),

  city: yup.string().matches(regExp.alphabetRegExp, getErrorMessage('E-10044')),
  state: yup.string().matches(regExp.alphabetRegExp, getErrorMessage('E-10044')),
  pinCode: yup.string().matches(regExp.pinCode, getErrorMessage('E-10045'))
});

export const donorDetailsSchema = yup.object({
  first_name: yup.string().required(getErrorMessage('E-10025')),
  last_name: yup.string().required(getErrorMessage('E-10026')),
  email_id: yup
    .string()
    .required(getErrorMessage('E-10001'))
    .matches(regExp.email, getErrorMessage('E-10001')),
  phone_number: yup
    .string()
    .required(getErrorMessage('E-10027'))
    .matches(regExp.phoneRegExp, getErrorMessage('E-10029')),
  pan_number: yup
    .string()
    .required(getErrorMessage('E-10028'))
    .matches(regExp.panRegExp, getErrorMessage('E-10030'))
});

export const eventSchema = yup.object({
  host: yup.string().required(getErrorMessage('E-10031')),
  event: yup.string().required(getErrorMessage('E-10032')),
  customUrl: yup.string().required(getErrorMessage('E-10048')),
  description: yup.string().required(getErrorMessage('E-10033')),
  startDate: yup
    .date()
    .required(getErrorMessage('E-10034'))
    .max(yup.ref('endDate'), getErrorMessage('E-10035')),
  endDate: yup
    .date()
    .required(getErrorMessage('E-10036'))
    .min(yup.ref('startDate'), getErrorMessage('E-10037'))
});

export const queryDetailsSchema = yup.object({
  first_name: yup.string().required(getErrorMessage('E-10025')),
  last_name: yup.string().required(getErrorMessage('E-10026')),
  email_id: yup
    .string()
    .required(getErrorMessage('E-10001'))
    .matches(regExp.email, getErrorMessage('E-10001'))
});
