import { createSelector } from 'reselect';

export const allUsers = createSelector([(state) => state.users.users], (users) => users);

export const updateSuccessMessage = createSelector(
  [(state) => state.users.userUpdateSuccess],
  (updateSuccessMessage) => updateSuccessMessage
);

export const updateErrorMessage = createSelector(
  [(state) => state.users.updateError],
  (updateError) => updateError
);

export const isUserLoading = createSelector(
  [(state) => state.users.isLoading],
  (loading) => loading
);
