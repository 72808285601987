export const detailStyles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    m: 1
  },
  textFieldContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%'
  },
  textStyle: { m: 1 },
  borderStyle: { borderRadius: 40 },
  fieldSize: { width: '90%' },
  middleField: { display: 'flex', flexDirection: 'column', width: '100%' },
  middleFieldSize: { width: '95%' }
};
