import { Box, Typography } from "@mui/material";
import React, { useState } from "react";

import { defaultValues } from "../../../constants";
import { style } from "./causeCardStyle";
import { ImageSlider } from "../../ui-kit/slider";
import { commaMaskedAmount } from "../../../utils/strings";

export const CauseCard = ({ data }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={style.mainContainer}>
      <Box sx={style.imageGrid}>
        <Box sx={style.s4card(defaultValues.isResponsive)}>
          <ImageSlider images={data.images} width={250} height={250} />
          <Typography variant="subtitle1" sx={style.projectName}>
            {data.project_name}
          </Typography>
          {data.purpose && (
            <Box sx={style.additionalInfo}>
              <Typography variant="body2" sx={style.additionalInfo}>
                {expanded
                  ? data.purpose
                  : `${data.purpose.substring(0, 35)}...`}
              </Typography>
              <Typography
                variant="body2"
                sx={style.additionalInfoExpand}
                onClick={() => handleExpandClick()}
              >
                {expanded ? "Show Less" : "Show More"}
              </Typography>
            </Box>
          )}
          <Box sx={style.s4detailsContainer}>
            <Box>
              <Typography variant="body1" sx={style.bodyText}>
                Fund Raised
              </Typography>
              <Typography variant="body1" sx={style.bodyText}>
                Fund to be Raised
              </Typography>
            </Box>
            <Box sx={style.rightDetails}>
              <Typography variant="body1" sx={style.bodyText}>
                {`${commaMaskedAmount(data.fund_raised)}`}
              </Typography>
              <Typography variant="body1" sx={style.bodyText}>
                {`${commaMaskedAmount(data.fund_to_be_raised)}`}
              </Typography>
            </Box>
          </Box>
          {data.additional_info && (
            <>
              {data.fund_raised === data.fund_to_be_raised && (
                <Typography
                  sx={{ color: "green", fontWeight: "bold", fontSize: "12px" }}
                >
                  Target Fund Amount Reached.
                </Typography>
              )}

              <Typography
                sx={style.purpose}
              >{`${data.additional_info.substring(0, 40)}`}</Typography>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};
