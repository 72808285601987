import { color } from '../../theme/color';

export const ssoFormStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: '5%'
  },

  textfield_container: {
    width: '55%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },

  textfield_container_responsive: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    p: 2
  },

  button: { m: 2, pr: '50px', pl: '50px' },

  signInContainer: (isResponsive) => ({
    width: isResponsive ? '90%' : '55%',
    height: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    boxShadow: 1,
    border: 1,
    borderRadius: 1,
    borderColor: color.palette.primaryBlue,
    cursor: 'pointer',
    mt: 2
  }),
  iconStyle: (isResponsive) => ({
    width: isResponsive ? '27px' : '35px',
    height: isResponsive ? '27px' : '35px',
    marginRight: isResponsive ? 0 : '3%',
    marginTop: isResponsive ? '3%' : 0
  }),
  textStyle: (isResponsive) => ({ fontSize: isResponsive ? 11 : 22, fontWeight: 600 }),
  horzonalContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 2,
    width: '55%'
  },
  horizonalLine: { width: '45%', height: 0, border: 1 }
};
