import { palette } from '../../../theme/palette';

export const styles = {
  sliderImg: {
    backgroundColor: palette.white,
    borderRadius: '10px !important',
    objectFit: 'contain'
    // position: 'fixed'
  }
};
