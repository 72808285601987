import React, { useState } from 'react';
import { Box } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import Stack from '@mui/material/Stack';
import { defaultValues } from '../../constants/defaultValues';
import { footerStyles } from './footerStyle';
import { Text } from '../ui-kit';
import { SendQueryModal } from './send_query';
import { useToasts } from 'react-toast-notifications';
import { submitQuery } from '../../services/api';
import {
  ERROR_KEY,
  Route_Paths,
  SUCCESS_KEY,
  getErrorMessage,
  getSuccessMessage
} from '../../constants';
import ContactModal from '../contact-modal';
import { useNavigate } from 'react-router-dom';

const Footer = (props) => {
  const navigation = useNavigate();
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sendData] = useState('');

  const { addToast } = useToasts();

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const queryData = (data) => {
    const values = {
      first_name: data.first_name,
      last_name: data.last_name,
      email_id: data.email_id,
      query: sendData
    };
    submitQuery(values)
      .then((response) => {
        addToast(getSuccessMessage(response.data.success_code), {
          appearance: SUCCESS_KEY,
          autoDismiss: true
        });
      })
      // eslint-disable-next-line no-unused-vars
      .catch((_error) => {
        addToast(getErrorMessage('E-10003'), { appearance: ERROR_KEY, autoDismiss: true });
      });
  };

  const handleClick = (item) => {
    if (item === defaultValues.quickLinks.aboutUs) {
      if (window.location.pathname === '/') {
        props.scrollAbout();
      } else {
        navigation('/#about');
      }
    } else if (item === defaultValues.quickLinks.contactUs) {
      navigation(Route_Paths.CONTACTUS);
    } else if (item === defaultValues.quickLinks.faqs) {
      navigation(Route_Paths.FAQ);
    } else if (item === defaultValues.quickLinks.donation) {
      if (window.location.pathname === '/') {
        props.scrollProject();
      } else {
        navigation('/#project');
      }
    }
  };

  return (
    <Box sx={footerStyles.container}>
      <SendQueryModal open={showModal} handleClose={handleCloseModal} queryData={queryData} />
      <ContactModal open={open} handleClose={() => setOpen(false)} />
      <Box sx={footerStyles.subContainer(defaultValues.isResponsive)}>
        <Box sx={footerStyles.mainContainer}>
          <Text
            variant={defaultValues.isResponsive ? 'bold' : 'header'}
            style={footerStyles.titleText}>
            OTOO
          </Text>
          <Text
            variant={defaultValues.isResponsive ? 'primarySubTitles' : 'bold'}
            sx={footerStyles.subTitleText}>
            On the occasion of
          </Text>
        </Box>
        <Box sx={footerStyles.titleContainer}>
          <Box sx={{ display: 'flex', width: '100%', p: 1 }}>
            <Box sx={footerStyles.innerContainer(defaultValues.isResponsive)}>
              <Text variant="primarySubTitles" style={footerStyles.subHeading}>
                Quick links
              </Text>
              {Object.keys(defaultValues.quickLinks).map((key, index) => (
                <Text
                  key={index}
                  sx={footerStyles.linkTexts}
                  onClick={() => handleClick(defaultValues.quickLinks[key])}>
                  {defaultValues.quickLinks[key]}
                </Text>
              ))}
            </Box>

            <Box sx={footerStyles.rightInnerContainer(defaultValues.isResponsive)}>
              <Text variant="primarySubTitles" sx={footerStyles.subHeading}>
                Contact Us
              </Text>
              <Stack spacing={1} direction="row" sx={footerStyles.contactTexts}>
                <EmailIcon />
                <Box sx={footerStyles.emailWraper}>
                  <Text>{defaultValues.contact_email}</Text>
                </Box>{' '}
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box sx={footerStyles.labelContainer}>
        <Box sx={footerStyles.laberLeft(defaultValues.isResponsive)}>
          <Text
            text={`Terms and condition * `}
            onClick={() => window.open(Route_Paths.TERMS)}
            style={footerStyles.labelText(defaultValues.isResponsive)}
          />
          <Text
            text={`Privacy policy * `}
            onClick={() => window.open(Route_Paths.PRIVACY_POLICY)}
            style={footerStyles.labelText(defaultValues.isResponsive)}
          />
          <Text
            text={`Refund policy * `}
            onClick={() => window.open(Route_Paths.REFUND)}
            style={footerStyles.labelText(defaultValues.isResponsive)}
          />
          <Text
            text={`Shipping policy`}
            onClick={() => window.open(Route_Paths.SHIPPING)}
            style={footerStyles.labelText(defaultValues.isResponsive)}
          />
        </Box>
        <Box
          sx={footerStyles.laberRight(defaultValues.isResponsive)}
          style={{ textAlign: 'right', marginRight: '10px' }}>
          <Text
            text={`Copy right © 2024 otoogift, Inc. All rights reserved.`}
            style={footerStyles.labelText(defaultValues.isResponsive)}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
