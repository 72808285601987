export const style = {
  rightContainer: {
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    p: 1,
    mx: 2,
    my: 3
  },

  infoContainer: { width: '75%', mt: 2, p: 2 },

  detailsContainer: { display: 'flex', justifyContent: 'space-between' },

  totalContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    mt: 2
  },

  titleText: (isResponsive) => ({ fontSize: isResponsive ? 20 : 32, fontWeight: 600 }),
  causeTxt: (isResponsive) => ({ fontSize: isResponsive ? 15 : 24, fontWeight: 500 }),
  amntStyle: (isResponsive) => ({ fontSize: isResponsive ? 16 : 25, fontWeight: 600 }),
  descText: (isResponsive) => ({ fontSize: isResponsive ? 12 : 20, fontWeight: 400 }),
  totalTxt: (isResponsive) => ({ fontSize: isResponsive ? 15 : 32, fontWeight: 500 })
};
