import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { defaultValues } from '../../constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: defaultValues.isResponsive ? 'translate(-50%, -50%)' : 'translate(-40%, -50%)',
  width: defaultValues.isResponsive ? 'auto' : 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  borderRadius: 2
};

const ContactModal = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="contact-modal-title"
      aria-describedby="contact-modal-description">
      <Box sx={style}>
        <Typography id="contact-modal-title" variant="h6" component="h2">
          Contact Information
        </Typography>
        <Box display="flex" alignItems="center" mt={2}>
          <LocationOnIcon color="action" />
          <Box ml={1}>
            <Typography variant="subtitle1">Location:</Typography>
            <Typography variant="body2">
              Palm Meadows Club, Palm Meadows
              <br />
              Whitefield, Bangalore – 560 066
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mt={2}>
          <EmailIcon color="action" />
          <Box ml={1}>
            <Typography variant="subtitle1">Email:</Typography>
            <Typography variant="body2">secretary.rbitc@gmail.com</Typography>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ContactModal;
