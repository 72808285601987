import { ACCESS_KEY, API } from '../../constants';
import { createAxiosInstance } from './axiosConfig';

const defaultHeader = {
  'Content-type': 'application/json'
};

const multipartHeaders = {
  'Content-Type': 'multipart/form-data'
};

export const setToken = (val) => {
  sessionStorage.setItem(ACCESS_KEY, val);
};

export const getToken = () => sessionStorage.getItem(ACCESS_KEY);

const getAuthorizationHeader = () => `Bearer ${getToken()}`;

export function signIn(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.login,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function contactUsForm(formData) {
  return createAxiosInstance({
    url: API.noAuthUrls.contact_us,
    method: 'POST',
    headers: defaultHeader,
    data: formData
  }).then((response) => {
    return response;
  });
}

export function sendEmail(donerInfo) {
  return createAxiosInstance({
    url: API.noAuthUrls.donor_email,
    method: 'POST',
    headers: defaultHeader,
    data: donerInfo
  }).then((response) => {
    return response;
  });
}

export function socialLogin(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.social_login,
    method: 'POST',
    headers: defaultHeader,
    data: values
  }).then((response) => {
    setToken(response.data.token);
    return response;
  });
}

export function verifyUser(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.verify_login,
    method: 'POST',
    headers: defaultHeader,
    data: values
  }).then((response) => {
    setToken(response.data.token);
    return response;
  });
}

export function resendOtp(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.resend_login_otp,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

// export function userData(values) {
//   return createAxiosInstance({
//     url: "/users/user_details",
//     method: "GET",
//     headers: defaultHeader,
//     data: values,
//   });
// }

export function getProjects(values) {
  return createAxiosInstance({
    url: API.auth_urls.projects,
    method: 'GET',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function getActiveProjects(values) {
  return createAxiosInstance({
    url: API.auth_urls.active_projects,
    method: 'GET',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function deleteImage(values) {
  return createAxiosInstance({
    url: API.auth_urls.deleteProjectImage,
    method: 'DELETE',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function getProjectById(projectId) {
  return createAxiosInstance({
    url: `${API.auth_urls.projects}/${projectId}`,
    method: 'GET',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() }
  });
}

export function updateProject(values) {
  return createAxiosInstance({
    url: `${API.auth_urls.projects}/${values.id}`,
    method: 'PATCH',
    headers: { ...multipartHeaders, Authorization: getAuthorizationHeader() },
    data: values.formData
  });
}

export function createProject(values) {
  return createAxiosInstance({
    url: API.auth_urls.projects,
    method: 'POST',
    headers: { ...multipartHeaders, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function getAllUsers(values) {
  return createAxiosInstance({
    url: API.auth_urls.users,
    method: 'GET',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function getEvents(values) {
  return createAxiosInstance({
    url: API.auth_urls.events,
    method: 'GET',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function updateUserByAdmin(id, values) {
  return createAxiosInstance({
    url: `${API.auth_urls.update_user_by_admin}/${id}`,
    method: 'PUT',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function updateEvents(id, values) {
  return createAxiosInstance({
    url: `${API.auth_urls.events}/${id}`,
    method: 'PATCH',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function getEventsByUser() {
  return createAxiosInstance({
    url: API.auth_urls.user_events,
    method: 'GET',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() }
  });
}

export function createEvents(values) {
  return createAxiosInstance({
    url: API.auth_urls.create_events,
    method: 'POST',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function getThankYouImages(values) {
  return createAxiosInstance({
    url: API.auth_urls.create_events,
    method: 'POST',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function getEventsById(id) {
  return createAxiosInstance({
    url: `${API.auth_urls.events}/${id}`,
    method: 'GET',
    headers: defaultHeader
  });
}

export function createOrder(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.initiateTransaction,
    method: 'POST',
    headers: { ...defaultHeader },
    data: values
  });
}

export function updateOrder(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.updateTransaction,
    method: 'POST',
    headers: { ...defaultHeader },
    data: values
  });
}

export function updateUserData(values) {
  return createAxiosInstance({
    url: API.auth_urls.users,
    method: 'PUT',
    headers: { ...multipartHeaders, Authorization: getAuthorizationHeader() },
    data: values
  });
}

export function getDonors(id) {
  return createAxiosInstance({
    url: `${API.auth_urls.donors}/${id}`,
    method: 'GET',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() }
  });
}

export function getEventDetails(id) {
  return createAxiosInstance({
    url: `${API.auth_urls.eventDetails}/${id}`,
    method: 'GET',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() }
  });
}

export function submitQuery(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.submitQuery,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function createCard(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.create_card,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function getCard(values) {
  return createAxiosInstance({
    url: API.noAuthUrls.get_card,
    method: 'POST',
    headers: defaultHeader,
    data: values
  });
}

export function getAllDonation() {
  return createAxiosInstance({
    url: API.auth_urls.getAllDonation,
    method: 'GET',
    headers: { ...defaultHeader, Authorization: getAuthorizationHeader() }
  });
}

export function getAllThankyouCards() {
  return createAxiosInstance({
    url: API.auth_urls.getAllThankyouCards,
    method: 'GET',
    headers: defaultHeader
  });
}
