import { defaultValues } from "../../../constants";
import { color } from "../../../theme/color";

export const manageEventsStyle = {
  container: {
    margin: defaultValues.isResponsive ? "3%" : "2%",
    boxShadow: 1,
    borderRadius: 1,
    height: "230px",
    position: "relative",
  },
  firstInnerContainer: {
    display: "flex",
    backgroundColor: color.palette.lightBlue,
    padding: "10px",
    justifyContent: "space-between",
    // flexWrap: "wrap",
    height: "160px",
  },
  qrCodeContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "30%",
    // marginRight: "10rem",
    height: "100px",
    width: "100px",
    marginRight: "2rem",
    "@media (max-width: 600px)": {
      marginRight: 0, // Adjust for mobile screens
      width: "130%", // Smaller container for mobile

      // height: "70px",
      // marginBottom: 0,
    },
  },
  qrCodeImage: {
    width: "100px",
    height: "100px",
    paddingLeft: "10px",
    "@media (max-width: 600px)": {
      width: "60px", // Smaller QR code for mobile
      height: "60px",
    },
  },

  textContainer: {
    width: "70%",
    wordWrap: "break-word",
    overflowWrap: "break-word",
    flexWrap: "wrap",
    "@media (max-width: 600px)": {
      width: "100%", // Full width on mobile screens
      marginBottom: "10px", // Add space between text and QR code
    },
  },
  secondInnerContainer: {
    display: "flex",
    // position: "absolute",
    justifyContent: "space-between",
    padding: "2%",
    bottom: "10px",

    // flexWrap: "wrap",
  },
  leftFirstContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginRight: 4,
    paddingLeft: "30px",
  },
  arrowButton: {
    width: "40px",
    height: "40px",
    boxShadow: 1,
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  textName: { fontSize: 24, fontWeight: 600 },
  resTextName: { fontSize: 15, fontWeight: 600 },
  textHost: { fontSize: 16, fontWeight: 500, marginBottom: "5%" },
  resText: { fontSize: 12, paddingTop: 4, fontWeight: 500 },
  descText: { fontSize: 18, fontWeight: 500 },

  res1Text: {
    fontWeight: 500,
    paddingLeft: "25%",
    "@media (max-width: 768px)": {
      paddingLeft: "25%",
    },
  },
  viewText: { fontSize: 15, fontWeight: 500, paddingLeft: "25px" },
  resViewText: { fontSize: 9, fontWeight: 500 },
  amntText: { fontSize: 16, fontWeight: 600 },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "7px",
    // position: "absolute",
    // right: -190,
    // right: 0,
  },
  buttonActive: {
    width: "75px",
    height: "28px",
    backgroundColor: color.palette.green,
    fontSize: 12,
  },

  inactivebutton: {
    width: "75px",
    height: "28px",
    backgroundColor: color.palette.red,
    fontSize: 12,
  },
  button: {
    width: "75px",
    height: "28px",
    backgroundColor: color.palette.white,
    fontSize: 12,
  },

  resButton: {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: color.palette.green,
    },
  },

  donateButton: { paddingLeft: "5%", paddingRight: "5%" },
  resDonateButton: { paddingLeft: "5%", paddingRight: "5%", fontSize: 10 },
  iconContainer: {
    width: "30px",
    height: "25px",
    objectFit: "contain",
    margin: 1,
    cursor: "pointer",
  },
};
