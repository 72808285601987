import { color } from '../../../../theme/color';

export const styles = {
  container: {
    backgroundColor: color.primaryBackground,
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    width: '100%',
    pb: 2
  },
  buttonStyle: { paddingLeft: '4%', paddingRight: '4%' },
  buttonContainer: { display: 'flex', justifyContent: 'center' }
};
