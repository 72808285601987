/* eslint-disable react/prop-types */
import React from 'react';
import { Typography } from '@mui/material';
import { mergeAll, flatten } from 'ramda';
import { fonts } from '../../../theme/fonts';
import { color } from '../../../theme/color';
import { defaultValues } from '../../../constants';

const BASE = {
  fontFamily: fonts.secondary,
  fontSize: 14
};

const variants = {
  /**
   * The default text styles.
   */
  default: BASE,

  /**
   * A bold version of the default text.
   */
  bold: {
    ...BASE,
    fontFamily: fonts.secondary,
    fontSize: 28,
    fontWeight: '400'
  },

  /**
   * Large headers.
   */
  header: {
    ...BASE,
    fontFamily: fonts.header,
    fontSize: 56,
    fontWeight: '600'
  },

  /**
   * Field labels that appear on forms above the inputs.
   */
  fieldLabel: { ...BASE, fontSize: 16 },

  /**
   * Field error
   */
  fieldError: {
    ...BASE,
    fontSize: 14,
    fontFamily: fonts.secondary,
    color: color.errorText
  },

  success: {
    ...BASE,
    fontSize: 16,
    fontFamily: fonts.primary,
    fontWeight: '500'
  },
  /**
   * label related to info
   */
  infoLabel: {
    ...BASE
  },
  /**
   * it can be used to sub headings in the footer
   * sub headings like Contact us or Quick links
   */

  primarySubTitles: {
    ...BASE,
    fontFamily: fonts.header,
    fontSize: 20
  },

  /**
   * Login page or signup page subtitle
   */
  secondarySubTitle: {
    fontFamily: fonts.header,
    fontSize: 20
  },

  primaryMenuText: {
    fontFamily: fonts.secondary,
    fontSize: 20
  },
  //  text style for sub title or section texts
  sectionTitle: {
    fontFamily: fonts.header,
    fontWeight: 500,
    fontSize: 32
  },

  // responsive text style for sub title or section texts
  resSectionTitle: {
    fontFamily: fonts.secondary,
    fontWeight: 400,
    fontSize: 18
  },
  // it is used to show the message , if the data is not available
  noData: {
    ...BASE,
    fontSize: defaultValues.isResponsive ? 14 : 28,
    fontFamily: fonts.secondary,
    color: color.errorText
  },

  resNoData: {
    ...BASE,
    fontSize: 14,
    fontFamily: fonts.secondary,
    color: color.errorText
  },
  buttonTitle: {
    ...BASE,
    fontSize: 14,
    fontWeight: '600',
    fontFamily: fonts.secondary,
    color: color.errorText
  },

  resButtonTitle: {
    ...BASE,
    fontSize: 8,
    fontWeight: '500',
    fontFamily: fonts.secondary,
    color: color.errorText
  }
};

export const Text = (props) => {
  const { variant = 'default', text, children, style: styleOverride, ...rest } = props;

  const content = text || children;

  const style = mergeAll(flatten([variants[variant] || variants.default, styleOverride]));

  return (
    <Typography style={style} {...rest}>
      {content}
    </Typography>
  );
};
