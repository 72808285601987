import { put, takeLatest, all } from 'redux-saga/effects';
import { createEvents, getEvents, getEventsByUser, updateEvents } from '../../services/api';
import {
  createEventFailure,
  createEventRequest,
  createEventSuccess,
  eventRequest,
  eventRequestByUser,
  eventRequestByUserFailure,
  eventRequestByUserSuccess,
  eventRequestFailure,
  eventRequestSuccess,
  eventUpdateRequest,
  eventUpdateRequestFailure,
  eventUpdateRequestSuccess
} from '../slices/eventSlice';
import { setAuthError } from '../slices';

function* allEvents() {
  try {
    const response = yield getEvents();
    yield put(eventRequestSuccess(response.data));
  } catch (error) {
    yield all([
      put(eventRequestFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* createEvent(action) {
  try {
    const response = yield createEvents(action.payload);
    yield put(createEventSuccess(response.data));
  } catch (error) {
    yield all([
      put(createEventFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* eventsUpdate(action) {
  const { id, data } = action.payload;
  try {
    const response = yield updateEvents(id, data);
    yield put(eventUpdateRequestSuccess(response.data));
  } catch (error) {
    yield all([
      put(eventUpdateRequestFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

function* eventsByUser(action) {
  try {
    const response = yield getEventsByUser(action.payload);
    yield put(eventRequestByUserSuccess(response.data));
  } catch (error) {
    yield all([
      put(eventRequestByUserFailure(error.response.data)),
      put(setAuthError(error.response.data.error_code))
    ]);
  }
}

export function* eventSaga() {
  yield takeLatest(eventRequest.type, allEvents);
  yield takeLatest(eventUpdateRequest.type, eventsUpdate);
  yield takeLatest(eventRequestByUser.type, eventsByUser);
  yield takeLatest(createEventRequest.type, createEvent);
}
