import { defaultValues } from '../../../constants';
import { color } from '../../../theme/color';

export const manageEventsStyle = {
  container: {
    margin: defaultValues.isResponsive ? '3%' : '2%',
    boxShadow: 1,
    borderRadius: 1
  },
  firstInnerContainer: {
    display: 'flex',
    backgroundColor: color.palette.lightBlue,
    padding: '10px',
    justifyContent: 'space-between'
  },
  qrCodeContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '0px',
    marginRight: '10rem'
  },
  qrCodeImage: {
    width: '100px',
    height: '100px'
  },

  textContainer: {
    width: '70%',
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    flexWrap: 'wrap'
  },
  secondInnerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '2%'
  },
  leftFirstContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4
  },
  arrowButton: {
    width: '40px',
    height: '40px',
    boxShadow: 1,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
  },
  textName: { fontSize: 24, fontWeight: 600 },
  resTextName: { fontSize: 15, fontWeight: 600 },
  textHost: { fontSize: 16, fontWeight: 500, marginBottom: '5%' },
  resText: { fontSize: 12, fontWeight: 500 },
  descText: { fontSize: 18, fontWeight: 500 },

  viewText: { fontSize: 15, fontWeight: 500 },
  resViewText: { fontSize: 9, fontWeight: 500 },
  amntText: { fontSize: 16, fontWeight: 600 },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonActive: {
    width: '75px',
    height: '28px',
    backgroundColor: color.palette.green,
    fontSize: 12
  },

  inactivebutton: {
    width: '75px',
    height: '28px',
    backgroundColor: color.palette.red,
    fontSize: 12
  },
  button: {
    width: '75px',
    height: '28px',
    backgroundColor: color.palette.white,
    fontSize: 12
  },

  resButton: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: color.palette.green
    }
  },

  donateButton: { paddingLeft: '5%', paddingRight: '5%' },
  resDonateButton: { paddingLeft: '5%', paddingRight: '5%', fontSize: 10 },
  iconContainer: {
    width: '30px',
    height: '25px',
    objectFit: 'contain',
    margin: 1,
    cursor: 'pointer'
  }
};
