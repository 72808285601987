import { color } from '../../../../theme/color';

export const EventPageStyles = {
  container: {
    backgroundColor: color.primaryBackground,
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    px: '4%'
  },
  optionsContainer: { display: 'flex' },
  optionStyle: (index, selected) => ({
    margin: 1,
    borderBottom: 2,
    borderColor: index === selected ? color.primary : color.tertiaryText,
    cursor: 'pointer'
  }),
  textStyle: (index, selected) => ({
    color: index === selected ? color.primaryText : color.palette.gray
  }),
  cardContainer: (isDonation) => ({ minHeight: isDonation ? '70vh' : '60vh' }),
  buttonContainer: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'center',
    mb: 5
  },
  buttonStyle: { paddingLeft: '3%', paddingRight: '3%' }
};
