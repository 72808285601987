import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Text } from '../text';
import { defaultValues } from '../../../constants';

export const Accordian = ({ title, qna }) => {
  const style = {
    secondaryTextQ: { fontSize: 20, fontWeight: 600 },
    reSecondaryTextQ: { fontSize: 16, fontWeight: 600 },
    common: { fontSize: 16, fontWeight: 500, marginBottom: '12px' },
    resCommon: { fontSize: 14, fontWeight: 500, marginBottom: '12px' }
  };

  return (
    <Accordion sx={{ m: 1 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header">
        {title}
      </AccordionSummary>
      <AccordionDetails>
        {qna &&
          qna.map((q) => (
            <>
              <Text
                text={q.q}
                style={defaultValues.isResponsive ? style.reSecondaryTextQ : style.secondaryTextQ}
              />
              <Text
                text={q.a}
                style={defaultValues.isResponsive ? style.resCommon : style.common}
              />
            </>
          ))}
      </AccordionDetails>
    </Accordion>
  );
};
