import { createTheme } from '@mui/material/styles';
import '@fontsource/roboto';
import '@fontsource/quicksand';

let Theme = createTheme({
  // Theme customization goes here as usual, including tonalOffset and/or
  // contrastThreshold as the augmentColor() function relies on these
});

Theme = createTheme(Theme, {
  // Custom colors created with augmentColor go here
  palette: {
    yellow: Theme.palette.augmentColor({
      color: {
        main: '#FEE967'
      },
      name: 'yellow'
    }),
    blue: Theme.palette.augmentColor({
      color: {
        main: '#0067C8'
      },
      name: 'blue'
    }),
    black: Theme.palette.augmentColor({
      color: {
        main: '#343434'
      },
      name: 'black'
    }),
    white: Theme.palette.augmentColor({
      color: {
        main: '#F7FCFF'
      },
      name: 'white'
    }),
    red: Theme.palette.augmentColor({
      color: {
        main: '#FF9494'
      },
      name: 'red'
    })
  },
  typography: {
    h1: {
      fontFamily: 'Roboto',
      fontSize: 42,
      fontWeight: 600,
      letterSpacing: '2%'
    },
    h2: {
      fontFamily: 'Quicksand',
      fontSize: 20,
      fontWeight: 600,
      letterSpacing: '2%'
    },
    h3: {
      fontFamily: 'Quicksand',
      fontSize: 20,
      fontWeight: 600,
      letterSpacing: '2%'
    },
    error: {
      fontFamily: 'Quicksand',
      color: 'red',
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: '2%'
    },
    body1: {
      fontFamily: 'Quicksand',
      fontSize: 20,
      fontWeight: 400,
      letterSpacing: '2%'
    }
  }
});

export default Theme;
