import React, { useEffect, useState } from 'react';
import { Screen } from '../../components';
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  TextField,
  Typography
} from '@mui/material';
import { donationStyles } from './style';
import { getAllDonation } from '../../services/api';

export const Donations = () => {
  const [donations, setDonations] = useState([]);
  const [filteredDonations, setFilteredDonations] = useState([]);
  const [filters, setFilters] = useState({ donorNameFilter: '' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getAllDonation();
        setDonations(response.data.data);
      } catch (error) {
        setDonations([]);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = donations.filter((donation) =>
      donation.donorName.toLowerCase().includes(filters.donorNameFilter.toLowerCase())
    );
    setFilteredDonations(filtered);
  }, [filters.donorNameFilter, donations]);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', options);
  };

  const handleExport = () => {
    const dataToExport = filters.donorNameFilter ? filteredDonations : donations;
  
    const header = 'Donor Name,Mobile,PAN,Amount,Project,Date';
    const rows = dataToExport.map(donation => {
      return [
        donation.donorName,
        donation.mobile,
        donation.pan,
        donation.amount,
        donation.project,
        formatDate(donation.date)
      ].join(',');
    });
  
    const csvContent = 'data:text/csv;charset=utf-8,' + [header, ...rows].join('\n');
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'donations.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };  

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  return (
    <Screen showFooter={false}>
      <Box sx={donationStyles.container}>
        <Box sx={donationStyles.filterSection}>
          <TextField
            name="donorNameFilter"
            label="Enter name for search"
            variant="outlined"
            value={filters.donorNameFilter || ''}
            onChange={handleFilterChange}
            sx={donationStyles.filterInput}
          />
          <Button variant="contained" onClick={handleExport} sx={donationStyles.exportButton}>
            Export
          </Button>
        </Box>

        {filteredDonations.length > 0 ? (
          <TableContainer component={Paper} sx={donationStyles.tableContainer}>
            <Table sx={donationStyles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Donor Name</TableCell>
                  <TableCell>Mobile</TableCell>
                  <TableCell>PAN</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Projects</TableCell>
                  <TableCell>Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDonations.map((donation, index) => (
                  <TableRow key={index}>
                    <TableCell>{donation.donorName}</TableCell>
                    <TableCell>{donation.mobile}</TableCell>
                    <TableCell>{donation.pan}</TableCell>
                    <TableCell>{`Rs.${donation.amount} /-`}</TableCell>
                    <TableCell>{donation.project}</TableCell>
                    <TableCell>{formatDate(donation.date)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="h6" sx={donationStyles.noDonationsMessage}>
            No donations have been made yet.
          </Typography>
        )}
      </Box>
    </Screen>
  );
};
