export const palette = {
  primaryYellow: '#FEE967',
  secondaryYellow:'#FFFEEF',
  primaryBlue: '#0067C8',
  primaryBlack: '#343434',
  lightBlue: '#F7FCFF',
  white: '#FFFFFF',
  gray: '#808080',
  lightGray: '#D3D3D3',
  red: '#EA4335',
  green: '#A5DF44',
  skyBlue: '#80caed',
  offWhite: '#FEF1611A',
  blueShade: '#DEECF280',
  rezorpayTheme: '#686CFD',
  darkGray: '#E5E5E5',
  lightBlack: '#383631',
  hoverBlack:'#1a1a1a',
  contactBlue:'#E3FFFF',
  black:'#000000',
  blue:'#0000FF'
};
