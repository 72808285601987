import { defaultValues } from '../../../../constants';
import { color } from '../../../../theme/color';

export const styles = {
  cardContainer: (isResponsive) => ({
    px: '5%',
    py: '5%',
    display: 'flex',
    flexDirection: isResponsive ? 'column' : 'row'
  }),
  eventCardContainer: {
    width: '40%',
    m: 2,
    '@media (max-width: 600px)': {
      width: '90%'
    }
  },
  projectCards: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  qrCodeSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px'
  },
  qrCodeContainer: {
    marginTop: '10px'
  },
  qrCodeImage: {
    width: '200px',
    height: '200px'
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-end'
  },
  text: (isResponsive) => ({ fontSize: isResponsive ? 20 : 32, fontWeight: 600 }),
  title: { fontSize: 32, fontWeight: 600, marginTop: '5px' },
  donorSectionContainer: (isResponsive) => ({
    ml: isResponsive ? 5 : '10%',
    marginBottom: 10
  }),
  donorCardContainer: (isResponsive) => ({
    width: isResponsive ? '100%' : '50%',
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap'
  }),
  donnorDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '80%'
  },
  donorName: (isResponsive) => ({ fontSize: isResponsive ? 18 : 24, fontWeight: 500 }),
  amountText: (isResponsive) => ({ fontSize: isResponsive ? 18 : 25, fontWeight: 600 }),
  backAndHeaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    backgroundColor: color.primaryBackground,
    pt: 3
  },
  headerContainer: { mt: -2, width: '90%', display: 'flex', alignItems: 'center' },
  iconStyle: {
    width: '30px',
    height: '30px',
    objectFit: 'contain',
    margin: '10px',
    cursor: 'pointer'
  },
  backIconStyle: {
    cursor: 'pointer',
    objectFit: 'contain',
    width: defaultValues.isResponsive && '40px',
    height: defaultValues.isResponsive && '40px'
  },
  titleContainer: (isResponsive) => ({ width: '100%', mt: !isResponsive && '-2%' })
};
