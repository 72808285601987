/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getEventsById, getProjects } from "../../services/api";
import { EventCard, Loader, Screen, Text, UIButton } from "../../components";
import { Route_Paths, defaultValues, getErrorMessage } from "../../constants";
import { useToasts } from "react-toast-notifications";
import {
  Box,
  TextField,
  InputAdornment,
  Typography,
  Grid,
} from "@mui/material";
import logo from "../../assets/images/donate.png";
import { styles } from "./style";
import { commaMaskedAmount } from "../../utils/strings";
import { NumericFormat } from "react-number-format";
// import Ticker from 'react-ticker';
import { ImageSlider } from "../../components/ui-kit/slider";
import { viewEventStyle } from "../events/admin/viewEvent/style";

export const GuestLanding = () => {
  const navigation = useNavigate();
  const { addToast } = useToasts();
  const [projects, setProjects] = useState([]);
  const [eventData, setEventData] = useState(null);
  const [causeId, setCauseId] = useState([]);
  const [projectsData, setProjectsdata] = useState([]);
  const [amt, setAmt] = useState(0);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isNoData, setIsNoData] = useState(false);

  const url = window.location.href; // getting the url
  const urlParts = url.split("/");
  const lastUrlPart = urlParts?.[urlParts.length - 1] || "";

  const [expanded, setExpanded] = useState({});

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index],
    }));
  };

  useEffect(() => {
    const getProjectData = async () => {
      await getProjects()
        .then((response) => {
          setProjects(response.data.data);
        })
        .catch((error) => {
          const errorCode = error.response.data.error_code;
          if (
            errorCode === "E-10040" ||
            errorCode === "E-10043" ||
            errorCode === "E-10042"
          ) {
            let message = errorCode;
            navigation(Route_Paths.FALLBACK, { state: { message } });
          } else if (error.response?.status === 404) {
            setIsNoData(true);
            setIsLoading(false);
          } else {
            addToast(getErrorMessage(error.response.data.error_code), {
              appearance: error,
              autoDismiss: true,
            });
          }
        });
    };
    getProjectData();
  }, []);

  useEffect(() => {
    const getEventData = async () => {
      try {
        const response = await getEventsById(lastUrlPart);
        if (response) {
          setEventData(response.data.data);
          setCauseId(response.data.data.causes);
          setTimeout(() => {
            setIsLoading(false);
          }, 500);
        }
      } catch (error) {
        setIsLoading(false);
        const errorCode = error.response.data.error_code;
        if (error.response?.status === 404) {
          setIsNoData(true);
        } else if (
          errorCode === "E-10040" ||
          errorCode === "E-10043" ||
          errorCode === "E-10042"
        ) {
          let message = getErrorMessage(errorCode);
          navigation(Route_Paths.FALLBACK, { state: { message } });
        } else {
          addToast(getErrorMessage(error.response.data.error_code), {
            appearance: error,
            autoDismiss: true,
          });
        }
      }
    };

    getEventData();
  }, []);

  useEffect(() => {
    if (projects.length && causeId.length) {
      const causesInEvents = projects.filter((project) =>
        causeId.includes(project._id),
      );
      causesInEvents.map((item) => {
        item.amount = "";
      });
      setProjectsdata(causesInEvents);
    }
  }, [projects, causeId]);

  const handleAmount = (values, item) => {
    const { floatValue } = values;
    item.amount = floatValue;
    setAmt(floatValue);
    if (floatValue > item.fund_to_be_raised - item.fund_raised) {
      setError("Entered amount exceeds available fund.");
    } else {
      setError("");
    }
  };

  const hasEnded =
    eventData?.end_date_time && new Date(eventData.end_date_time) < new Date();

  return (
    <Screen showFooter={true}>
      {isLoading ? (
        <Loader />
      ) : isNoData ? ( // 404
        <Box sx={viewEventStyle.textError}>
          <Text text={defaultValues.customLinkChanged} variant="noData" />
        </Box>
      ) : eventData && !eventData.is_active ? (
        <Box sx={viewEventStyle.textError}>
          <Text text={defaultValues.noActiveText} variant="noData" />
        </Box>
      ) : hasEnded ? (
        <Box sx={viewEventStyle.textError}>
          <Text text={defaultValues.endDateText} variant="noData" />
        </Box>
      ) : projectsData.filter((cause) => cause.status === "active").length ===
        0 ? (
        <Box sx={viewEventStyle.textError}>
          <Text text={defaultValues.noActiveCauseText} variant="noData" />
        </Box>
      ) : (
        <>
          <Box sx={styles.container}>
            <Box sx={styles.titleBox}>
              <Text
                text="Event Details"
                variant="sectionTitle"
                style={styles.eventTitle}
              />
            </Box>
            <Box sx={styles.s4Container}>
              <Box sx={styles.detailsContainer}>
                <EventCard {...eventData} />
                <Text text="causes" variant="sectionTitle" />
                <Box sx={styles.s4cardContainer}>
                  <Grid container sx={styles.gridContainer}>
                    {projectsData
                      .filter((project) => project.status === "active")
                      .map((project, index) => (
                        <Box key={index} sx={styles.imageGrid}>
                          <Box sx={styles.s4card(defaultValues.isResponsive)}>
                            <ImageSlider
                              images={project.images}
                              width={300}
                              height={250}
                            />
                            <Typography
                              variant="subtitle1"
                              sx={styles.projectName}
                            >
                              {project.project_name}
                            </Typography>
                            {project.purpose && (
                              <Box sx={styles.additionalInfo}>
                                <Typography
                                  variant="body2"
                                  sx={styles.additionalInfo}
                                >
                                  {expanded[index]
                                    ? project.purpose
                                    : `${project.purpose.substring(0, 35)}...`}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  sx={styles.additionalInfoExpand}
                                  onClick={() => handleExpandClick(index)}
                                >
                                  {expanded[index] ? "Show Less" : "Show More"}
                                </Typography>
                              </Box>
                            )}
                            <Box sx={styles.s4detailsContainer}>
                              <Box>
                                <Typography
                                  variant="body1"
                                  sx={styles.bodyText}
                                >
                                  Fund Raised
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={styles.bodyText}
                                >
                                  Fund to be Raised
                                </Typography>
                              </Box>
                              <Box sx={styles.rightDetails}>
                                <Typography
                                  variant="body1"
                                  sx={styles.bodyText}
                                >
                                  {`${commaMaskedAmount(project.fund_raised)}`}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={styles.bodyText}
                                >
                                  {`${commaMaskedAmount(
                                    project.fund_to_be_raised,
                                  )}`}
                                </Typography>
                              </Box>
                            </Box>
                            {project.additional_info && (
                              <Typography sx={styles.purpose}>
                                {`${project.additional_info.substring(0, 35)}`}
                              </Typography>
                            )}
                            {eventData && eventData.is_active ? (
                              <>
                                <TextField
                                  label="Enter amount"
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  sx={styles.inputField(
                                    defaultValues.isResponsive,
                                  )}
                                  InputProps={{
                                    inputComponent: NumericFormatCustom,
                                    inputProps: {
                                      project,
                                      handleAmount,
                                    },
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        ₹
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <Typography sx={styles.errorMsg}>
                                  {error}
                                </Typography>
                              </>
                            ) : (
                              <Typography sx={styles.errorMsg}>
                                The event has been deactivated, so donations are
                                no longer possible.
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      ))}
                  </Grid>
                </Box>
              </Box>
              {!defaultValues.isResponsive && (
                <img src={logo} style={styles.imageStyle} />
              )}
            </Box>
          </Box>
          <Box sx={styles.buttonContainer}>
            <UIButton
              title="Donate"
              disabled={!amt || error !== ""}
              style={styles.buttonStyle}
              onClick={() =>
                navigation(Route_Paths.CHECKOUT, {
                  state: {
                    projectsData,
                    eventData,
                  },
                })
              }
            />
          </Box>
        </>
      )}
    </Screen>
  );
};

const NumericFormatCustom = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { inputRef, onChange, handleAmount, project, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => handleAmount(values, project)}
      thousandSeparator
      isNumericString
      prefix=""
    />
  );
};
