import { color } from "../../theme/color";
import { palette } from "../../theme/palette";

export const style = {
  innerContainer: {
    margin: "16px",
  },
  imageContainer: {
    display: "flex",
    flexWrap: "wrap",
    overflowY: "auto",
    maxHeight: "600px",
    margin: "10px 0px 0px 10px",
    gap: "10px",
    "@media (maxWidth: 600px)": {
      flexDirection: "column",
      overflowX: "hidden",
    },
    "@media (maxWidth: 330px)": {},
  },
  imgContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "0",
    padding: "1px",
    cursor: "pointer",
    "@media (maxWidth: 330px)": {},
  },
  selectedImageStyle: {
    border: `2px solid ${color.primaryText}`,
    borderRadius: "10px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
  },
  s4card: (isResponsive) => ({
    m: isResponsive && 1,
    p: 3,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "300px",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff",
  }),
  imageGrid: {
    margin: "0px",
    padding: "0px",
  },
  s4image: {
    width: "300px",
    height: "250px",
    marginBottom: "10px",
    borderRadius: "15px",
  },
  projectName: {
    fontSize: "18px",
    fontWeight: "700",
    textAlign: "center",
    margin: "10px 0px",
    height: "65px",
    overflow: "hidden",
  },
  additionalInfo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  additionalInfoExpand: {
    cursor: "pointer",
    color: palette.blue,
    fontSize: "14px",
  },
  purpose: {
    fontWeight: "bold",
  },
  s4detailsContainer: {
    display: "flex",
    justifyContent: "space-between",
    gap: "80px",
    margin: "20px 0px",
  },
  bodyText: {
    fontSize: "14px",
    fontWeight: "bold",
  },
};
