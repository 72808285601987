import axios from 'axios';
import { API } from '../../constants/api';

export const createAxiosInstance = (info) => {
  const { url, method, headers, data, params } = info;
  const axiosInstance = axios.create({
    baseURL: API.baseURL[API.currentEnv],
    headers
  });
  return axiosInstance({ url, method, headers, data, params });
};
