import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  // signingOtp: null,
  token: null,
  userInfo: null,
  user_email: null, //for unauthenticated users, clear when authenticated.
  isEmailVerified: false,
  loginSuccessMessage: null,
  errorLogin: null,
  is_admin: false,
  logout: false,
  updateSuccess: null,
  updateError: null
};

const loginSlice = createSlice({
  initialState,
  name: 'login',
  reducers: {
    loginRequest(state, action) {
      state.isLoading = true;
      state.errorLogin = null;
      state.user_email = action.payload.email_id;
    },
    loginSuccess(state, action) {
      state.isLoading = false;
      state.isEmailVerified = true;
      state.loginSuccessMessage = action.payload.success_code;
      // state.token = action.payload.accessToken;
    },
    loginFailure(state, action) {
      state.isLoading = false;
      state.errorLogin = action.payload.error_code;
    },
    verificationRequest(state) {
      state.isLoading = true;
      state.errorLogin = null;
      state.loginSuccessMessage = null;
    },
    verificationSuccess(state, action) {
      state.isAuthenticated = true;
      state.isLoading = false;
      state.userInfo = action.payload.user_data;
      state.token = action.payload.token;
      state.is_admin = action.payload.user_data.is_admin;
      state.isEmailVerified = false;
    },
    verificationFailure(state, action) {
      state.isLoading = false;
      state.errorLogin = action.payload.error_code;
    },
    logoutRequest(state) {
      state.isAuthenticated = false;
      state.is_admin = null;
      state.userInfo = null;
      state.errorLogin = null;
      state.loginSuccessMessage = null;
      localStorage.clear();
      sessionStorage.clear();
    },
    changeMailRequest(state) {
      state.isEmailVerified = false;
      state.errorLogin = null;
    },
    otpResendRequest(state) {
      state.isLoading = true;
      state.errorLogin = null;
    },
    otpResendSuccess(state, action) {
      state.isLoading = false;
      state.loginSuccessMessage = action.payload.success_code;
    },
    otpResendFailure(state, action) {
      state.isLoading = false;
      state.errorLogin = action.payload.error_code;
    },
    updateUserRequest(state) {
      state.isLoading = true;
      state.updateSuccess = null;
      state.updateError = null;
    },
    updateUserSuccessMessage(state, action) {
      state.userInfo = action.payload.data;
      state.updateSuccess = action.payload.success_code;
      state.isLoading = false;
    },
    updateUserFailureMessage(state, action) {
      state.isLoading = false;
      state.updateError = action.payload.error_code;
    },
    setLogOutRequest(state, action) {
      state.logout = action.payload;
    },
    socialLoginrequest(state) {
      state.isLoading = true;
    }
    // socialLoginSuccess(state, action) {
    //   state.
    // }
  }
});

export const {
  loginFailure,
  loginRequest,
  loginSuccess,
  socialLoginrequest,
  verificationFailure,
  verificationRequest,
  verificationSuccess,
  logoutRequest,
  changeMailRequest,
  otpResendRequest,
  otpResendSuccess,
  otpResendFailure,
  updateUserFailureMessage,
  updateUserRequest,
  updateUserSuccessMessage,
  setLogOutRequest
} = loginSlice.actions;

export default loginSlice.reducer;
