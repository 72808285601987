import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthError: null,
  invalidEventError: null
};

const genericSlice = createSlice({
  initialState,
  name: 'generic',
  reducers: {
    setAuthError(state, action) {
      if (action.payload === 'E-10019' || action.payload === 'E-10020') {
        state.isAuthError = action.payload;
      }
    },
    setEventError(state, action) {
      if (action.payload === 'E-10040') {
        state.invalidEventError = action.payload;
      }
    }
  }
});

export const { setAuthError, setEventError } = genericSlice.actions;

export default genericSlice.reducer;
