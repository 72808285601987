import { defaultValues } from "../constants";
import { createOrder, updateOrder } from "../services/api";

export const handleTransaction = async (
  eventData,
  total,
  values,
  projects,
  setIsLoading,
  setShowCard
) => {
  let order_id = null;
  let orderData = {
    event_id: eventData ? eventData._id : "",
    total_amount: parseFloat(total),
    user_details: {
      first_name: values.first_name,
      last_name: values.last_name,
      email_id: values.email_id,
      phone_number: values.phone_number,
      pan_number: values.pan_number.toUpperCase(),
    },
    donation_details: [projects],
  };

  try {
    const response = await createOrder(orderData);
    const { data } = response.data;
    order_id = data.order_id;

    return new Promise((resolve, reject) => {
      const options = {
        key: process.env.REACT_APP_RZ_KEY,
        name: defaultValues.paymentHeader,
        description: defaultValues.paymentDescription,
        order_id: order_id,
        handler: async (response) => {
          setIsLoading(true);
          try {
            const paymentId = response.razorpay_payment_id;
            let updateValues = {
              order_id: order_id,
              event_id: eventData ? eventData._id : "",
              payment_id: paymentId,
              amount: parseFloat(total),
              orderData,
            };

            const updateResponse = await updateOrder(updateValues);
            setIsLoading(false);
            setShowCard(true);
            resolve(updateResponse); // Resolve with updateOrder response
          } catch (err) {
            setIsLoading(false);
            reject(err);
          }
        },
        theme: {
          color: '#3399cc',
        },
      };

      const rzp1 = new window.Razorpay(options);
      rzp1.open();

      rzp1.on('payment.failed', (response) => {
        setIsLoading(false);
        reject(response.error.code);
      });
    });
  } catch (error) {
    setIsLoading(false);
    throw error;
  }
};
