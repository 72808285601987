import React, { useCallback, useEffect, useState } from "react";
import { Screen, UIButton } from "../../../../components";
import { styles } from "./style";
import { Box, Grid } from "@mui/material";
import { EventImages } from "../../../../components/createEvent/eventImages";
import { CreateEvent } from "../../../../components/createEvent/createEvent";
import { useDispatch, useSelector } from "react-redux";
import {
  createEventRequest,
  eventUpdateRequest,
  projectsRequest,
} from "../../../../redux/slices";
import {
  allProjects,
  currentEvent,
  eventsSuccessMessage,
} from "../../../../redux/selectors";
import { useNavigate } from "react-router-dom";
import { Route_Paths } from "../../../../constants";

export const CreateEventPage = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const projects = useSelector(allProjects);
  const currentEventData = useSelector(currentEvent);
  const success = useSelector(eventsSuccessMessage);

  const [eventCauses, setEventCauses] = useState([]);
  const [hostName, setHostName] = useState("");
  const [eventName, setEventName] = useState("");
  const [customUrl, setCustomUrl] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [causes, setCauses] = useState([]);
  const [thankYouCardUrl, setThankYouCard] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);

  useEffect(() => {
    dispatch(projectsRequest());
  }, [dispatch]);

  useEffect(() => {
    if (currentEventData) {
      const filtered = projects.filter((item) =>
        currentEventData.causes.includes(item._id),
      );
      setEventCauses(filtered);
    }
  }, []);

  useEffect(() => {
    // Filter projects to exclude those where fund_raised equals fund_to_be_raised
    const updatedProjects = projects.filter(
      (project) => project.fund_raised !== project.fund_to_be_raised,
    );
    setFilteredProjects(updatedProjects);
  }, [projects]);

  useEffect(() => {
    if (success === "S-10006" || success === "S-10005") {
      navigation(Route_Paths.EVENTS);
    }
  });

  const eventDetails = useCallback((data) => {
    setHostName(data.host);
    setEventName(data.event);
    setCustomUrl(data.customUrl);
    setEventDescription(data.description);
    setStartDate(data.startDate);
    setEndDate(data.endDate);
    setThankYouCard(data.thankYouCardUrl);
  }, []);

  const selectedCause = useCallback((data) => {
    setCauses(data);
  }, []);

  const handleCreateEvent = () => {
    const values = {
      host_name: hostName,
      event_name: eventName,
      custom_url: customUrl,
      event_description: eventDescription,
      start_date_time: startDate,
      end_date_time: endDate,
      causes: causes,
      thank_you_card_url: thankYouCardUrl,
    };
    if (currentEventData) {
      const id = currentEventData._id;
      dispatch(eventUpdateRequest({ id, data: values }));
    } else {
      dispatch(createEventRequest(values));
    }
  };

  const areAllFieldsFilled = () => {
    return (
      hostName &&
      eventName &&
      customUrl &&
      eventDescription &&
      startDate &&
      endDate &&
      thankYouCardUrl &&
      causes.length > 0
    );
  };

  return (
    <Screen showFooter={true}>
      <Box sx={styles.container}>
        <Grid container>
          <Grid item xs={12} md={5}>
            <CreateEvent onDataChange={eventDetails} data={currentEventData} />
          </Grid>
          <Grid item xs={12} md={7}>
            <EventImages
              causeImages={selectedCause}
              data={filteredProjects}
              onEdit={eventCauses}
            />
          </Grid>
        </Grid>
        <Box sx={styles.buttonContainer}>
          <UIButton
            title={currentEventData ? "Save" : "Create Event"}
            style={styles.buttonStyle}
            onClick={handleCreateEvent}
            disabled={!areAllFieldsFilled()}
          />
        </Box>
      </Box>
    </Screen>
  );
};
