/* eslint-disable no-undef */
import { Route_Paths } from "../navigation/routes";
import { commaMaskedAmount } from "../utils/strings";

export const defaultValues = {
  isResponsive: window.innerWidth < 1030,
  contact_phone: "+91-987654321",
  contact_email: "info@otoogifts.org",
  quickLinks: {
    aboutUs: "About Us",
    contactUs: "Contact Us",
    donation: "Donation",
    faqs: "FAQs",
  },
  ThankYouCard:"Select Thank You Card",
  ImagebuttonText:"Background image",

  OTOO: "OTOO",
  loginText: "Welcome to OTOO!",
  otoo_stands: "On The Occasion Of",

  discription:
    " We at Otoo are dedicated to make a difference one event at a time. We believe in the power of every occasion, big or small, to create positive change in the world. With Otoo-, your events have a purpose beyond themselves, making the world a better place.",

  admin_navigation: [
    "Manage Projects",
    "Manage Events",
    "Manage Users",
    "All Donations",
    "Logout",
  ],

  navigationRoutes: {
    "Manage Projects": Route_Paths.MANAGE_PROJECT,
    "Manage Events": Route_Paths.MANAGE_EVENTS,
    "Manage Users": Route_Paths.MANAGE_USERS,
    "All Donations": Route_Paths.DONATIONS,
    Logout: "Logout",
  },

  admin_navigation_responsive: [
    "Manage Projects",
    "Manage Events",
    "Manage Users",
    "Manage Donations",
    "Logout",
  ],

  admin_navigation_link: [Route_Paths.MANAGE_PROJECT, "/handle-project"],

  host_event_menu: [
    { title: "Your events", route: Route_Paths.EVENTS },
    { title: "Your donations", route: Route_Paths.VIEW_DONATIONS },
  ],
  landingCardDetails: [
    `1. Create an Event: Select an occasion (e.g., 50th birthday party) and choose from OTOOGifts' highly trusted and curated causes (e.g., school uniforms for government school children). Share this with your invitation as your preferred gift option`,
    `2. Make a Contribution: Your guests can contribute towards the chosen cause. They will receive an 80G tax exemption certificate and a personalized thank you note.`,
    "3. Receive a Certificate: You will receive a beautiful digital certificate detailing the impact of the contributions, such as the number of uniforms donated",
  ],
  inviteToDonateMsg: (eventName) =>
    `On the occasion of ${eventName}, I want to make a difference to society. I request you to help donate to the causes that are close to my heart as a gift to me. Together, we can create positive change. Click the link below:`,

  landingPage: {
    topContent: `A celebration in greed can be a celebration in deed, to those in need!`,
    heading: (
      <div>
        GI<span style={{ color: "red" }}>V</span>FTING
      </div>
    ),
    subHeading: `Make (Y)OUR Occasions Matter to THEM!`,
    changingTitle: `Turn Birthdays, anniversaries, weddings, graduations into fundraising opportunities for NGOs doing great work!`,

    q1: "Wondering what OTOO is about?",
    q1Content:
      "OTOO is a non-profit organisation that lets you create events for different causes, you can then easily share the event link with your network, enabling them to contribute to the cause that touches their hearts. Together, we can turn celebrations into fundraising opportunities.",
    q1SubHeading: "But what if there was a better way?",
    q1SubParagraph: "Imagine this :",

    // changes request to
    q1a: "What is GIVFTING?",
    q1aContent: (
      <>
        <strong>GIVFTING</strong> allows you to invite friends and family for
        your special occasion and request them to direct gifts on your behalf to
        an NGO that you choose. Those who donate get a tax break, and you get
        the pleasure of knowing that your special occasion helped causes that
        are close to your heart.
      </>
    ),

    headingCardTitles: [
      "On your mother’s 90th birthday",
      "On your grandfather’s birth aniversary",
      "On Diwali",
    ],
    card1Content: [
      "Invite your family and friends, throw her a big party, celebrate in style. Pick your NGO from our curated list and causes they work on - for example a school uniform. Your guests make contributions and receive a tax break while 90 deserving children receive free school uniforms. Truly a WIN-WIN-WIN!",
      "Your grandfather was all about feeding the poor - and what better way to remember his birth anniversary by feeding 100 school children. And guess what - now you and your cousins can remember grandpa by collectively contributing to such a cause at the click of a button.",
      "You run a large company. You buy your team (unhealthy) Diwali sweets every year costing thousands of rupees. What if you also gifted a deserving child with a sweater - and shared a photograph of school children in amazing sweaters and terrific smiles.",
    ],

    cardSec2Content: [
      "Use our simple interface to create an event associated with your Special Occasion.",
      "Choose from our curated NGOs and their projects. Once you’ve done that, our platform will create an event that you can share along with a QR code.",
      "Your Friends can now Click on the link you share and make a tax-exempt donation to the causes chosen by you. Your friends will automatically receive a confirmation email and a Section 80G receipt from the NGO. You get confirmation via email every time your friends make a donation. You’ve now truly turned GIFTING into GIVING - or as we like to call it, GIVFTING!",
    ],
    s3Heading:
      "Make a Difference on Special Occasions with Our Meaningful Donations.",
    s3Content:
      "Discover a unique way to give back by donating to meaningful causes on special occasions. Whether its a birthday, anniversary, or milestone event, make a lasting impact with our donation platform.",

    s6Heading: "About Us",
    s6Content:
      "At OTOOGifts.org, we believe in transforming the way we celebrate special moments by giving back to those in need.",
    s6SubContent:
      "Whether its a festival, a personal milestone, or a professional achievement, we often find ourselves spending on gifts that may not always be appreciated or remembered. From Diwali and Christmas to birthdays and anniversaries, the cycle of obligatory gifting can be financially draining and socially challenging. The pressure to choose the perfect gift, determine the right amount to spend, and ensure it is well-received can be overwhelming",
    s6FooterHeading: "Introducing OTOOGifts.org",
    s6FooterContent:
      'A unique service that allows you to make donations to meaningful causes "On The Occasion Of" various events in the lives of your loved ones, colleagues, employees, and friends.',

    a1: "At OTOOGifts.org, we believe in transforming the way we celebrate special moments by giving back to those in need.",
    a2: `Whether it's a festival, a personal milestone, or a professional achievement, we often find ourselves spending on gifts that may not always be appreciated or remembered. From Diwali and Christmas to birthdays and anniversaries, the cycle of obligatory gifting can be financially draining and socially challenging. The pressure to choose the perfect gift, determine the right amount to spend, and ensure it is well-received can be overwhelming`,
    aa3: "But what if there was a better way?",
    aa4: "Imagine this: ",
    aa5: `On your mother's 70th birthday , instead of buying an expensive saree, you and your siblings could ensure that 70 children are fed for a year.`,
    aa6: `On your grandfather's birth anniversary , alongside a traditional puja, you could provide a scholarship for a young girl to become a doctor.`,
    aa7: `On Diwali , instead of giving 500 employees boxes of sweets, you could provide warm sweaters to 500 government school children for the harsh winters.`,
    aa8: "How It Works:",
    aa9: "Introducing OTOOGifts.org",
    aa10: 'A unique service that allows you to make donations to meaningful causes "On The Occasion Of" various events in the lives of your loved ones, colleagues, employees, and friends',
    testimonial: "Testimonials",
    testimonialSub: "Hear from our guests",
    testimonial1: `Otoo- makes it so simple to give back. I've attended multiple events and love knowing that my participation directly benefits important causes.`,
    testimonial2: `I hosted a birthday fundraiser through Otoo- and was amazed at how easy it was to support my favorite cause. It's a brilliant platform for turning personal celebrations into acts of kindness.`,
    testimonial3: `The flexibility to choose the causes I care about in connection with various events is what sets Otoo- apart. It's an excellent way to make a real impact.`,
    tistimonialTitle: "Real Stories, Real Impact",
    q2: "Got questions? We’ve got you covered",
    intro: "Our Trusted Partners",
    i1: "To ensure the highest integrity and trust, OTOOGifts has partnered with Rotary, a globally respected organization. Only projects approved by Rotary are featured on our platform.",
    i2: "Join us in making every occasion a reason to give back. Visit OTOOGifts.org and turn your celebrations into meaningful contributions. ",
    questions: {
      individualsNfamily: {
        title: "For Individuals and Families",
        qNa: [
          {
            q: "How do I create an event on OTOOGifts.org ?",
            a: 'Creating an event is simple. Visit our website, click on "Create Event," select the occasion, choose a cause from our curated list, and share the event details with your invitees.',
          },
          {
            q: "Can I choose any cause I want to support ?",
            a: "Yes, you can choose from a variety of causes that have been carefully vetted and approved by our partner, Rotary. We offer a diverse range of causes to match your interests and values.",
          },
          {
            q: "What do I receive after my guests make a contribution ?",
            a: "You will receive a beautiful digital certificate that highlights the impact of the contributions, such as the number of uniforms donated or meals provided. This certificate can be a cherished reminder of the difference made on your special occasion.",
          },
          {
            q: "Are the contributions tax-deductible ?",
            a: "Yes, all contributions made through OTOOGifts.org are eligible for an 80G tax exemption. Contributors will receive a certificate for tax purposes.",
          },
          {
            q: "How do I know the donations are being used appropriately ?",
            a: "We partner with Rotary, a globally respected organization, to ensure all projects and causes are legitimate and impactful. You can trust that your contributions are making a real difference.",
          },
        ],
      },
      for_Employers: {
        title: "For Employers",
        qNa: [
          {
            q: "How can I use OTOOGifts.org for corporate gifting ?",
            a: "Employers can create events for various occasions like Diwali, Christmas, or employee milestones. Choose a cause and share the event details with your employees, encouraging them to contribute.",
          },
          {
            q: "What are the benefits of using OTOOGifts for corporate events ?",
            a: `Using OTOOGifts for corporate events enhances your company's social responsibility profile, boosts employee morale, and provides a meaningful alternative to traditional corporate gifts.`,
          },
          {
            q: "Can employees receive tax benefits for their contributions ?",
            a: "Yes, employees who contribute will receive an 80G tax exemption certificate for their donations.",
          },
          {
            q: "How do I communicate this initiative to my employees ?",
            a: "We provide customizable templates and communication materials to help you inform your employees about the initiative and how they can participate.",
          },
          {
            q: "What kind of feedback will I receive about the contributions ?",
            a: "As an employer, you will receive a summary report detailing the impact of the contributions, such as the total number of sweaters provided to school children or meals served, which you can share with your employees.",
          },
        ],
      },
      for_Ngo: {
        title: "For NGOs and Partner Organizations",
        qNa: [
          {
            q: "How can my organization become a partner with OTOOGifts.org ?",
            a: "If your organization is interested in partnering with us, please contact us through our website. We have a vetting process in place to ensure all partners align with our values and standards.",
          },
          {
            q: "What are the criteria for a cause to be featured on OTOOGifts.org ?",
            a: `Causes must be approved by Rotary and meet our standards for integrity, impact, and transparency. We focus on projects that make a tangible difference in communities.`,
          },
          {
            q: "How will my organization benefit from partnering with OTOOGifts ?",
            a: "Partnering with OTOOGifts provides your organization with a platform to reach a wider audience, increase donations, and raise awareness for your cause.",
          },
          {
            q: "How do we ensure the transparency of the contributions ?",
            a: "We provide detailed reports to donors and event creators about how the funds are used. This transparency helps build trust and ensures continued support.",
          },
          {
            q: "Can we propose new projects or causes for inclusion ?",
            a: "Yes, partner organizations can propose new projects or causes. These proposals will be reviewed and, if they meet our criteria, added to our platform.",
          },
        ],
      },
      for_eventOrg: {
        title: "For Event Organizers",
        qNa: [
          {
            q: "How do I integrate OTOOGifts into my event planning services ?",
            a: "You can offer OTOOGifts as an alternative gift option to your clients. We provide all the necessary tools and materials to seamlessly incorporate our service into your event planning process.",
          },
          {
            q: "What support does OTOOGifts provide to event organizers ?",
            a: `We offer marketing materials, customizable templates, and dedicated support to help you explain and promote the concept to your clients. `,
          },
          {
            q: "Can I track the contributions made for an event I organize ?",
            a: "Yes, as an event organizer, you will have access to a dashboard where you can track contributions and see the impact of the donations.",
          },
          {
            q: "How does using OTOOGifts enhance the experience for event attendees ?",
            a: "Attendees can feel good knowing their contributions are making a real difference. They receive tax benefits and a personalized thank you note, enhancing their overall experience.",
          },
          {
            q: "What types of events are best suited for using OTOOGifts ?",
            a: "OTOOGifts is perfect for all types of events, including birthdays, anniversaries, weddings, corporate events, and festivals. Any occasion where gifts are typically exchanged can be transformed into an opportunity to give back.",
          },
        ],
      },
      for_donors: {
        title: "For Donors",
        qNa: [
          {
            q: "How do I make a contribution to an event ?",
            a: "When you receive an invitation with an OTOOGifts link, simply click on the link, choose the amount you wish to donate, and complete the payment process.",
          },
          {
            q: " Will I receive a receipt for my donation ?",
            a: `Yes, you will receive an 80G tax exemption certificate and a personalized thank you note for your contribution.`,
          },
          {
            q: "Can I choose the specific cause I want to support within an event ?",
            a: "The event organizer selects the cause, but you can always browse our website for other causes to support directly if you wish.",
          },
          {
            q: "How can I be sure my donation is making a difference?",
            a: "OTOOGifts partners with Rotary and other trusted organizations to ensure all donations are used effectively. You will receive detailed information about how your donation has been utilized.",
          },
          {
            q: "Can I make anonymous donations?",
            a: " Yes, you have the option to make your donation anonymously if you prefer.",
          },
        ],
      },
    },
    note: "By addressing these questions, we aim to provide clarity and confidence to all stakeholders involved with OTOOGifts.org. For any additional queries, please feel free to contact us through our website",
  },
  paymentHeader: "Rotary Bangalore IT Corridor CT",
  paymentDescription: "Payments on otoo",

  twitter: "https://twitter.com/rbitc",
  facebook: "https://www.facebook.com/RotaryBangaloreITCorridor/",
  youtube: "https://www.youtube.com/channel/UCOk5l1E4EW3I6m3ZOcn-Kyg",

  donateSuccessMsg: (hostName, eventName, amount, guestName) =>
    `Hey ${hostName} ,\n On the occasion of ${eventName}, I am delighted to contribute ${commaMaskedAmount(
      amount,
    )}. \n -${guestName}`,

  noActiveText: "Payments are not being accepted for this event at the moment",
  noActiveCauseText: "No active causes available for this event",
  endDateText: "The donation time has ended.",
  noData: "Oops! Something went wrong.",
  customLinkChanged: "The link you are trying to access is no longer available. Please reach out to the person who shared the link and request a new one."
};
