import React, { useRef } from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";

import { Text, TextInput } from "../../components";
import { detailStyles } from "./detailStyles";
import { userDetailsSchema } from "../../utils/schemas";
import { NumberInput } from "../ui-kit";

const UserDetails = ({ onDataChange, data }) => {
  const { values, handleChange, errors, touched, setFieldTouched, isValid } =
    useFormik({
      initialValues: {
        firstName: data.first_name ? data.first_name : "",
        lastName: data.last_name ? data.last_name : "",
        phoneNo: data.phone_number ? data.phone_number : "",
        email: data.email_id ? data.email_id : "",
        addressLineOne: data.address_line_one ? data.address_line_one : "",
        addressLineTwo: data.address_line_two ? data.address_line_two : "",
        city: data.city ? data.city : "",
        state: data.state ? data.state : "",
        pinCode: data.pin_code ? data.pin_code : "",
      },
      validateOnBlur: true,
      validateOnMount: true,
      validateOnChange: true,
      validationSchema: userDetailsSchema,
    });

  onDataChange(values, isValid);

  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);
  const phoneNoRef = useRef(null);

  return (
    <>
      <Box sx={detailStyles.container}>
        <Box sx={detailStyles.innerContainer}>
          <div style={detailStyles.textFieldContainer}>
            <Text text="First Name" sx={detailStyles.textStyle} />
            <TextInput
              ref={firstNameRef}
              required
              id="firstName"
              name="firstName"
              label="Enter first name"
              value={values.firstName}
              error={touched.firstName && errors.firstName}
              onChange={handleChange}
              onBlur={() => setFieldTouched("firstName")}
              InputProps={{ sx: detailStyles.borderStyle }}
              sx={detailStyles.fieldSize}
            />
          </div>
          <div style={detailStyles.textFieldContainer}>
            <Text text="Last name" sx={detailStyles.textStyle} />
            <TextInput
              required
              ref={lastNameRef}
              InputProps={{ sx: detailStyles.borderStyle }}
              id="lastName"
              name="lastName"
              label="Enter last name"
              value={values.lastName}
              error={touched.lastName && errors.lastName}
              onChange={handleChange}
              onBlur={() => setFieldTouched("lastName")}
              sx={detailStyles.fieldSize}
            />
          </div>
        </Box>
        <Box sx={detailStyles.innerContainer}>
          <div style={detailStyles.textFieldContainer}>
            <Text text="Phone No" sx={detailStyles.textStyle} />
            <NumberInput
              required
              ref={phoneNoRef}
              InputProps={{ sx: detailStyles.borderStyle }}
              id="phoneNo"
              name="phoneNo"
              label="Enter phone no"
              value={values.phoneNo}
              error={touched.phoneNo && errors.phoneNo}
              onChange={handleChange}
              onBlur={() => setFieldTouched("phoneNo")}
              sx={detailStyles.fieldSize}
              isNum={true}
            />
          </div>
          <div style={detailStyles.textFieldContainer}>
            <Text text="Email" sx={detailStyles.textStyle} />
            <TextInput
              InputProps={{ sx: detailStyles.borderStyle }}
              id="outlined-basic"
              variant="outlined"
              sx={detailStyles.fieldSize}
              label="Enter email"
              name="email"
              value={values.email}
              disabled={true}
              onChange={handleChange}
            />
          </div>
        </Box>
        <Box sx={detailStyles.textStyle}>
          <div style={detailStyles.middleField}>
            <Text text="Address Line 1" sx={detailStyles.textStyle} />
            <TextInput
              InputProps={{ sx: detailStyles.borderStyle }}
              id="outlined-basic"
              sx={detailStyles.middleFieldSize}
              label="Enter address line 1"
              variant="outlined"
              name="addressLineOne"
              value={values.addressLineOne}
              onChange={handleChange}
            />
          </div>
        </Box>{" "}
        <Box sx={detailStyles.innerContainer}>
          <div style={detailStyles.textFieldContainer}>
            <Text text="Address Line 2" sx={detailStyles.textStyle} />
            <TextInput
              InputProps={{ sx: detailStyles.borderStyle }}
              id="outlined-basic"
              variant="outlined"
              sx={detailStyles.fieldSize}
              name="addressLineTwo"
              value={values.addressLineTwo}
              label="Address line 2"
              onChange={handleChange}
            />
          </div>
          <div style={detailStyles.textFieldContainer}>
            <Text text="City" sx={detailStyles.textStyle} />
            <TextInput
              InputProps={{ sx: detailStyles.borderStyle }}
              id="outlined-basic"
              variant="outlined"
              sx={detailStyles.fieldSize}
              label="Enter city"
              name="city"
              value={values.city}
              onChange={handleChange}
              onBlur={() => setFieldTouched("city")}
              error={touched.city && errors.city}
            />
          </div>
        </Box>
        <Box sx={detailStyles.innerContainer}>
          <div style={detailStyles.textFieldContainer}>
            <Text text="State" sx={detailStyles.textStyle} />
            <TextInput
              InputProps={{ sx: detailStyles.borderStyle }}
              id="outlined-basic"
              variant="outlined"
              sx={detailStyles.fieldSize}
              label="Enter state"
              name="state"
              value={values.state}
              onChange={handleChange}
              onBlur={() => setFieldTouched("state")}
              error={touched.state && errors.state}
            />
          </div>
          <div style={detailStyles.textFieldContainer}>
            <Text text="Pin Code" sx={detailStyles.textStyle} />
            <TextInput
              InputProps={{ sx: detailStyles.borderStyle }}
              id="outlined-basic"
              variant="outlined"
              sx={detailStyles.fieldSize}
              name="pinCode"
              value={values.pinCode}
              label="Enter pin code"
              onChange={handleChange}
              onBlur={() => setFieldTouched("pinCode")}
              error={touched.pinCode && errors.pinCode}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default UserDetails;
