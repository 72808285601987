import { defaultValues } from '../../constants';
import { color } from '../../theme/color';

export const profileStyles = {
  container: {
    backgroundColor: color.primaryBackground,
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
    width: '100%',
    pb: 3
  },
  subContainer: {
    display: 'flex',
    flexDirection: { xs: 'column-reverse', md: 'row' },
    width: '100%'
  },
  innerContainer: {
    width: defaultValues.isResponsive ? '100%' : '50%',
    my: 4,
    mx: !defaultValues.isResponsive && 4
  },
  buttonStyle: {
    backgroundColor: color.primary,
    paddingLeft: 3,
    paddingRight: 3,
    color: color.primaryText,
    '&:hover': {
      backgroundColor: color.primary
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
};
