/* eslint-disable react/prop-types */
import React from 'react';
import { ThemeProvider, Button } from '@mui/material';

import Theme from '../color';
import { Text } from '../text';

export const UIButton = (props) => {
  const {
    variant = 'contained',
    size = 'large',
    title,
    style = { minWidth: '15%', maxWidth: '15%' },
    disabled = false,
    textStyle,
    textVariant,
    ...rest
  } = props;

  return (
    <ThemeProvider theme={Theme}>
      <Button
        variant={variant}
        size={size}
        color={variant === 'contained' ? 'yellow' : 'black'}
        style={style}
        disabled={disabled}
        {...rest}>
        <Text text={title} style={textStyle} variant={textVariant} />
      </Button>
    </ThemeProvider>
  );
};
