import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import img from '../../assets/images/profile.jpg';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { ProfileStyles } from './profileStyles';

const Profile = ({ photo, imageUrl }) => {
  const [imgUpload, setImgUpload] = useState('');

  const fileInputRef = useRef(null);

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    photo(selectedFile);
    if (selectedFile) {
      const imageURL = URL.createObjectURL(selectedFile);
      setImgUpload({
        file: selectedFile,
        imageURL: imageURL
      });
    }
  };

  return (
    <Box sx={ProfileStyles.container}>
      <Box sx={ProfileStyles.imgContainer}>
        {imgUpload.imageURL ? (
          <img src={imgUpload.imageURL} alt="Profile" style={ProfileStyles.imgStyle} />
        ) : (
          <img src={imageUrl ? imageUrl : img} alt="Profile" style={ProfileStyles.imgStyle} />
        )}
        <CameraAltIcon sx={ProfileStyles.iconStyles} onClick={handleFileUploadClick} />
        <CameraAltIcon sx={ProfileStyles.iconStyles} onClick={handleFileUploadClick} />
      </Box>
      <input
        type="file"
        ref={fileInputRef}
        style={ProfileStyles.inputStyle}
        onChange={handleFileChange}
      />
    </Box>
  );
};

export default Profile;
