import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, InputAdornment, MenuItem, Select } from '@mui/material';
import { useFormik } from 'formik';

import { styles } from './styles';
import { isAdminProfile } from '../../redux/selectors';
import { PageHeader, Screen, Text, TextInput, UIButton } from '../../components';

import LeftIcon from '../../assets/images/Left.png';
import { Route_Paths, defaultValues } from '../../constants';

import { createEventSchema } from '../../utils/schemas';
import { createProjectRequest, updateProjectRequest } from '../../redux/slices';

import { ConvertIndian } from '../../utils/currencyConvert';
import { deleteImage, getProjectById } from '../../services/api';

export const AddProject = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [heading, setHeading] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [imgURLs, setImgURLs] = useState([]);
  const [imgFiles, setImgFiles] = useState([]);
  const [localUrl, setLocalUrl] = useState([]);
  const [numberInWords, setNumberInWords] = useState('');

  const fileInputRef = useRef(null);

  const causeRef = useRef(null);
  const fundRef = useRef(null);
  const purposeRef = useRef(null);
  const infoRef = useRef(null);

  const isAdmin = useSelector(isAdminProfile);

  let navType = location.state.type;

  const [data, setData] = useState(null);

  const { values, handleChange, errors, touched, setFieldTouched } = useFormik({
    initialValues: {
      cause: data ? data.project_name : '',
      fundDetails: data ? data.fund_to_be_raised : '',
      purpose: data ? data.purpose : '',
      status: data ? data.status : '',
      additional_info: data ? data.additional_info : ''
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: createEventSchema
  });

  useEffect(() => {
    if (navType === 'add') {
      setHeading('Add new project');
      setIsEdit(false);
    } else {
      setHeading('Edit project');
      setIsEdit(true);
    }
  }, [location]);

  useEffect(() => {
    if (navType !== 'add') {
      const { _id } = location.state.data;
      const fetchProject = async () => {
        const response = await getProjectById(_id);
        const project = response.data.data;
        setData(project);
        setImgURLs(project.images);
      };
      fetchProject();
    }
  }, []);

  const handleBack = () => {
    navigation(-1);
  };

  const onSubmit = async (type, id) => {
    const formData = new FormData();
    formData.append('project_name', values.cause);
    formData.append('fund_to_be_raised', values.fundDetails);
    formData.append('purpose', values.purpose);
    imgFiles.forEach((file) => {
      formData.append(`images`, file);
    });
    formData.append('status', values.status);
    formData.append('additional_info',values.additional_info);

    if (type === 'create') {
      dispatch(createProjectRequest(formData));
    } else {
      dispatch(updateProjectRequest({ id, formData }));
    }

    navigation(Route_Paths.MANAGE_PROJECT);
  };

  const handleFileUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);
    const newImgURLs = selectedFiles.map((file) => URL.createObjectURL(file));
    setImgFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    setImgURLs((prevURLs) => [...prevURLs, ...newImgURLs]);
    setLocalUrl((prevURLs) => [...prevURLs, ...newImgURLs]);
  };

  const handleFundDetailsChange = (event) => {
    const { value } = event.target;

    handleChange(event);

    if (!isNaN(value)) {
      const num = parseInt(value, 10);
      if (!isNaN(num)) {
        setNumberInWords(ConvertIndian(num));
      } else {
        setNumberInWords('');
      }
    }
  };

  const handleDeleteImage = async (url) => {
    try {
      if (url.startsWith('blob:')) {
        setImgURLs((prevURLs) => prevURLs.filter((image) => image !== url));
        URL.revokeObjectURL(url);
        const indexToDelete = localUrl.findIndex((imageURL) => imageURL === url);
        if (indexToDelete !== -1) {
          const updatedFiles = [...imgFiles];
          updatedFiles.splice(indexToDelete, 1);
          setImgFiles(updatedFiles);
        }
      } else {
        const imgData = {
          projectId: data._id,
          imageUrl: url
        };

        await deleteImage(imgData);

        setImgURLs((prevURLs) => prevURLs.filter((image) => image !== url));
      }
    } catch (error) {
      throw new Error(error.response.data.error_code);
    }
  };

  return (
    <Screen showSideBar={isAdmin}>
      <div style={styles.container}>
        <Box sx={styles.iconContainer}>
          <img src={LeftIcon} style={styles.icon} onClick={handleBack} />
          <PageHeader title={heading} />
        </Box>
        <Box sx={styles.editContainer}>
          <Box sx={styles.editParentContainer}>
            <Box sx={styles.imgContainer}>
              <Button sx={styles.uploadButtonStyle} onClick={handleFileUploadClick}>
                Upload Project Images
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                multiple
              />
              <Box sx={styles.imageContainer}>
                {imgURLs.map((url, index) => (
                  <Box key={index} sx={styles.deleteContainer}>
                    <img src={url} alt={`upload-${index}`} style={styles.imageStyle} />
                    <Box sx={styles.deleteIconStyle} onClick={() => handleDeleteImage(url)}>
                      X
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box>
              <Text style={defaultValues.isResponsive && styles.textStyle}>
                Add an image to help user understand the cause
              </Text>
            </Box>
            <Box sx={styles.inputFieldContainer}>
              <TextInput
                ref={causeRef}
                required
                id="cause"
                name="cause"
                label="Enter the cause"
                multiline
                sx={styles.inputFieldStyle}
                value={values.cause}
                error={touched.cause && errors.cause}
                onChange={handleChange}
                onBlur={() => setFieldTouched('cause')}
              />
              <TextInput
                ref={fundRef}
                id="fundDetails"
                required
                name="fundDetails"
                label="Fund to be raised"
                value={values.fundDetails}
                InputProps={{
                  inputProps: {
                    onChange: handleFundDetailsChange
                  },
                  startAdornment: <InputAdornment position="start">₹</InputAdornment>
                }}
                style={styles.inputFieldStyle}
                error={touched.fundDetails && errors.fundDetails}
                onBlur={() => setFieldTouched('fundDetails')}
              />
              {numberInWords !== '' && (
                <p
                  style={{
                    color: 'red',
                    marginLeft: '15px',
                    marginTop: '-30px',
                    textTransform: 'capitalize'
                  }}>
                  {numberInWords}
                </p>
              )}
              <TextInput
                ref={infoRef}
                id="additional_info"
                name="additional_info"
                label="Enter the Additional Info"
                sx={styles.inputFieldStyle}
                value={values.additional_info}
                onChange={handleChange}
              />
              <TextInput
                id="purpose"
                ref={purposeRef}
                required={true}
                name="purpose"
                label="Purpose"
                rows={4}
                multiline
                value={values.purpose}
                style={styles.inputFieldStyle}
                error={touched.purpose && errors.purpose}
                onChange={handleChange}
                onBlur={() => setFieldTouched('purpose')}
              />
              <Select
                id="status"
                name="status"
                value={values.status}
                displayEmpty
                style={{ width: '90%', margin: '15px' }}
                onChange={handleChange}>
                <MenuItem value="">Select Status</MenuItem>
                <MenuItem value="active">ACTIVE</MenuItem>
                <MenuItem value="inactive">INACTIVE</MenuItem>
              </Select>
            </Box>
          </Box>
        </Box>
        <div style={styles.buttonContainer}>
          {isEdit ? (
            <UIButton
              title="Save"
              onClick={() => onSubmit('update', data._id)}
              style={styles.button}
              disabled={
                !values.cause ||
                !values.fundDetails ||
                !values.purpose ||
                (values.cause === data.project_name &&
                  values.fundDetails === data.fund_to_be_raised &&
                  values.purpose === data.purpose &&
                  values.status === data.status &&
                  values.additional_info === data.additional_info &&
                  data.images.length === imgURLs.length) ||
                Object.keys(errors).length > 0
              }
            />
          ) : (
            <UIButton
              title="Create new project"
              onClick={() => onSubmit('create', null)}
              style={styles.button}
              disabled={
                !values.cause ||
                !values.fundDetails ||
                !values.purpose ||
                !values.status ||
                Object.keys(errors).length > 0
              }
            />
          )}
        </div>
      </div>
    </Screen>
  );
};
