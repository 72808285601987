// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rsis-image {
  border-radius: 10px !important;
}
.container {
  border-radius: 10px !important;
}
.imageSliderContainer {
  border-radius: 10px !important;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-kit/slider/style.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;AAChC;AACA;EACE,8BAA8B;AAChC;AACA;EACE,8BAA8B;EAC9B,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".rsis-image {\n  border-radius: 10px !important;\n}\n.container {\n  border-radius: 10px !important;\n}\n.imageSliderContainer {\n  border-radius: 10px !important;\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
