import { color } from '../../../theme/color';

export const styles = {
  container: {
    width: '88%',
    boxShadow: 2,
    borderRadius: 2,
    p: 2,
    backgroundColor: color.palette.lightBlue,
    m: 1
  },
  subContainer: { width: '50%' },

  textContainer: { display: 'flex', alignItems: 'center' },

  title: { fontSize: 18, fontWeight: 500 },
  resTitle: { fontSize: 15, fontWeight: 500 },

  text: { fontSize: 14, fontWeight: 500, marginLeft: '2%' },
  resText: { fontSize: 12, fontWeight: 500, marginLeft: '2%' },

  amnt: { fontSize: 15, fontWeight: 600, marginLeft: '2%' },
  resAmnt: { fontSize: 12, fontWeight: 600, marginLeft: '2%' },

  icon: { height: '15px', objectFit: 'contain', margin: 2 }
};
