import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Text } from "../ui-kit";
import { style } from "./imageStyles";
import { defaultValues } from "../../constants";
import { ImageSlider } from "../ui-kit/slider";
import { commaMaskedAmount } from "../../utils/strings";

export const EventImages = ({ data, causeImages, onEdit }) => {
  const imageIdToBeEdited = onEdit.map((item) => item._id);
  const [selectedImages, setSelectedImages] = useState([]);
  const [expanded, setExpanded] = useState({});

  const imageDetails = data.map((item) => {
    return item;
  });

  const handleSelection = (id) => {
    if (selectedImages.includes(id)) {
      const exclude = selectedImages.filter((selectedId) => selectedId !== id);
      setSelectedImages(exclude);
    } else {
      setSelectedImages([...selectedImages, id]);
    }
  };

  const handleExpandClick = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  useEffect(() => {
    setSelectedImages([...selectedImages, ...imageIdToBeEdited]);
  }, [onEdit]);

  useEffect(() => {
    causeImages(selectedImages);
  }, [selectedImages, causeImages]);

  return (
    <Box sx={style.innerContainer}>
      <Text variant="primarySubTitles">Select cause(s)</Text>
      <Box sx={style.imageContainer}>
        {imageDetails.map((image, index) => (
          <Box
            key={index}
            sx={{
              ...style.imgContainer,
              ...(selectedImages.includes(image._id) &&
                style.selectedImageStyle),
            }}
            onClick={() => handleSelection(image._id)}
          >
            <Box key={index} sx={style.imageGrid}>
              <Box sx={style.s4card(defaultValues.isResponsive)}>
                <ImageSlider images={image.images} width={300} height={250} />
                <Typography variant="subtitle1" sx={style.projectName}>
                  {image.project_name}
                </Typography>
                {image.purpose && (
                  <Box sx={style.additionalInfo}>
                    <Typography variant="body2" sx={style.additionalInfo}>
                      {expanded[index]
                        ? image.purpose
                        : `${image.purpose.substring(0, 35)}...`}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={style.additionalInfoExpand}
                      onClick={() => handleExpandClick(index)}
                    >
                      {expanded[index] ? "Show Less" : "Show More"}
                    </Typography>
                  </Box>
                )}
                <Box sx={style.s4detailsContainer}>
                  <Box>
                    <Typography variant="body1" sx={style.bodyText}>
                      Fund Raised
                    </Typography>
                    <Typography variant="body1" sx={style.bodyText}>
                      Fund to be Raised
                    </Typography>
                  </Box>
                  <Box sx={style.rightDetails}>
                    <Typography variant="body1" sx={style.bodyText}>
                      {`${commaMaskedAmount(image.fund_raised)}`}
                    </Typography>
                    <Typography variant="body1" sx={style.bodyText}>
                      {`${commaMaskedAmount(image.fund_to_be_raised)}`}
                    </Typography>
                  </Box>
                </Box>
                {image.additional_info && (
                  <Typography
                    sx={style.purpose}
                  >{`${image.additional_info.substring(0, 35)}`}</Typography>
                )}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
