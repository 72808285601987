/**
 * The various styles of fonts are defined in the <Text /> component.
 *
 * How to add font
 * Run the command: npx react-native link
 *
 * This will add the fonts in this directory to the native iOS and Android projects.
 *  Ensure the path to this directory at react-native.config.js is correct.
 */
export const fonts = {
  /**
   * The primary font.  Used in most places like normal text and paragraphs.
   */
  primary: 'Quicksand-Medium',
  /**
   * An alternate font used for perhaps sub-titles and stuff.
   */
  secondary: 'Quicksand',
  /**
   * An alternate font used for perhaps Page Header and etc.
   */
  header: 'Roboto'
};
