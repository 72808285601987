import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';

import { Loader, ManageEventCard, Screen, Text, UIButton } from '../../../../components';
import { EventPageStyles } from './style';
import {
  ERROR_KEY,
  Route_Paths,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../../../constants';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  allEvents,
  eventFailureMessage,
  eventsSuccessMessage,
  getDonations,
  isEventLoading,
  updateErrorsMsg,
  updateSuccessMsg,
  userInfo
} from '../../../../redux/selectors';
import { eventRequestByUser, projectsRequest, setCurrentEventData } from '../../../../redux/slices';
import { useToasts } from 'react-toast-notifications';

export const HostEvents = () => {
  const { addToast } = useToasts();

  const navigation = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector(userInfo);
  const userEvents = useSelector(allEvents);
  const donations = useSelector(getDonations);
  const id = userData && userData._id;
  const isLoading = useSelector(isEventLoading);

  const eventSuccess = useSelector(eventsSuccessMessage);
  const eventFailure = useSelector(eventFailureMessage);
  const updateSuccess = useSelector(updateSuccessMsg);
  const updateError = useSelector(updateErrorsMsg);

  const [selected, setSelected] = useState(0);
  const [isDonation, setIsDonation] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (userData._id) {
      dispatch(eventRequestByUser(id));
      dispatch(projectsRequest());
      dispatch(setCurrentEventData(null));
    }
  }, [dispatch]);

  useEffect(() => {
    if (userEvents) {
      setData(userEvents);
    }
  }, [userEvents]);

  useEffect(() => {
    if (eventSuccess) {
      addToast(getSuccessMessage(eventSuccess), {
        appearance: SUCCESS_KEY,
        autoDismiss: true
      });
    }

    if (eventFailure) {
      addToast(getErrorMessage(eventFailure), {
        appearance: ERROR_KEY,
        autoDismiss: true
      });
    }
  }, [eventFailure, eventSuccess]);

  useEffect(() => {
    if (updateSuccess) {
      addToast(getSuccessMessage(updateSuccess), {
        appearance: SUCCESS_KEY,
        autoDismiss: true
      });
    }
    if (updateError) {
      addToast(getErrorMessage(updateError), {
        appearance: ERROR_KEY,
        autoDismiss: true
      });
    }
  }, [updateError, updateSuccess]);

  const handleSelect = (index, route) => {
    if (route === Route_Paths.VIEW_DONATIONS) {
      let temp = [...donations];
      setIsDonation(true);
      setData(temp);
    } else {
      let temp = [...userEvents];
      setIsDonation(false);
      setData(temp);
    }
    setSelected(index);
    navigation(route);
  };

  // variant styles for sub titles
  let textVariant = '';
  if (defaultValues.isResponsive) {
    textVariant = 'resSectionTitle';
  } else {
    textVariant = 'sectionTitle';
  }

  if (isLoading) {
    return <Loader isFullScreen={true} />;
  }

  return (
    <Screen showFooter={true}>
      <Box sx={EventPageStyles.container}>
        <div style={EventPageStyles.optionsContainer}>
          {defaultValues.host_event_menu.map((item, index) => (
            <Box
              key={index}
              sx={EventPageStyles.optionStyle(selected, index)}
              onClick={() => handleSelect(index, item.route)}>
              <Text
                text={item.title}
                variant={textVariant}
                sx={EventPageStyles.textStyle(index, selected)}
              />
            </Box>
          ))}
        </div>

        <Grid container sx={EventPageStyles.cardContainer}>
          {data.length > 0 ? (
            data.map((item, index) => (
              <Grid item xs={12} key={index} md={6}>
                <ManageEventCard data={item} isDonation={isDonation} />
              </Grid>
            ))
          ) : (
            <Text text="Not available" variant="noData" />
          )}
        </Grid>

        <Box sx={EventPageStyles.buttonContainer}>
          {!isDonation && (
            <UIButton
              title="create event"
              style={EventPageStyles.buttonStyle}
              onClick={() => navigation(Route_Paths.CREATE_EVENTS)}
            />
          )}
        </Box>
      </Box>
    </Screen>
  );
};
