/* eslint-disable no-dupe-keys */
/* eslint-disable no-unused-vars */
import { defaultValues } from '../../constants';
import { color } from '../../theme/color';
import { fonts } from '../../theme/fonts';
import { palette } from '../../theme/palette';

export const style = {
  sectionFirstContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: defaultValues.isResponsive ? '50vh' : '90vh',
    width: '100%',
    backgroundColor: '#FAEED1',
    justifyContent: 'center',
    alignItems: 'center'
  },
  contentContainer: (img) => ({
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FAEED1',
    backgroundPosition: defaultValues.isResponsive ? 'center' : 'right'
  }),
  leftContainer: {
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column !important',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flex: 1,
    padding: '20px 20px 20px 50px',
    '@media (max-width: 600px)': {
      textAlign: 'center',
      padding: '20px',
      maxWidth: '100%'
    }
  },
  landingPageData: {
    display: 'flex',
    flexDirection: 'column'
  },
  landingPageHeading: {
    fontSize: '65px',
    fontWeight: 'bold',
    '@media (max-width: 600px)': {
      fontSize: '30px'
    }
  },
  landingPageTopc: {
    fontSize: '18px'
  },
  landingPageCTitle: {
    fontSize: '24px',
    fontWeight: 'bold'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column !important',
    marginBottom: '20px'
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    gap: '30px',
    marginTop: '20px',
    '@media (max-width: 600px)': {
      width: '100%',
      justifyContent: 'center'
    }
  },
  browseButtonStyle: {
    width: '150px',
    height: '54px',
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    border: `1.5px solid ${color.primaryText}`,
    backgroundColor: color.transparent,
    color: 'black',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: color.primaryText,
      color: color.primaryBackground
    }
  },
  browseButton: {
    marginBottom: '20px'
  },
  createButtonStyle: {
    width: '150px',
    height: '54px',
    padding: '16px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    borderRadius: '12px',
    border: '1.5px solid black',
    backgroundColor: color.primaryText,
    color: 'white',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: color.hoverText,
      color: color.primaryBackground
    }
  },
  rightContainer: {
    backgroundColor: '#E7F5FD',
    borderRadius: '30px 0 0 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    marginLeft: 'auto',
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  imageContainer: {
    width: '600px',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 20px 0',
    boxSizing: 'border-box'
  },
  imageStyle: {
    position: 'absolute',
    zIndex: '1',
    width: '300px',
    height: '200px',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  },
  imageStyleRight: {
    width: '300px',
    height: '200px',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
  },
  contentStyle: (isResponsive) => ({
    fontSize: isResponsive ? '20px' : '70px',
    fontFamily: fonts.secondary,
    margin: 3,
    fontWeight: 'bold',
    display: 'inline',
    textShadow: '1px 1px 1px black, 1px 2px 1px black'
  }),

  textContainer: { width: '100%', display: 'flex' },
  //second section
  sectionSecondContainer: { px: '10%' },
  sectionSecondInnerContainer: { width: '100%' },

  cardContainer: (isResponsive) => ({
    width: '100%',
    display: 'flex',
    flexDirection: isResponsive ? 'column' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 5,
    mb: 5
  }),
  q1heading: {
    margin: '20px 0'
  },
  q1content: {
    margin: '10px 0px',
    fontSize: '16px',
    fontWeight: '500'
  },
  q1subHeading: {
    margin: '10px 0'
  },
  q1SubParagraph: {
    margin: '10px 0px',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  cardStyle: (isResponsive) => ({
    width: '100%',
    m: isResponsive && 1,
    p: 3,
    padding: '20px',
    margin: '20px',
    gap: '20px',
    borderRadius: '16px',
    backgroundColor: '#E9F7FF',
    boxShadow: '15px 15px 0 0 #AEE2F4',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      width: '90%',
      height: 'auto'
    }
  }),
  q1BorderChange: {
    backgroundColor: '#FFFEEF',
    boxShadow: ' 15px 15px 0px 0px #F9F4A6'
  },
  q1imageStyle: {
    width: '250px',
    height: '200px',
    borderRadius: '10px'
  },
  testimonialHeader: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  s3ContentContainer: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundPosition: defaultValues.isResponsive ? 'center' : 'right'
  },
  s3RightContainer: {
    borderRadius: '30px 0 0 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    overflow: 'hidden',
    marginLeft: 'auto',
    padding: '20px',
    '@media (max-width: 600px)': {
      display: 'none'
    }
  },
  s3imageContainer: {
    width: '600px',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box'
  },
  s3imageStyleRight: {
    width: '100%',
    height: '100%',
    boxShadow: '20px 20px 0px 0px #FFF08B',
    borderRadius: '10px'
  },

  s4Container: {
    width: '100%',
    backgroundColor: '#FFFEEF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cardsContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    overflow: 'hidden',
    padding: '20px',
    flexDirection: 'row',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'center'
    }
  },

  curatedProject: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginTop: '20px'
  },
  s4cardContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    mt: 5,
    mb: 5
  },
  s4card: (isResponsive) => ({
    m: isResponsive && 1,
    p: 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '300px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 6px 12px rgba(0,0,0,0.2)'
    }
  }),
  imageGrid: {
    margin: '0px',
    padding: '0px'
  },
  s4image: {
    width: '300px',
    height: '250px',
    marginBottom: '10px',
    borderRadius: '15px'
  },
  projectName: {
    fontSize: '18px',
    fontWeight: '700',
    textAlign: 'center',
    margin: '10px 0px',
    height: '65px',
    overflow: 'hidden'
  },
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  additionalInfoExpand: {
    cursor:'pointer',
    color:palette.blue,
    fontSize:'14px'
  },
  purpose :{
    fontWeight:'bold',
  },
  s4detailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '80px',
    margin: '20px 0px'
  },
  rightDetails: {
    textAlign: 'right'
  },
  donateButton: {
    width: '150px',
    height: '54px',
    gap: '10px',
    marginTop: '10px',
    borderRadius: '12px',
    background: '#343434',
    color: 'white',
    '&:hover': {
      backgroundColor: '#1a1a1a'
    }
  },
  loadMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px'
  },
  loadMorebtn: {
    backgroundColor: 'transparent',
    color: color.primaryText,
    cursor: 'pointer',
    border: 'none',
    fontWeight: 'bold',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline'
    }
  },
  bodyText: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  gridContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '20px',
    justifyContent: 'center'
  },

  s6Container: {
    display: 'flex',
    flexDirection: 'column',
    height: defaultValues.isResponsive ? '70vh' : '90vh',
    width: '100%',
    backgroundColor: '#FAEED1',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    backgroundColor: '#E7F5FD'
  },
  s6footer: {
    padding: '0px 0px 30px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '10px',
    '@media (max-width: 600px)': {
      alignItems: 'center',
      justifyContent: 'center'
    }
  },

  s6FooterContent: {
    margin: '0px'
  },

  s6DivContent: {
    display: 'flex',
    flexDirection: 'column'
  },

  outerTBox: {
    width: '100%',
    height: '28vh',
    backgroundColor: '#ffffff'
  },
  triangleBox: {
    bottom: 0,
    height: 0,
    borderTop: '28vh solid #E7F5FD',
    borderRight: '49vw solid transparent',
    borderLeft: '49vw solid transparent',
    borderBottom: '25px solid transparent'
  },
  tringle: {
    maxWidth: '100%'
  },
  btnContactUs: {
    width: '150px',
    top: '-150px',
    left: '50%',
    transform: 'translateX(-50%)',
    padding: '16px',
    gap: '10px',
    borderRadius: '12px',
    background: color.primaryText,
    color: color.primaryBackground,
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      background: color.hoverText
    }
  },

  testimonialCardContainer: (isResponsive) => ({
    py: '5%',
    display: 'flex',
    flexDirection: isResponsive ? 'column' : 'row',
    justifyContent: 'space-between'
  }),
  tHeading: {
    fontFamily: 'Roboto',
    fontSize: '50px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  tSubheading: {
    fontFamily: 'Quicksand',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  centerCard: (isResponsive) => ({
    width: isResponsive ? '100%' : '34%',
    height: isResponsive ? 'auto' : '270px',
    backgroundColor: color.primary,
    paddingBottom: '20px',
    mt: isResponsive ? 1 : -5,
    ml: isResponsive ? 1 : '-5%',
    mr: isResponsive ? 1 : '-5%',
    mb: isResponsive ? 1 : '-5%',
    zIndex: 1
  }),

  tContainer: {
    backgroundColor: '#FFFEEF'
  },
  tSubContainer: {
    margin: '10px',
    padding: '10px'
  },

  testimonialCardStyle: (isResponsive) => ({
    width: isResponsive ? '100%' : '34%',
    backgroundColor: color.palette.blueShade,
    m: isResponsive && 1
  }),

  profileIcon: {
    width: '20%',
    height: '30%',
    position: 'relative',
    top: '-10%',
    left: '40%'
  },

  leftQuoteIcon: { position: 'relative', left: '5%' },
  rightQuoteIcon: { position: 'relative', left: '70%' },
  testimonialTextContainer: { px: 3 },

  //third section
  sectionThirdContainer: { backgroundColor: color.palette.offWhite, px: '10%' },
  thirdSecInnerContainer: { width: '90%' },
  subContainer: {
    display: 'flex',
    flexDirection: defaultValues.isResponsive ? 'column' : 'row',
    pt: '2%',
    pb: '4%',
    justifyContent: 'space-between',
    width: '100%'
  },
  innerSubContainer: { width: defaultValues.isResponsive ? '100%' : '48%' },

  detailsContainer: {
    mt: '-10%',
    pl: 1,
    width: '90%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  primaryText: { fontSize: 56, fontWeight: 600 },
  resPrimaryText: { fontSize: 18, fontWeight: 600 },

  secondaryText: { fontSize: 25, fontWeight: 500 },
  reSecondaryText: { fontSize: 12, fontWeight: 500 },

  secondaryTextQ: { fontSize: 20, fontWeight: 600 },
  reSecondaryTextQ: { fontSize: 18, fontWeight: 600 },

  largerText: (isResponsive) => ({
    fontSize: isResponsive ? 20 : 65,
    fontWeight: 600,
    margin: '12px',
    textAlign: 'center'
  }),
  largerTextWithShadow: (isResponsive) => ({
    fontSize: isResponsive ? 20 : 65,
    fontWeight: 600,
    textShadow: '1px 1px 1px black, 1px 2px 1px black',
    marginLeft: '10px'
  }),

  largerTextWithLine: {
    fontSize: 60,
    fontWeight: 600,
    // letterSpacing: '12px',
    margin: '12px',
    textDecoration: 'underline',
    color: color.primaryBackground,
    cursor: 'pointer',
    backgroundColor: color.palette.lightBlack
  },
  resLargerTextWithLine: {
    fontSize: 20,
    fontWeight: 600,
    textDecoration: 'underline',
    color: color.primaryBackground,
    backgroundColor: color.palette.lightBlack,
    cursor: 'pointer'
  },

  common: { fontSize: 22, fontWeight: 500, marginBottom: '12px' },
  resCommon: { fontSize: 14, fontWeight: 500, marginBottom: '12px' },
  cardText: { fontSize: 20, fontWeight: 500 },

  mediumText: { fontSize: 28, fontWeight: 600 },
  resMediumText: { fontSize: 18, fontWeight: 600 },

  heading: { fontSize: 65, fontWeight: 500 },
  title: { fontSize: 32, fontWeight: 500 },
  resTitle: { fontSize: 16, fontWeight: 500 },

  testimonialText: { fontSize: 16, fontWeight: 'bold' },
  buttonStyle: {
    paddingLeft: '5%',
    paddingRight: '5%',
    backgroundColor: color.primaryText,
    marginTop: '5%'
  },

  causeText: (isResponsive) => ({
    display: 'flex',
    flexDirection: isResponsive ? 'column' : 'row',
    alignItems: 'center'
  })
};
