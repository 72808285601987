/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { DonationItem, Loader, Screen, TextInput, UIButton } from '../../../components';
import { style } from './style';
import { useLocation, useNavigate } from 'react-router-dom';
import { createCard, createOrder, updateOrder } from '../../../services/api';
import { useFormik } from 'formik';
import { donorDetailsSchema } from '../../../utils/schemas';
import { Route_Paths, defaultValues } from '../../../constants';
import { color } from '../../../theme/color';
import { v4 as uuidv4 } from 'uuid';

export const CheckoutPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { projectsData, eventData } = location.state;

  const { values, handleChange, errors, touched, setFieldTouched, isValid } = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      phone_number: '',
      email_id: '',
      pan_number: ''
    },
    validateOnBlur: true,
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: donorDetailsSchema
  });

  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let project_data = [...projectsData];
    let filtered = project_data.filter((item) => item.amount > 0);
    let totalAmt = filtered.reduce((acc, item) => acc + parseInt(item.amount), 0);
    setTotal(totalAmt);
    setProjects(filtered);
    setIsLoading(false);
  }, [projectsData]);

  const handleTransaction = async () => {
    let order_id = null;
    const guest_name = `${values.first_name} ${values.last_name}`;
    let orderData = {
      event_id: eventData._id,
      total_amount: total,
      user_details: {
        first_name: values.first_name,
        last_name: values.last_name,
        email_id: values.email_id,
        phone_number: values.phone_number,
        pan_number: values.pan_number.toUpperCase()
      },
      donation_details: projects
    };

    const response = await createOrder(orderData);
    const { data } = response.data;
    order_id = data.order_id;

    const options = {
      // eslint-disable-next-line no-undef
      key: process.env.REACT_APP_RZ_KEY,
      name: defaultValues.paymentHeader,
      description: defaultValues.paymentDescription,
      order_id: data.order_id,
      handler: (response) => {
        setIsLoading(true);
        try {
          const paymentId = response.razorpay_payment_id;
          let values = {
            order_id: order_id,
            event_id: eventData._id,
            payment_id: paymentId,
            amount: total,
            orderData
          };
          updateOrder(values).then(() => {
            setIsLoading(false);
            const uniqueId = uuidv4();
            const causesText = projects ? projects.map(cause => cause.project_name).join(', ') : '';
            const stateData = {
              uuid: uniqueId,
              host_name: eventData.host_name,
              event_name: eventData.event_name,
              total: total,
              guest_name: guest_name,
              causes: causesText,
              background_url:  eventData.thankyouCardUrl
            };

            createCard(stateData)
              .then((cardResponse) => {
                navigate(`${Route_Paths.CARD}/${uniqueId}`);
              })
              .catch((error) => {
                setIsLoading(false);
              });
          });
        } catch (err) {
          setIsLoading(false);
        }
      },
      theme: {
        color: color.palette.rezorpayTheme
      }
    };

    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  if (isLoading) {
    return <Loader isFullScreen={true} />;
  }
  return (
    <Screen showFooter={true}>
      <>
        <Box sx={style.container(defaultValues.isResponsive)}>
          <Box sx={style.leftContainer(defaultValues.isResponsive)}>
            <TextInput
              fullWidth
              required
              label="Enter first name"
              name="first_name"
              sx={style.inputField(defaultValues.isResponsive)}
              value={values.first_name}
              error={touched.first_name && errors.first_name}
              onChange={handleChange}
              onBlur={() => setFieldTouched('first_name')}
            />
            <TextInput
              required
              label="Enter last name"
              sx={style.inputField(defaultValues.isResponsive)}
              name="last_name"
              error={touched.last_name && errors.last_name}
              value={values.last_name}
              onChange={handleChange}
              onBlur={() => setFieldTouched('last_name')}
            />
            <TextInput
              required
              label="Enter email id"
              sx={style.inputField(defaultValues.isResponsive)}
              name="email_id"
              value={values.email_id}
              onChange={handleChange}
              onBlur={() => setFieldTouched('email_id')}
              error={touched.email_id && errors.email_id}
            />
            <TextInput
              required
              label="Enter your phone no"
              sx={style.inputField(defaultValues.isResponsive)}
              name="phone_number"
              value={values.phone_number}
              onChange={handleChange}
              onBlur={() => setFieldTouched('phone_number')}
              error={touched.phone_number && errors.phone_number}
            />
            <TextInput
              required
              label="Enter your pan no"
              sx={style.inputField(defaultValues.isResponsive)}
              name="pan_number"
              value={values.pan_number}
              onBlur={() => setFieldTouched('pan_number')}
              onChange={handleChange}
              error={touched.pan_number && errors.pan_number}
            />
          </Box>
          <Box sx={style.horizonal}></Box>
          <Box sx={style.rightContainer(defaultValues.isResponsive)}>
            <DonationItem data={projects} total={total} />
          </Box>
        </Box>
        <Box sx={style.buttonContainer}>
          <UIButton
            title="Proceed to pay"
            style={style.buttonStyle}
            disabled={!isValid}
            onClick={handleTransaction}
          />
        </Box>
      </>
    </Screen>
  );
};
