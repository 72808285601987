import { color } from '../../theme/color';

export const SSOImageStyle = {
  container: {
    backgroundColor: color.primary,
    height: window.innerHeight,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  responsiveContainer: {
    backgroundColor: color.primary,
    height: '40vh',
    width: '100%'
  },

  imageStyles: { width: '50%' },
  textStyle: { marginLeft: '8.68%', marginTop: '12px' },
  responsiveImageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  responsiveImage: { width: '150px', height: '150px', objectFit: 'contain' }
};
