import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { styles } from './style';
import { Screen, PageHeader, Text, UIButton, Loader } from '../../components';
import {
  ERROR_KEY,
  SUCCESS_KEY,
  defaultValues,
  getErrorMessage,
  getSuccessMessage
} from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllUserRequest,
  resetMessages,
  updateUserByAdminRequest
} from '../../redux/slices/userSlice';
import {
  allUsers,
  isUserLoading,
  updateErrorMessage,
  updateSuccessMessage
} from '../../redux/selectors/usersSelector';
import { useToasts } from 'react-toast-notifications';

import EmailIcon from '../../assets/images/Email.png';
import phoneIcon from '../../assets/images/Phone.png';

export const ManageUsers = () => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const users = useSelector(allUsers);
  const updateSuccess = useSelector(updateSuccessMessage);
  const updateError = useSelector(updateErrorMessage);
  const userLoading = useSelector(isUserLoading);

  const [searchedUser, setSearchedUser] = useState([]);

  useEffect(() => {
    dispatch(getAllUserRequest());
  }, [dispatch]);

  useEffect(() => {
    if (updateSuccess) {
      addToast(getSuccessMessage(updateSuccess), {
        appearance: SUCCESS_KEY,
        autoDismiss: true
      });
    }

    if (updateError) {
      addToast(getErrorMessage(updateError), {
        appearance: ERROR_KEY,
        autoDismiss: true
      });
    }
    dispatch(resetMessages());
  }, [updateError, updateSuccess, searchedUser]);

  useEffect(() => {
    let temp = [...users];
    setSearchedUser(temp);
  }, [users]);

  const handleActive = (data, type) => {
    const id = data._id;
    dispatch(updateUserByAdminRequest({ id, data: { is_active: type } }));
  };

  const handleSearch = useCallback(
    (value) => {
      const tempUsers = [...users];
      if (value.length > 1) {
        const filteredUsers = tempUsers.filter(
          (item) => item.email_id && item.email_id.toLowerCase().includes(value.toLowerCase())
        );
        setSearchedUser(filteredUsers);
      } else {
        setSearchedUser(tempUsers);
      }
    },
    [users]
  );

  return (
    <Screen showFooter={true}>
      <Box sx={defaultValues.isResponsive ? styles.resContainer : styles.container}>
        {userLoading ? (
          <Loader />
        ) : (
          <>
            <Box sx={styles.headerContainer}>
              {defaultValues.isResponsive ? (
                <PageHeader searchContent={'Search Users'} />
              ) : (
                <PageHeader
                  title={'Manage Users'}
                  searchContent={'Search Users'}
                  onSearch={handleSearch}
                />
              )}
            </Box>

            {searchedUser.length > 0 ? (
              searchedUser.map((item, index) => (
                <Box
                  sx={defaultValues.isResponsive ? styles.resCardContainer : styles.cardContainer}
                  key={index}>
                  <Box sx={styles.subContainer}>
                    <Box sx={styles.textWithIconContainer}>
                      <Text
                        text={item.first_name}
                        variant="header"
                        style={defaultValues.isResponsive ? styles.subText : styles.nameText}
                      />
                    </Box>
                    <Box sx={styles.textWithIconContainer}>
                      <img src={EmailIcon} style={styles.icon} />
                      <Text
                        text={item.email_id}
                        style={defaultValues.isResponsive ? styles.reSubText : styles.subText}
                      />
                    </Box>
                    {item.phone_number && (
                      <Box sx={styles.textWithIconContainer}>
                        <img src={phoneIcon} style={styles.icon} />
                        <Text
                          text={item.phone_number}
                          style={defaultValues.isResponsive ? styles.reSubText : styles.subText}
                        />
                      </Box>
                    )}
                  </Box>
                  {defaultValues.isResponsive ? (
                    <Box sx={{ display: 'flex' }}>
                      <UIButton
                        textStyle={styles.resBtnStyle}
                        title="Active"
                        style={item.is_active ? styles.reButtonActive : styles.reDefaultButton}
                        onClick={() => handleActive(item, true)}
                      />
                      <UIButton
                        textStyle={styles.resBtnStyle}
                        title="Inactive"
                        style={item.is_active ? styles.reDefaultButton : styles.reButtonInctive}
                        onClick={() => handleActive(item, false)}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ display: 'flex' }}>
                      <UIButton
                        title="Active"
                        style={item.is_active ? styles.buttonActive : styles.defaultButton}
                        onClick={() => handleActive(item, true)}
                      />
                      <UIButton
                        title="Inactive"
                        style={item.is_active ? styles.defaultButton : styles.buttonInactive}
                        onClick={() => handleActive(item, false)}
                      />
                    </Box>
                  )}
                </Box>
              ))
            ) : (
              <Text
                text="No users available"
                variant="secondarySubTitle"
                style={styles.notificationStyle}
              />
            )}
          </>
        )}
      </Box>
    </Screen>
  );
};
