import { createSelector } from 'reselect';

export const isAuthError = createSelector(
  [(state) => state.generic.isAuthError],
  (isAuthError) => isAuthError
);

export const isInvalidEvent = createSelector(
  [(state) => state.generic.invalidEventError],
  (invalidEventError) => invalidEventError
);
