import { all } from 'redux-saga/effects';
import { loginSaga } from './loginSaga';
import { projectsSaga } from './projectsSaga';
import { usersSaga } from './usersSaga';
import { eventSaga } from './eventSaga';

const root = function* root() {
  yield all([loginSaga(), projectsSaga(), usersSaga(), eventSaga()]);
};

export default root;
