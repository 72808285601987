const ConvertIndianCur = (num) => {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];
  const scales = ["", "Thousand", "Lakh", "Crore"];

  if (num === 0) return "Zero Rupees Only";

  let word = "";

  const numberToWords = (n) => {
    let str = "";
    if (n < 20) {
      str += units[n];
    } else {
      str += tens[Math.floor(n / 10)];
      if (n % 10) str += " " + units[n % 10];
    }
    return str;
  };

  let scaleIndex = 0;
  while (num > 0) {
    let chunk = num % 1000;
    if (chunk) {
      word =
        numberToWords(Math.floor(chunk / 100)) +
        (Math.floor(chunk / 100) > 0 ? " Hundred " : "") +
        numberToWords(chunk % 100) +
        " " +
        scales[scaleIndex] +
        " " +
        word;
    }
    num = Math.floor(num / 1000);
    scaleIndex++;
  }

  return word.trim() + " Rupees Only";
};

export default ConvertIndianCur;